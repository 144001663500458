import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { switchMap, catchError } from 'rxjs/operators';

import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { LocalStorageService } from '../utilities/local-storage.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { AuthUsersService } from '../utilities/auth-users.service';

import { storeSetUserProfileGuard, storeResetUserProfileGuard } from '../../store/actions/user-profile-guard.actions';
import { StoreStateUserProfileGuard } from '../../store/reducers/user-profile-guard.reducers';
import { selectStoreUserProfileGuard } from '../../store/selectors/user-profile-guard.selectors';

export const authUsersGuard: CanActivateFn = (route, state) => {
  const localStorageService = inject(LocalStorageService);
  const utilitiesService = inject(UtilitiesService);
  const authUsersService = inject(AuthUsersService);
  const router = inject(Router);
  const store = inject(Store<StoreStateUserProfileGuard>);

  if (!localStorageService.isLoggedIn(GLOBAL_CONSTANT?.session?.auth_token)) {
    router.navigate([GLOBAL_CONSTANT.base_url]);
    return of(false);
  }

  return store.pipe(
    select(selectStoreUserProfileGuard),
    switchMap(userProfileGuard => {
      if (userProfileGuard) {
        return of(true);
      } else {
        return authUsersService.getProfileAllUsersData().pipe(
          switchMap(response => {
            if (response?.data && utilitiesService.objectLength(response?.data) > 0) {
              store.dispatch(storeSetUserProfileGuard({ storeData: response.data }));
              return of(true);
            }
            store.dispatch(storeResetUserProfileGuard());
            authUsersService.logoutUsers();
            router.navigate([GLOBAL_CONSTANT.base_url]);
            return of(false);
          }),
          catchError(() => {
            authUsersService.logoutUsers();
            router.navigate([GLOBAL_CONSTANT.base_url]);
            return of(false);
          })
        );
      }
    })
  );
};
