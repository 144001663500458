<div class="main_container">
    <div class="container">
        <div class="bg-white p-3 rounded-3">
            <h1 class="fs-5 mb-3">All Services</h1>
            <div class="box_outer border rounded service-sidebar">
                <div class="row">
                    <div class="col-md-12 innerpages">
                        <div class="table-responsive">
                            <table
                                class="table caption-top mb-0 table-ds table-bordered table-striped fs-14 dataTable no-footer table-nowrap">
                                <thead>
                                    <tr class="table_header">
                                        <th scope="col" class="text-center">SERVICE NAME</th>
                                        <th scope="col" class="text-center">TOTAL QUOTE </th>
                                        <th scope="col" class="text-center">TODAY QUOTE </th>
                                        <th scope="col" class="text-center">THIS WEEK QUOTE</th>
                                        <th scope="col" class="text-center">THIS MONTH QUOTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @if(objectLength(servicesCount) > 0) {
                                    @for(data of servicesCount; track i; let i=$index) {
                                    <tr>
                                        <td>
                                            {{ data?.name || '' }}
                                        </td>
                                        <td class="text-center">
                                            <a class="text-success"
                                                [routerLink]="data?.total && data?.total > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.orders) : null">
                                                {{ data?.total || 0 }}
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            <a class="text-primary"
                                                [routerLink]="data?.today && data?.today > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.orders, globalConstant.date_key_match.today) : null">
                                                {{ data?.today || 0 }}
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            <a class="text-warning"
                                                [routerLink]="data?.this_week && data?.this_week > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.orders, globalConstant.date_key_match.this_week) : null">
                                                {{ data?.this_week || 0 }}
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            <a class="text-primary"
                                                [routerLink]="data?.this_month && data?.this_month > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.orders, globalConstant.date_key_match.this_month) : null">
                                                {{ data?.this_month || 0 }}
                                            </a>
                                        </td>
                                    </tr>
                                    }
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>