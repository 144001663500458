<div class="vehicle-rto vehicleInfo" id="rtoDiv">
    <p class="fw-semi text-primary mb-2">Select RTO and City</p>
    <div class="row">
      <div class="col-md-12">
        <div [formGroup]="formGroup" class="form-group mb-4">
          <select id="rto_code" formControlName="rto_code" class="form-control form-select">
            <option value="">-- Select RTO --</option>
            @if(arrayLength(getRtoList) > 0) {
              @for(data of getRtoList; track i; let i = $index) {
                <option [value]="data?.rto_code">{{ data?.rto_code || '' }}</option>
              }
            }
          </select>
          <mat-error *ngIf="getErrorMessage('rto_code')" class="custom-error">
            {{ getErrorMessage('rto_code') }}
          </mat-error>
        </div>
      </div>

      @if(arrayLength(getRtoList) > 0) {
        <div class="col-md-12">
          <ul class="grouplist mb-4">
            @for(data of getRtoList; track i; let i = $index) {
              <li>
                <div class="form-check form-check-inline typeradio">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="rto_code"
                    [id]="data?.rto_code"
                    [value]="data?.rto_code"
                    (change)="onRadioSelect(data?.rto_code)"
                    [checked]="isRadioChecked(data?.rto_code)"
                  />
                  <label class="form-check-label justify-content-center" [for]="data?.rto_code">{{ data?.rto_code }}</label>
                </div>
              </li>
            }
          </ul>
        </div>
      }

      <div class="col-sm-12">
        <div class="form-group text-center">
            <button (click)="onContinue()" [disabled]="isLoading" class="btn btn-success rounded-2 btn-lg px-5">Continue</button>
        </div>
      </div>
    </div>
  </div>
