import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatTabsModule } from '@angular/material/tabs';
import { PospProfileService } from './posp-profile.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../common/utilities/moment-utilities.service';
import { DownloadService } from '../../common/utilities/download.service';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_POSP } from '../../common/constants/routes/posp/posp.constants';
import { storeSetUserProfile, storeResetUserProfile } from '../../store/actions/user-profile.actions';

@Component({
  selector: 'app-posp-profile',
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe,
    MatTabsModule
  ],
  templateUrl: './posp-profile.component.html'
})
export class PospProfileComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  routesPosp = ROUTES_POSP;
  projectName = this.globalConstant?.projectName;

  private apiPospProfileDataSubscription: Subscription | undefined;

  getPospData: any = {};
  profileDocUrl: any = {};
  pancardDocUrl: any = {};
  aadhaarFrontDocUrl: any = {};
  aadhaarBackDocUrl: any = {};
  bankDocUrl: any = {};
  pospSignDocUrl: any = {};
  pospeduDocUrl: any = {};

  constructor(
    private store: Store,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private downloadService: DownloadService,
    private pospProfileService: PospProfileService
  ) { }

  ngOnInit(): void {
    this.apiGetPospData();
  }

  getDBDateShow(date: string): string {
    return this.momentUtilitiesService.getDBDateShow(date);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  isPdfOrImage(url: string): string {
    return this.utilitiesService.isPdfOrImage(url);
  }

  apiGetPospData() {
    this.apiPospProfileDataSubscription = this.pospProfileService.getPospData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            this.getPospData = response?.data;
            this.store.dispatch(storeSetUserProfile({ storeData: this.getPospData }));
            this.profileDocUrl = this.globalConstant?.storage_base_url + this.getPospData?.posp_detail?.profile_doc;
            this.pancardDocUrl = this.globalConstant?.storage_base_url + this.getPospData?.posp_detail?.pan_card_doc;
            this.aadhaarFrontDocUrl = this.globalConstant?.storage_base_url + this.getPospData?.posp_detail?.aadhar_front_doc;
            this.aadhaarBackDocUrl = this.globalConstant?.storage_base_url + this.getPospData?.posp_detail?.aadhar_back_doc;
            this.bankDocUrl = this.globalConstant?.storage_base_url + this.getPospData?.posp_detail?.cheque_doc;
            this.pospSignDocUrl = this.globalConstant?.storage_base_url + this.getPospData?.posp_detail?.signature_doc;
            this.pospeduDocUrl = this.globalConstant?.storage_base_url + this.getPospData?.posp_detail?.educational_certificate_doc;
          } else {
            this.store.dispatch(storeResetUserProfile());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiPospProfileDataSubscription) {
      this.apiPospProfileDataSubscription.unsubscribe();
    }
  }
}
