import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { dateFormatValidator, educationValidator, nomineeRelationValidator, panCardValidator, occupationValidator, businessNatureValidator, insuranceStatusValidator, medicalHistoryValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        service_step: new FormControl(GLOBAL_CONSTANT?.offline_term_life_insurance_step_match?.step_2 || 'insurance_details'),
        uuid: new FormControl(''),
        pan_card: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            panCardValidator
        ]),
        date_of_birth: new FormControl('', [
            Validators.required,
            dateFormatValidator()
        ]),
        nominee_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        nominee_dob: new FormControl('', [
            Validators.required,
            dateFormatValidator()
        ]),
        nominee_relation: new FormControl('', [
            Validators.required,
            nomineeRelationValidator()
        ]),
        nominee_phone: new FormControl('', [
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('^[0-9]*$')
        ]),
        nominee_email: new FormControl('', [
            Validators.maxLength(100),
            Validators.email
        ]),
        education: new FormControl('', [
            Validators.required,
            educationValidator()
        ]),
        height: new FormControl('', [
            Validators.required,
        ]),
        weight: new FormControl('', [
            Validators.required,
        ]),
        occupation: new FormControl('', [
            Validators.required,
            occupationValidator()
        ]),
        nature_of_business: new FormControl('', [
            businessNatureValidator()
        ]),
        firm_name: new FormControl('', [
            Validators.maxLength(100),
        ]),
        mother_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        vehicle_number: new FormControl('', [
            Validators.maxLength(12),
        ]),
        medical_history: new FormControl('', [
            Validators.required,
            medicalHistoryValidator()
        ]),
        last_insurer_name: new FormControl('', []),
        last_premium: new FormControl('', []),
        last_sum_insured: new FormControl('', []),
        last_insurance_status: new FormControl('', [
            insuranceStatusValidator()
        ]),
        state_code: new FormControl('', [
            Validators.required
        ]),
        city: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        pincode: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
        ]),
        address: new FormControl('', [
            Validators.required
        ]),
        accept_t_c: new FormControl('', [
            Validators.required
        ]),
        photo_doc: new FormControl('', [
            Validators.required
        ]),
        pan_doc: new FormControl('', [
            Validators.required
        ]),
        aadhar_doc: new FormControl('', []),
        cheque_doc: new FormControl('', []),
        bank_statement_doc: new FormControl('', []),
        salary_slip_doc: new FormControl('', []),
        nominee_id_proof_doc: new FormControl('', []),
        other_doc1: new FormControl('', []),
        other_doc2: new FormControl('', [])
    });
}
