import { INSURER_ID } from "../constants/insurer/ids.constants";

export const PolicyHolderTypeUtility = {
  [INSURER_ID.GO_DIGIT_MOTOR]: [
    { value: 'individual', label: 'Individual' },
    { value: 'organization', label: 'Organization' }
  ]
};

export type PolicyHolderTypeUtilityType = {
  [key: number]: { value: string; label: string }[];
};
