import { FormGroup, FormControl, Validators } from '@angular/forms';

export function getFormValidators(): FormGroup {
    return new FormGroup({

        rto_code: new FormControl('', [
            //Validators.required,
        ]),
        make_name: new FormControl('', [
            //Validators.required,
        ]),
        model_name: new FormControl('', [
            //Validators.required,
        ]),
        variant_name: new FormControl('', [
            //Validators.required,
        ]),
        data: new FormControl('', [
            //Validators.required,
        ]),
        customerType: new FormControl('', [
            //Validators.required,
        ]),
        full_name: new FormControl('', [
            //Validators.required,
        ]),
        organization_name: new FormControl('', [
            //Validators.required,
        ])
    });
}
