import { Action, createReducer, on } from '@ngrx/store';
import { storeSetCategoryPospBlog, storeResetCategoryPospBlog } from '../../../actions/blog/posp-blog/category-posp-blog.actions';

export interface StoreCategoryPospBlog { [key: string]: any; }

export interface StoreStateCategoryPospBlog {
  storeData: StoreCategoryPospBlog | null;
}

export const initialState: StoreStateCategoryPospBlog = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetCategoryPospBlog, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetCategoryPospBlog, state => ({ ...state, storeData: null }))
);

export function reducerCategoryPospBlog(state: StoreStateCategoryPospBlog | undefined, action: Action) { return _storeReducer(state, action); }