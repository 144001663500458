import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../common/utilities/moment-utilities.service';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_POSP_BLOG } from '../../common/constants/routes/posp-blog/posp-blog.constants';
import { PospBlogWidgetComponent } from '../../common/layouts/blog/posp-blog-widget/posp-blog-widget.component';
import { PospBlogDetailService } from './posp-blog-detail.service';

@Component({
  selector: 'app-posp-blog-detail',
  standalone: true,
  imports: [
    RouterModule,
    PospBlogWidgetComponent
  ],
  templateUrl: './posp-blog-detail.component.html'
})
export class PospBlogDetailComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospBlog = ROUTES_POSP_BLOG;

  projectName = this.globalConstant?.projectName;
  serverErrors: { [key: string]: string[] } = {};

  pospBlogDetailData: any = {};
  slug: string = '';

  isLoading = false;

  private apiPospBlogDetailDataSubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private pospBlogDetailService: PospBlogDetailService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.slug = params['slug'] || null;
      if (this.slug != null && this.slug != '') {
        this.apiGetPospBlogDetailData({ 'slug': this.slug });
      }
    });
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.DD_MMM_YYYY);
  }

  sanitizeHtml(html: string) {
    return this.utilitiesService.sanitizeHtml(html);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  apiGetPospBlogDetailData(params: object) {
    this.isLoading = true;
    this.apiPospBlogDetailDataSubscription = this.pospBlogDetailService.getPospBlogDetailData(params).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.pospBlogDetailData = response?.data && this.objectLength(response?.data) > 0 ? response?.data : {};
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiPospBlogDetailDataSubscription) {
      this.apiPospBlogDetailDataSubscription.unsubscribe();
    }
  }
}