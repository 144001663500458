import { NgIf } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GLOBAL_CONSTANT } from '../../../constants/global-constant.constants';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { RtoService } from './rto.service';
import { UtilitiesService } from '../../../utilities/utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { LoaderService } from '../../../loader/loader.service';

@Component({
  selector: 'app-rto',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
  templateUrl: './rto.component.html',
})
export class RtoComponent implements OnInit, OnDestroy {

  globalConstant = GLOBAL_CONSTANT;
  projectName = this.globalConstant?.projectName;

  @Input() formGroup!: FormGroup;
  @Input() serverErrors: any = {};
  @Output() continue = new EventEmitter<void>();
  @Output() rtoCodeSelected = new EventEmitter<string>();

  selectedRtoCode: string | null = null;
  getRtoList: any[] = [];
  isLoading = false;

  private apiGetRtoListSubscription: Subscription | undefined;

  constructor(
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private loaderService: LoaderService,
    private rtoService: RtoService
  ) { }

  ngOnInit(): void {
    this.apiGetRtoList();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  apiGetRtoList() {
    this.apiGetRtoListSubscription = this.rtoService.getRtoList({
    }).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getRtoList = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.formGroup.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetRtoListSubscription) {
      this.apiGetRtoListSubscription.unsubscribe();
    }
  }

  onContinue(): void {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const selectedRtoCode = this.formGroup.get('rto_code')?.value;
      if (selectedRtoCode) {
        this.rtoCodeSelected.emit(selectedRtoCode);
        this.continue.emit();
      }
      this.isLoading = false;
      this.loaderService.hide();
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.formGroup);
    }
  }

  onRadioSelect(rtoCode: string): void {
    this.formGroup.get('rto_code')?.setValue(rtoCode);
  }

  isRadioChecked(rtoCode: string): boolean {
    return this.formGroup.get('rto_code')?.value === rtoCode;
  }
}
