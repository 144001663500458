import { Routes } from '@angular/router';
import { ROUTES_POSP_BLOG } from '../../constants/routes/posp-blog/posp-blog.constants';
import { PospBlogComponent } from '../../../posp-blog/posp-blog.component';
import { PospBlogDetailComponent } from '../../../posp-blog/posp-blog-detail/posp-blog-detail.component';

export const PospBlogRoutes: Routes = [
    {
        path: ROUTES_POSP_BLOG.INDEX,
        component: PospBlogComponent
    },
    {
        path: ROUTES_POSP_BLOG.INDEX + '/:category_slug',
        component: PospBlogComponent
    },
    {
        path: ROUTES_POSP_BLOG.DETAIL + '/:slug',
        component: PospBlogDetailComponent
    }
];