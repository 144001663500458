import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER } from '../../constants/routes/offline-services/offline-persoanl-accident-cover.constants';
import { OfflinePersonalAccidentCoverStepOneComponent } from '../../../offline-services/personal-accident-cover/offline-personal-accident-cover-step-one/offline-personal-accident-cover-step-one.component';
import { OfflinePersonalAccidentCoverStepTwoComponent } from '../../../offline-services/personal-accident-cover/offline-personal-accident-cover-step-two/offline-personal-accident-cover-step-two.component';
import { OfflinePersonalAccidentCoverOrdersComponent } from '../../../offline-services-orders/personal-accident-cover/offline-personal-accident-cover-orders/offline-personal-accident-cover-orders.component';
import { OfflinePersonalAccidentCoverOrderDetailComponent } from '../../../offline-services-orders/personal-accident-cover/offline-personal-accident-cover-order-detail/offline-personal-accident-cover-order-detail.component';

export const OfflinePersonalAccidentCoverRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_STEP_ONE,
        component: OfflinePersonalAccidentCoverStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflinePersonalAccidentCoverStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflinePersonalAccidentCoverStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflinePersonalAccidentCoverOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflinePersonalAccidentCoverOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflinePersonalAccidentCoverOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];