<div class="main_container">
    @if(userType == globalConstant.user_type.posp) {
        <div class="sec pos_ds mb-2">
            <div class="row">
                <div class="col- col-sm-6 col-md-6 col-lg-3">
                    <div class="info-box p-0 appbg-dark">
                        <div class="info-box-content">
                            <span class="info-box-text">
                                Total Offline Commission
                                <a [routerLink]="[globalConstant.base_url, routesOfflineMotorInsurancePolicy.INDEX || '', globalConstant.offline_policy_page_name.policy || '']" class="fw-semi text-success">
                                    View all
                                </a>
                            </span>
                            <div class="info-box-number">
                                {{ (offlineCommission?.offline_total_commission_amount || offlineCommission?.offline_total_commission_amount === 0) ? offlineCommission.offline_total_commission_amount : showCurrency(0) }} /- 
                                <span class="info_bx_icon">
                                    <img src="images/icons/commission.svg" [alt]="projectName" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    <div class="sec insplan py-3 posp-dashboard border rounded-1 shadow-sm p-3 bg-white border">
        <h1 class="fs-5 text-center fw-bolder mb-3">Request Offline Quotes</h1>
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="categorie_name_list">
                    @if(arrayLength(serviceList) > 0) {
                    <ul class="services-list">
                        @for(data of serviceList; track i; let i=$index) {
                        <li class="nav-item tab_insurance ">
                            <a [routerLink]="getOffLineServiceURL(data?.service_type_id, 'step_1')"
                                class="text-center img_width">
                                <div class="cat-bx">
                                    <img [src]="data?.image ?? 'images/default/default-insurance.png'"
                                        alt="{{ data?.name }}" width="60" />
                                </div>
                                <span class="cat-name d-block" [innerHTML]="data?.name"></span>
                            </a>
                        </li>
                        }
                    </ul>
                    }
                </div>
            </div>
        </div>
    </div>
</div>