<section class="sec insplan py-5 categroy">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-6 mx-auto mt-md-5">
                <div class="bg-white">
                    <h2 class="fw-bold fs-20 mb-1">Buy two-wheeler insurance</h2>
                    <div class="text-green fw-semi">Up to 93% off on two-wheeler policies!</div>
                    <div class="cmnform onlinesvcform mt-3">
                        <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <div class="row">
                                <div class="col-sm-12">
                                    <mat-label class="fs-14 mb-1 custom-label">Enter Bike/Scooter Number<span class="text-danger"> * </span></mat-label>
                                    <div class="form-group mb-3">
                                        <input matInput appUpperCase name="vehicle_number" formControlName="vehicle_number" id="vehicle_number" placeholder="Enter vehicle number:  (eg. RJ14AB1234)" minlength="10" maxlength="10" type="text" class="form-control" />
                                        <mat-error *ngIf="getErrorMessage('vehicle_number')" class="custom-error">
                                            {{ getErrorMessage('vehicle_number') }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="new-bike mb-3">
                                    <button type="button" class="btn px-0 text-primary fw-normal btn-hover-green fw-semi" (click)="navigateToVehicleDetails()">Buying a new bike?</button>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group text-center">
                                        <button type="submit" class="btn btn-success rounded-5 btn-lg px-5" [disabled]="isLoading">View Prices</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>