import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GLOBAL_CONSTANT } from '../../../constants/global-constant.constants';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UtilitiesService } from '../../../utilities/utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { LoaderService } from '../../../loader/loader.service';

@Component({
  selector: 'app-customer',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
  templateUrl: './customer.component.html',
})
export class CustomerComponent implements OnInit, OnDestroy {

  globalConstant = GLOBAL_CONSTANT;
  projectName = this.globalConstant?.projectName;

  selectedYear: string | null = null;
  selectedMakeId: string | null = null;

  @Input() formGroup!: FormGroup;
  @Input() serverErrors: any = {};
  @Output() yearSelected = new EventEmitter<string>();
  @Output() previous = new EventEmitter<void>();

  constructor(
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private loaderService: LoaderService,

  ) { }

  ngOnInit(): void {

  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.formGroup.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }



  ngOnDestroy(): void {

  }

}
