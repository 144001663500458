import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { FORM_DATE_FORMATS } from '../../../common/constants/moment.constants';

import { GLOBAL_CONSTANT, STATUS_MAP } from '../../../common/constants/global-constant.constants';
import { OFFLINE_SERVICES_ID } from '../../../common/constants/services/offline-services-url.constants';
import { INSURER_ID } from '../../../common/constants/insurer/ids.constants';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { CommonUtilityService } from '../../../common/utility/common-utility.service';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { OfflineTaxiInsuranceOrdersService } from './offline-taxi-insurance-orders.service';

@Component({
  selector: 'app-offline-taxi-insurance-orders',
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: FORM_DATE_FORMATS }
  ],
  templateUrl: './offline-taxi-insurance-orders.component.html'
})
export class OfflineTaxiInsuranceOrdersComponent implements OnInit, AfterViewInit {
  globalConstant = GLOBAL_CONSTANT;
  offlineServicesID = OFFLINE_SERVICES_ID;
  statusMapConstant = STATUS_MAP;
  insurerId = INSURER_ID.WEB_INSURANCE;

  displayedColumns: string[] = ['order_id', 'full_name', 'phone', 'vehicle_number', 'previous_claim', 'policy_type', 'status', 'created_at'];
  dataSource = new MatTableDataSource<any>();
  totalItems = 0;

  sFilter: string = '';
  dateFilter: Date | undefined;
  lastFilters: any = {};
  date_key: string = '';
  order_show_type: string = this.globalConstant?.offline_order_show_type_match?.orders;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('date', { static: false }) date!: MatDatepicker<Date>;
  minDate: Date = this.momentUtilitiesService.getCustomDateTimeAsDateTime(this.globalConstant?.dateTime?.orderFillterMin);
  maxDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();

  private apiOfflineTaxiInsuranceOrdersSubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private commonUtilityService: CommonUtilityService,
    private momentUtilitiesService: MomentUtilitiesService,
    private offlineTaxiInsuranceOrdersService: OfflineTaxiInsuranceOrdersService
  ) { }

  openDatepicker() {
    this.date.open();
  }


  getOffLineServiceShortName(id: number): string {
    return this.utilitiesService.getOffLineServiceShortName(id);
  }

  getOffLineServiceFullName(id: number): string {
    return this.utilitiesService.getOffLineServiceFullName(id);
  }

  getOffLineServiceOrderDetailURL(id: number, uuid: string = ''): string[] {
    return this.utilitiesService.getOffLineServiceOrderDetailURL(id, uuid);
  }

  getStatusDisplay(status: string): string {
    return this.statusMapConstant[status] || status;
  }

  getPolicyTypeDisplay(policyType: string): string {
    return this.commonUtilityService.getLabelByValue('policy_type_utility', this.insurerId, policyType);
  }

  getDBDateTimeShow(date: string): string {
    return this.momentUtilitiesService.getDBDateTimeShow(date);
  }
  
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.paginator && this.sort) {
      this.activatedRoute.params.subscribe(params => {
        this.date_key = params['date_key'] || '';
        this.order_show_type = params['order_show_type'] || '';
      });      
      this.paginator.page.subscribe(() => this.loadData());
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadData();
      });
      this.loadData();
    }
  }

  loadData(): void {
    if (this.paginator && this.sort) {
      const page = this.paginator.pageIndex;
      const size = this.paginator.pageSize;
      const sort = this.sort.active || this.globalConstant.offlineServiceOrderBy;
      const order = this.sort.direction || 'desc';

      const filters: any = {};
      if (this.sFilter) {
        filters.s = this.sFilter;
      }
      if (this.dateFilter) {
        filters.date = this.dateFilter;
      }
      let params = new HttpParams()
        .set('order_show_type', this.order_show_type)
        .set('page', (page + 1).toString())
        .set('size', size.toString())
        .set('sort', sort)
        .set('order', order)
        .set('date_key', this.date_key);
      if (filters.s && filters.s != '') {
        params = params.set('s', filters.s);
      }
      if (filters.date && filters.date != '') {
        let date = this.momentUtilitiesService.formInsertDateFormate(filters.date);
        params = params.set('date', date);
      }

      this.apiOfflineTaxiInsuranceOrdersSubscription = this.offlineTaxiInsuranceOrdersService.getOrders(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
              this.dataSource.data = response.data.items;
              this.totalItems = response.data.total;
            }
          },
          (error) => {
            this.dataSource = new MatTableDataSource<any>();
            this.totalItems = 0;
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  applyFilter(): void {
    if (this.filtersChanged()) {
      if (this.paginator) {
        this.paginator.firstPage();
      }
      this.loadData();
      this.lastFilters = {
        s: this.sFilter,
        date: this.dateFilter
      };
    }
  }

  filtersChanged(): boolean {
    return this.sFilter !== this.lastFilters.s || this.dateFilter !== this.lastFilters.date;
  }

  ngOnDestroy(): void {
    if (this.apiOfflineTaxiInsuranceOrdersSubscription) {
      this.apiOfflineTaxiInsuranceOrdersSubscription.unsubscribe();
    }
  }
}