<div class="vehicle-year vehicleInfo" id="yearDiv">
    <p class="fw-semi text-primary mb-4">Select registration year</p>
    <div class="row">
        @if(arrayLength(getYearList) > 0) {
            <div class="col-md-12">
              <ul class="grouplist mb-4">
                @for(data of getYearList; track i; let i = $index) {
                  <li>
                    <div class="form-check form-check-inline typeradio">
                        <input class="form-check-input" type="radio" name="data" id="{{ data }}" value="{{ data }}" (change)="onRadioSelect(data)" [checked]="isRadioChecked(data)"/>
                        <label class="form-check-label justify-content-center" [for]="data">
                          {{ data }}</label>
                      </div>
                  </li>
                }
              </ul>
            </div>
        }
        <div class="col-sm-12">
            <div class="form-group text-center">
              <button type="button" class="btn btn-secondary rounded-2 btn-lg px-5 me-3" (click)="onPrevious()">Previous</button>
                <button type="button" class="btn btn-success rounded-2 btn-lg px-5" (click)="onContinue()" [disabled]="isLoading">Continue</button>
            </div>
        </div>
    </div>
</div>