import { GLOBAL_CONSTANT } from '../global-constant.constants';
import { ROUTES_OFFLINE_BIKE_INSURANCE } from '../routes/offline-services/offline-bike-insurance.constants';
import { ROUTES_OFFLINE_BUS_INSURANCE } from '../routes/offline-services/offline-bus-insurance.constants';
import { ROUTES_OFFLINE_BUSINESS_INSURANCE } from '../routes/offline-services/offline-business-insurance.constants';
import { ROUTES_OFFLINE_CAR_INSURANCE } from '../routes/offline-services/offline-car-insurance.constants';
import { ROUTES_OFFLINE_CHILD_SAVING_PLAN } from '../routes/offline-services/offline-child-saving-plan.constants';
import { ROUTES_OFFLINE_HEALTH_INSURANCE } from '../routes/offline-services/offline-health-insurance.constants';
import { ROUTES_OFFLINE_HOME_INSURANCE } from '../routes/offline-services/offline-home-insurance.constants';
import { ROUTES_OFFLINE_INVESTMENT_PLAN } from '../routes/offline-services/offline-investment-plan.constants';
import { ROUTES_OFFLINE_LIC_PLAN } from '../routes/offline-services/offline-lic-plan.constants';
import { ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER } from '../routes/offline-services/offline-persoanl-accident-cover.constants';
import { ROUTES_OFFLINE_PICKUP_INSURANCE } from '../routes/offline-services/offline-pickup-insurance.constants';
import { ROUTES_OFFLINE_RETIREMENT_PLAN } from '../routes/offline-services/offline-retirement-plan.constants';
import { ROUTES_OFFLINE_RICKSHAW_INSURANCE } from '../routes/offline-services/offline-rickshaw-insurance.constants';
import { ROUTES_OFFLINE_SMART_SAVING_PLAN } from '../routes/offline-services/offline-smart-saving-plan.constants';
import { ROUTES_OFFLINE_TAXI_INSURANCE } from '../routes/offline-services/offline-taxi-insurance.constants';
import { ROUTES_OFFLINE_TERM_LIFE_INSURANCE } from '../routes/offline-services/offline-term-life-insurance.constants';
import { ROUTES_OFFLINE_TRACTOR_INSURANCE } from '../routes/offline-services/offline-tractor-insurance.constants';
import { ROUTES_OFFLINE_TRAVEL_INSURANCE } from '../routes/offline-services/offline-travel-insurance.constants';

export const OFFLINE_SERVICES_ID = {
  BIKE_INSURANCE: 1,
  CAR_INSURANCE: 2,
  RICKSHAW_INSURANCE: 3,
  TAXI_INSURANCE: 4,
  TRACTOR_INSURANCE: 5,
  TRUCK_BUS_INSURANCE: 6,
  TRUCK_PICKUP_INSURANCE: 7,
  HEALTH_INSURANCE: 8,
  TERM_LIFE_INSURANCE: 9,
  CHILD_SAVING_PLAN: 10,
  INVESTMENT_PLAN: 11,
  RETIREMENT_PLAN: 12,
  SMART_SAVING_PLAN: 13,
  BUSINESS_INSURANCE: 14,
  TRAVEL_INSURANCE: 15,
  HOME_INSURANCE: 16,
  LIC_PLAN: 17,
  PERSONAL_ACCIDENT_COVER: 18,
}

export const OFFLINE_SERVICES_SHORT_NAME = {
  [OFFLINE_SERVICES_ID.BIKE_INSURANCE]: "Bike",
  [OFFLINE_SERVICES_ID.CAR_INSURANCE]: "Car",
  [OFFLINE_SERVICES_ID.RICKSHAW_INSURANCE]: "Rickshaw",
  [OFFLINE_SERVICES_ID.TAXI_INSURANCE]: "Taxi",
  [OFFLINE_SERVICES_ID.TRACTOR_INSURANCE]: "Tractor",
  [OFFLINE_SERVICES_ID.TRUCK_BUS_INSURANCE]: "Truck/Bus",
  [OFFLINE_SERVICES_ID.TRUCK_PICKUP_INSURANCE]: "Truck/Pickup",
  [OFFLINE_SERVICES_ID.HEALTH_INSURANCE]: "Health",
  [OFFLINE_SERVICES_ID.TERM_LIFE_INSURANCE]: "Term Life",
  [OFFLINE_SERVICES_ID.CHILD_SAVING_PLAN]: "Child Saving",
  [OFFLINE_SERVICES_ID.INVESTMENT_PLAN]: "Investment",
  [OFFLINE_SERVICES_ID.RETIREMENT_PLAN]: "Retirement",
  [OFFLINE_SERVICES_ID.SMART_SAVING_PLAN]: "Smart Saving",
  [OFFLINE_SERVICES_ID.BUSINESS_INSURANCE]: "Business",
  [OFFLINE_SERVICES_ID.TRAVEL_INSURANCE]: "Travel",
  [OFFLINE_SERVICES_ID.HOME_INSURANCE]: "Home",
  [OFFLINE_SERVICES_ID.LIC_PLAN]: "LIC",
  [OFFLINE_SERVICES_ID.PERSONAL_ACCIDENT_COVER]: "Personal Accident",
}

export const OFFLINE_SERVICES_FULL_NAME = {
  [OFFLINE_SERVICES_ID.BIKE_INSURANCE]: "Bike Insurance",
  [OFFLINE_SERVICES_ID.CAR_INSURANCE]: "Car Insurance",
  [OFFLINE_SERVICES_ID.RICKSHAW_INSURANCE]: "Rickshaw Insurance",
  [OFFLINE_SERVICES_ID.TAXI_INSURANCE]: "Taxi Insurance",
  [OFFLINE_SERVICES_ID.TRACTOR_INSURANCE]: "Tractor Insurance",
  [OFFLINE_SERVICES_ID.TRUCK_BUS_INSURANCE]: "Truck/Bus Insurance",
  [OFFLINE_SERVICES_ID.TRUCK_PICKUP_INSURANCE]: "Truck/Pickup Insurance",
  [OFFLINE_SERVICES_ID.HEALTH_INSURANCE]: "Health Insurance",
  [OFFLINE_SERVICES_ID.TERM_LIFE_INSURANCE]: "Term Life Insurance",
  [OFFLINE_SERVICES_ID.CHILD_SAVING_PLAN]: "Child Saving Plans",
  [OFFLINE_SERVICES_ID.INVESTMENT_PLAN]: "Investment Plans",
  [OFFLINE_SERVICES_ID.RETIREMENT_PLAN]: "Retirement Plans",
  [OFFLINE_SERVICES_ID.SMART_SAVING_PLAN]: "Smart Saving Plans",
  [OFFLINE_SERVICES_ID.BUSINESS_INSURANCE]: "Business Insurance",
  [OFFLINE_SERVICES_ID.TRAVEL_INSURANCE]: "Travel Insurance",
  [OFFLINE_SERVICES_ID.HOME_INSURANCE]: "Home Insurance",
  [OFFLINE_SERVICES_ID.LIC_PLAN]: "LIC Plans",
  [OFFLINE_SERVICES_ID.PERSONAL_ACCIDENT_COVER]: "Personal Accident Cover",
}

export const OFFLINE_SERVICES_URL: Record<string, any> = {
  step_1: {
    [OFFLINE_SERVICES_ID.BIKE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BIKE_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.RICKSHAW_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.TAXI_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.TRACTOR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.TRUCK_BUS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.TRUCK_PICKUP_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.HEALTH_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.TERM_LIFE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.CHILD_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.INVESTMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.RETIREMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.SMART_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.BUSINESS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.TRAVEL_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.HOME_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HOME_INSURANCE.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.LIC_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_LIC_PLAN.OFFLINE_STEP_ONE],
    [OFFLINE_SERVICES_ID.PERSONAL_ACCIDENT_COVER]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_STEP_ONE],
  },
  step_2: {
    [OFFLINE_SERVICES_ID.BIKE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BIKE_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.RICKSHAW_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.TAXI_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.TRACTOR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.TRUCK_BUS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.TRUCK_PICKUP_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.HEALTH_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.TERM_LIFE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.CHILD_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.INVESTMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.RETIREMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.SMART_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.BUSINESS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.TRAVEL_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.HOME_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HOME_INSURANCE.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.LIC_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_LIC_PLAN.OFFLINE_STEP_TWO],
    [OFFLINE_SERVICES_ID.PERSONAL_ACCIDENT_COVER]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_STEP_TWO]
  },
};

export const OFFLINE_SERVICES_ORDERS_URL: Record<string, any> = {
  [OFFLINE_SERVICES_ID.BIKE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BIKE_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.RICKSHAW_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.TAXI_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.TRACTOR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.TRUCK_BUS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.TRUCK_PICKUP_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.HEALTH_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.TERM_LIFE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.CHILD_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.INVESTMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.RETIREMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.SMART_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.BUSINESS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.TRAVEL_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.HOME_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HOME_INSURANCE.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.LIC_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_LIC_PLAN.OFFLINE_ORDERS],
  [OFFLINE_SERVICES_ID.PERSONAL_ACCIDENT_COVER]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_ORDERS],
};

export const OFFLINE_SERVICES_ORDER_DETAIL_URL: Record<string, any> = {
  [OFFLINE_SERVICES_ID.BIKE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BIKE_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.RICKSHAW_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.TAXI_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.TRACTOR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.TRUCK_BUS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.TRUCK_PICKUP_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.HEALTH_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.TERM_LIFE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.CHILD_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.INVESTMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.RETIREMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.SMART_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.BUSINESS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.TRAVEL_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.HOME_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HOME_INSURANCE.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.LIC_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_LIC_PLAN.OFFLINE_ORDER_DETAILS],
  [OFFLINE_SERVICES_ID.PERSONAL_ACCIDENT_COVER]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_ORDER_DETAILS],
};

export const OFFLINE_SERVICES_ORDER_EDIT_URL: Record<string, any> = {
  [OFFLINE_SERVICES_ID.BIKE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BIKE_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.RICKSHAW_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.TAXI_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.TRACTOR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.TRUCK_BUS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.TRUCK_PICKUP_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.HEALTH_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.TERM_LIFE_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.CHILD_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.INVESTMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.RETIREMENT_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.SMART_SAVING_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.BUSINESS_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.TRAVEL_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.HOME_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_HOME_INSURANCE.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.LIC_PLAN]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_LIC_PLAN.OFFLINE_STEP_ONE],
  [OFFLINE_SERVICES_ID.PERSONAL_ACCIDENT_COVER]: [GLOBAL_CONSTANT.base_url, ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_STEP_ONE],
};

