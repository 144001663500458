import { Injectable } from '@angular/core';
import { ApiService } from '../../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class BlogWidgetService {

  constructor(
    private apiService: ApiService
  ) { }

  getLatestBlog() {
    return this.apiService.request<any>('GET', 'blog/latest');
  }

  getCategoryBlog() {
    return this.apiService.request<any>('GET', 'blog/category');
  }
}
