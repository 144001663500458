import { Component, OnInit } from '@angular/core';
import { filter, Observable, Subscription, take, tap } from 'rxjs';
import { RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_PAGES } from '../../common/constants/routes/pages/pages.constants';
import { ROUTES_OFFLINE_MOTOR_INSURANCE_POLICY } from '../../common/constants/routes/offline-policy/offline-motor-insurance-policy.constants';
import { environment } from '../../../environments/environment';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { UserDashboardService } from './user-dashboard.service';
import { StoreUserProfile, StoreStateUserProfile } from '../../store/reducers/user-profile.reducers';
import { select, Store } from '@ngrx/store';
import { selectStoreUserProfile } from '../../store/selectors/user-profile.selectors';

@Component({
  selector: 'app-user-dashboard',
  standalone: true,
  imports: [
    RouterModule,
  ],
  templateUrl: './user-dashboard.component.html'
})
export class UserDashboardComponent implements OnInit {

  isProduction = environment.production;
  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;
  routesOfflineMotorInsurancePolicy = ROUTES_OFFLINE_MOTOR_INSURANCE_POLICY;

  projectName = this.globalConstant?.projectName;

  serviceList: any[] = [];
  offlineCommission: any = {};

  private apiServiceDataSubscription: Subscription | undefined;
  private apiOfflineCommissionSubscription: Subscription | undefined;

  userProfile$: Observable<StoreUserProfile | null>;
  userType: string = this.globalConstant.user_type.user;

  constructor(
    private store: Store<{ selectStoreUserProfile: StoreStateUserProfile }>,
    private utilitiesService: UtilitiesService,
    private userDashboardService: UserDashboardService
  ) {
    this.userProfile$ = this.store.pipe(select(selectStoreUserProfile));
  }

  ngOnInit(): void {
    this.apiGetPageData();

    this.userProfile$.pipe(
      tap(userProfile => {
        if (userProfile) {
          let user_type = userProfile?.['user_type'] ?? '';
          if (user_type == this.globalConstant.user_type.posp) {
            this.userType = user_type;
            this.apiGetOfflineCommission();
          }
        }
      }),
      filter(userProfile => !!userProfile),
      take(1)
    ).subscribe();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  getOffLineServiceURL(id: number, step: string): string[] {
    return this.utilitiesService.getOffLineServiceURL(id, step);
  }

  showCurrency(currency: number): string {
    return this.utilitiesService.showCurrency(this.globalConstant.currencySymbol, currency);
  }
  
  apiGetPageData() {
    this.apiServiceDataSubscription = this.userDashboardService.getServiceData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.serviceList = response?.data && this.arrayLength(response?.data) > 0 ? response.data : [];
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  apiGetOfflineCommission() {
    this.apiOfflineCommissionSubscription = this.userDashboardService.getOfflineCommission().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.offlineCommission = response?.data && this.objectLength(response?.data) > 0 ? response.data : {};
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiServiceDataSubscription) {
      this.apiServiceDataSubscription.unsubscribe();
    }
    if (this.apiOfflineCommissionSubscription) {
      this.apiOfflineCommissionSubscription.unsubscribe();
    }
  }

}
