import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineTwoWheelerInsurancePaymentService {

  constructor(
    private apiService: ApiService
  ) { }

  getSelectInsurerDetail(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/get-quick-quotation', formData, false);
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/submit-proposal', formData);
  }

  paymentLink(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/generate-payment-link', formData);
  }

}
