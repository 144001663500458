<section class="sec sec-payment pt-3 pb-5">
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-8 mx-auto">
                <div class="card shadow rounded-3">
                    <div class="card-body">
                        <div class="text-center">
                            <div class="mb-1">
                                <i class="bx bx-check-circle text-success fs-1"></i>
                            </div>
                            <h1 class="text-success fs-4">Payment Successful!</h1>
                            <p class="text-muted">Thank you for your payment. Your insurance has been activated successfully.</p>
                        </div>
                      <div class="mt-4">
                        <ul class="list-group list-group-flush text-start">
                          <li class="list-group-item">
                            <span class="fw-semi">Amount:</span> ₹500
                          </li>
                          <li class="list-group-item">
                            <span class="fw-semi">Transaction ID:</span>
                            96966887745
                          </li>
                          <li class="list-group-item">
                            <span class="fw-semi">Date:</span>
                            15/10/2024
                          </li>
                        </ul>
                      </div>

                      <div class="mt-4 text-center">
                        <a href="#" class="btn btn-success btn-lg me-2">
                          Go to Dashboard
                        </a>
                        <a href="#" class="btn btn-dark btn-lg">
                          Download Policies
                        </a>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</section>

