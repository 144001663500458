import { FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { inject } from '@angular/core';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { INSURER_ID } from '../constants/insurer/ids.constants';
import { UtilitiesService } from './utilities.service';
import { CommonUtilityService } from '../utility/common-utility.service';

type UtilityName = 'title_utility' | 'gender_utility' | 'nominee_relation_utility' | 'policy_type_utility' | 'agent_type_utility' | 'education_utility' | 'health_plan_type_utility' | 'document_type_utility' | 'policy_status_utility' | 'occupation_utility' | 'business_nature_utility' | 'insurance_status_utility' | 'personal_habit_utility' | 'medical_history_utility' | 'surgery_past_utility';

function commonUtilityValidation(
    control: AbstractControl,
    utilityName: UtilityName,
    commonUtilityService: CommonUtilityService,
    utilitiesService: UtilitiesService
): ValidationErrors | null {
    let insurerId = INSURER_ID.WEB_INSURANCE;
    const parent = control.parent;
    if (parent) {
        const getInsurerId = parent.get('insurer_id')?.value;
        if (getInsurerId && getInsurerId != '' && utilitiesService.checkInsurerID(getInsurerId)) {
            insurerId = getInsurerId;
        }
    }
    const titleValues = commonUtilityService.getValueArray(utilityName, insurerId);
    if (!titleValues || titleValues.length === 0) {
        return null;
    }
    if (control.value && !titleValues.includes(control.value)) {
        return { invalidUserType: { value: control.value } };
    }
    return null;
}

export function titleValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'title_utility', commonUtilityService, utilitiesService);
    };
}

export function genderValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'gender_utility', commonUtilityService, utilitiesService);
    };
}

export function nomineeRelationValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'nominee_relation_utility', commonUtilityService, utilitiesService);
    };
}

export function policyTypeValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'policy_type_utility', commonUtilityService, utilitiesService);
    };
}

export function agentTypeValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'agent_type_utility', commonUtilityService, utilitiesService);
    };
}

export function educationValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'education_utility', commonUtilityService, utilitiesService);
    };
}

export function occupationValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'occupation_utility', commonUtilityService, utilitiesService);
    };
}

export function businessNatureValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'business_nature_utility', commonUtilityService, utilitiesService);
    };
}

export function insuranceStatusValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'insurance_status_utility', commonUtilityService, utilitiesService);
    };
}

export function personalHabitValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'personal_habit_utility', commonUtilityService, utilitiesService);
    };
}

export function medicalHistoryValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'medical_history_utility', commonUtilityService, utilitiesService);
    };
}

export function surgeryPastValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'surgery_past_utility', commonUtilityService, utilitiesService);
    };
}

export function healthPlanTypeValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'health_plan_type_utility', commonUtilityService, utilitiesService);
    };
}

export function documentTypeValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'document_type_utility', commonUtilityService, utilitiesService);
    };
}

export function policyStatusValidator(): ValidatorFn {
    const commonUtilityService = inject(CommonUtilityService);
    const utilitiesService = inject(UtilitiesService);
    return (control: AbstractControl): ValidationErrors | null => {
        return commonUtilityValidation(control, 'policy_status_utility', commonUtilityService, utilitiesService);
    };
}

export function trimValidator(control: FormControl) {
    if (control.value && typeof control.value === 'string') {
        const trimmedValue = control.value.trim();
        control.patchValue(trimmedValue, { emitEvent: false });
    }
    return null;
}

export function panCardValidator(control: AbstractControl): ValidationErrors | null {
    const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    if (!control.value) {
        return null;
    }
    const valid = panRegex.test(control.value);
    return valid ? null : { invalidPAN: true };
}

export function aadharCardValidator(control: AbstractControl): ValidationErrors | null {
    const aadharRegex = /^\d{12}$/;
    if (!control.value) {
        return null;
    }
    const valid = aadharRegex.test(control.value);
    return valid ? null : { invalidAadhar: true };
}

export function ifscCodeValidator(control: AbstractControl): ValidationErrors | null {
    const ifscRegex = /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/
    if (!control.value) {
        return null;
    }
    const valid = ifscRegex.test(control.value);
    return valid ? null : { invalidIFSC: true };
}

export function gstNumberValidator(control: AbstractControl): ValidationErrors | null {
    const gstRegex = /^[A-Z]{2}[A-Z0-9]{10}[A-Z]{1}[0-9]{1}[A-Z]{1}$/;
    if (!control.value) {
        return null;
    }
    const valid = gstRegex.test(control.value);
    return valid ? null : { invalidGSTNumber: true };
}

export function confirmationValidator(controlName: string, confirmControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const mainControl = control.get(controlName);
        const confirmControl = control.get(confirmControlName);
        if (!mainControl || !confirmControl) {
            return null;
        }
        if (!confirmControl.value) {
            return null;
        }
        if (mainControl.value === confirmControl.value) {
            confirmControl.setErrors(null);
        } else {
            confirmControl.setErrors({ mismatch: true });
        }
        return null;
    };
}

export function vehicleNumberValidator(control: AbstractControl): ValidationErrors | null {
    const vehicleNumberRegex = /^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/;
    if (!control.value) {
        return null;
    }
    const isValid = vehicleNumberRegex.test(control.value);
    return isValid ? null : { invalidVehicleNumber: true };
}

export function dateFormatValidator(): ValidatorFn {
    const dateFormatRegex = GLOBAL_CONSTANT.regex.dateFormatRegex;
    const formDateField = GLOBAL_CONSTANT.dateTime.formDateField;

    return (control: AbstractControl): ValidationErrors | null => {
        let dateValue = control.value;
        if (dateValue && dateValue.isValid && typeof dateValue.format === 'function') {
            dateValue = dateValue.format(formDateField);
        }
        if (dateValue && !dateFormatRegex.test(dateValue)) {
            return { invalidDateFormat: { value: dateValue } };
        }
        return null;
    };
}

export function zeroOneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value === '0' || value === '1') {
            return null;
        }
        return { invalidInsuranceValue: { value: control.value } };
    };
}

export function handleFileSelection(event: Event, allowedExtensions: string[], maxFileSize: number): { status: boolean, file: File | null, error: object | null } {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];

        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.split('.').pop();
        if (!fileExtension || allowedExtensions.indexOf(fileExtension) === -1) {
            return {
                status: false,
                file: null,
                error: { fileType: `${allowedExtensions.join(', ')}` }
            };
        }

        if (file.size > maxFileSize) {
            return {
                status: false,
                file: null,
                error: { fileSize: `${maxFileSize / (1024 * 1024)}` }
            };
        }

        return {
            status: true,
            file,
            error: null
        };
    }
    return {
        status: false,
        file: null,
        error: null
    };
}

export function handleReadFile(file: File, callback: (result: string) => void): void {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
        if (file.type && file.type === 'application/pdf') {
            const result = 'images/icons/pdf.png';
            callback(result);
        } else {
            const result = e.target?.result as string;
            callback(result);
        }
    };
    reader.readAsDataURL(file);
}
