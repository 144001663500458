import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ROUTES_POSP_BLOG } from '../common/constants/routes/posp-blog/posp-blog.constants';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UtilitiesService } from '../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../common/utilities/moment-utilities.service';
import { GLOBAL_CONSTANT } from '../common/constants/global-constant.constants';
import { PospBlogWidgetComponent } from '../common/layouts/blog/posp-blog-widget/posp-blog-widget.component';
import { PospBlogService } from './posp-blog.service';

@Component({
  selector: 'app-posp-blog',
  standalone: true,
  imports: [
    RouterModule,
    NgIf,
    NgFor,
    MatPaginatorModule,
    PospBlogWidgetComponent
  ],
  templateUrl: './posp-blog.component.html'
})
export class PospBlogComponent implements OnInit, AfterViewInit, OnDestroy {

  globalConstant = GLOBAL_CONSTANT;
  routesPospBlog = ROUTES_POSP_BLOG;
  projectName = this.globalConstant?.projectName;

  dataSource = new MatTableDataSource<any>();
  totalItems = 0;

  serverErrors: { [key: string]: string[] } = {};
  sFilter: string = '';
  dateFilter: Date | undefined;
  lastFilters: any = {};

  pospBlogData: any = {};
  category_slug: string = '';

  isLoading = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private apiPospBlogDataSubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private pospBlogService: PospBlogService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.category_slug = params['category_slug'] || '';
      this.loadData();
    });
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      setTimeout(() => {
        this.paginator.page.subscribe(() => this.loadData());
        this.loadData();
      });
    } else {
    }
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.DD_MMM_YYYY);
  }

  sanitizeHtml(html: string) {
    return this.utilitiesService.sanitizeHtml(html);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  loadData(): void {
    if (!this.paginator) {
      return;
    }

    this.isLoading = true;

    const page = this.paginator.pageIndex + 1; 
    const size = this.paginator.pageSize;

    const filters: any = {};
    if (this.sFilter) filters.s = this.sFilter;
    if (this.dateFilter) filters.date = this.dateFilter;

    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (this.category_slug) {
      params = params.set('category_slug', this.category_slug);
    }

    if (filters.s) {
      params = params.set('s', filters.s);
    }
    
    this.apiPospBlogDataSubscription = this.pospBlogService.getPospBlog(params).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data?.items) {
            this.dataSource.data = response.data.items;
            this.totalItems = response.data.total;
          } else {
            this.dataSource.data = [];
            this.totalItems = 0;
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.dataSource.data = [];
          this.totalItems = 0;
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiPospBlogDataSubscription) {
      this.apiPospBlogDataSubscription.unsubscribe();
    }
  }
}
