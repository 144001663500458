import { Action, createReducer, on } from '@ngrx/store';
import { storeSetCategoryBlog, storeResetCategoryBlog } from '../../../actions/blog/blog/category-blog.actions';

export interface StoreCategoryBlog { [key: string]: any; }

export interface StoreStateCategoryBlog {
  storeData: StoreCategoryBlog | null;
}

export const initialState: StoreStateCategoryBlog = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetCategoryBlog, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetCategoryBlog, state => ({ ...state, storeData: null }))
);

export function reducerCategoryBlog(state: StoreStateCategoryBlog | undefined, action: Action) { return _storeReducer(state, action); }