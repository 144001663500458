@if(latestPospBlogData && arrayLength(latestPospBlogData) > 0) {
    <div class="mb-4">                    
        <div class="blog-detail-box rounded-0 text-white">
            <p class="px-3 py-1 mb-0 font_18">Latest Blogs</p>
        </div>
        <div class="px-3 pt-3 border">
            <ul class="latest_post m-0 p-0">
                @for(data of latestPospBlogData; track i; let i=$index) {
                    <li>
                        <div class="post_pic">
                            <a [routerLink]="[globalConstant.base_url, routesPospBlog.DETAIL || '', data.slug]">
                                <img [src]="globalConstant.storage_base_url + data.thumbnail" class="img-fluid" width="80" height="53" [alt]="data.title" />
                            </a>
                        </div>
                        <div class="post_info">
                            <p class="lh-1 fw-semibold mb-1 heading">
                                <a [routerLink]="[globalConstant.base_url, routesPospBlog.DETAIL || '', data.slug]" class="text-dark">
                                    {{ data.title }}
                                </a>
                            </p>
                            <p class="m-0 fs-14"><i class="bx bx-calendar pe-1"></i>
                                <span class="text-uppercase">
                                    @if(data?.posted_at && data?.posted_at != '') {
                                        {{ formatDate(data?.posted_at) }}
                                    }
                                </span>
                            </p>
                        </div>
                    </li>
                }
            </ul>
        </div>
    </div>
}
@if(categoryPospBlogData && arrayLength(categoryPospBlogData) > 0) {
    <div>
        <div class="blog-detail-box rounded-0 text-white">
            <p class="px-3 py-1 mb-0 font_18">Blog Categories</p>
        </div>                        
        <ul class="border tagging_tags d-flex flex-wrap p-3">
            @for(data of categoryPospBlogData; track i; let i=$index) {
                <li>
                    <a [routerLink]="[globalConstant.base_url, routesPospBlog.INDEX || '', data?.category_slug || '']" class="tag_group">{{ data?.category_name }}</a>
                </li>
            }
        </ul>
    </div>
}