import { INSURER_ID } from "../constants/insurer/ids.constants";

export const OccupationUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'salaried', label: 'Salaried' },
    { value: 'self_employed', label: 'Self Employed' },
    { value: 'professional', label: 'Professional' },
    { value: 'retired', label: 'Retired' },
    { value: 'student', label: 'Student' },
    { value: 'agriculturist', label: 'Agriculturist' },
    { value: 'other', label: 'Other' }
  ]
};

export type OccupationUtilityType = {
  [key: number]: { value: string; label: string }[];
};
