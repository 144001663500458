import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { INSURER_ID } from '../../../common/constants/insurer/ids.constants';
import { ROUTES_POSP_KYC } from '../../../common/constants/routes/posp/posp-kyc.constants';
import { FORM_DATE_FORMATS } from '../../../common/constants/moment.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { NumericInputDirective } from '../../../common/directives/numeric-input.directive';
import { handleFileSelection, handleReadFile } from '../../../common/utilities/form-rules';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { CommonUtilityService } from '../../../common/utility/common-utility.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { DownloadService } from '../../../common/utilities/download.service';
import { LoaderService } from '../../../common/loader/loader.service';
import { PospKycService } from '../../../common/utilities/posp-kyc.service';
import { navigatePopsKycProcessStatus } from '../../../common/utilities/navigate-posp-status';
import { PospKycBasicDetailsService } from './posp-kyc-basic-details.service';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-posp-kyc-basic-details',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NumericInputDirective
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: FORM_DATE_FORMATS }
  ],
  templateUrl: './posp-kyc-basic-details.component.html'
})
export class PospKycBasicDetailsComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospKyc = ROUTES_POSP_KYC;
  insurerId = INSURER_ID.WEB_INSURANCE;

  projectName = this.globalConstant?.projectName;

  customForm: FormGroup;
  profileDocFile: File | null = null;
  serverErrors: { [key: string]: string[] } = {};

  getAgentType: any[] = [];
  getState: any[] = [];
  profileDocFileUrl: string = '';

  isLoading = false;

  private apiGetFormDataSubscription: Subscription | undefined;
  private apiGetStateSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private pospKycService: PospKycService,
    private pospKycBasicDetailsService: PospKycBasicDetailsService,
    private momentUtilitiesService: MomentUtilitiesService,
    private utilitiesService: UtilitiesService,
    private commonUtilityService: CommonUtilityService,
    private formUtilitiesService: FormUtilitiesService,
    private downloadService: DownloadService
  ) {
    this.customForm = getFormValidators();
  }

  ngOnInit(): void {
    this.apiGetFormData();
    this.apiGeAgentType();
    this.apiGetState();
  }

  @ViewChild('date_of_birth', { static: false }) date_of_birth_datepicker!: MatDatepicker<Date>;
  maxDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.minDobYear);
  minDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);

  openDobDatepicker() {
    this.date_of_birth_datepicker.open();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  isPdfOrImage(url: string): string {
    return this.utilitiesService.isPdfOrImage(url);
  }

  datePickerInput(event: KeyboardEvent): void {
    this.utilitiesService.datePickerInput(event);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  apiGetFormData() {
    this.isLoading = true;
    this.apiGetFormDataSubscription = this.pospKycService.getFormData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.isLoading = false;
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            let userData = response?.data;
            const navigatedRoute = navigatePopsKycProcessStatus(userData);
            if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
              this.router.navigate(navigatedRoute.route as string[]);
            }
            let userDetail: any = {};
            if (response?.data?.posp_detail && this.utilitiesService.objectLength(response?.data?.posp_detail) > 0) {
              userDetail = response?.data?.posp_detail;
            }
            this.customForm.patchValue({
              first_name: userData?.first_name || '',
              middle_name: userData?.middle_name || '',
              last_name: userData?.last_name || '',
              email: userData?.email || '',
              date_of_birth: userDetail?.date_of_birth || '',
              gender: userDetail?.gender || '',
              agent_type: userDetail?.agent_type || '',
              state_code: userDetail?.state_code || '',
              city: userDetail?.city || '',
              pincode: userDetail?.pincode || '',
              address: userDetail?.address || ''
            });
            if (userDetail?.profile_doc && userDetail?.profile_doc != '') {
              this.profileDocFileUrl = this.globalConstant?.storage_base_url + userDetail?.profile_doc;
              this.customForm.get('profile_doc')?.clearValidators();
              this.customForm.get('profile_doc')?.updateValueAndValidity();
            }
          }
        },
        (error) => {
          this.isLoading = false;
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  apiGeAgentType() {
    let getAgentTypeUtility = this.commonUtilityService.getUtility('agent_type_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getAgentTypeUtility && this.utilitiesService.arrayLength(getAgentTypeUtility) > 0) {
      this.getAgentType = getAgentTypeUtility;
    }
  }

  apiGetState() {
    this.apiGetStateSubscription = this.pospKycBasicDetailsService.getState({
      'insurer_id': this.utilitiesService.insurerID(this.insurerId)
    }).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getState = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  onProfileDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('profile_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.profileDocFile = file;
      handleReadFile(this.profileDocFile, (result: string) => {
        this.profileDocFileUrl = result;
      });
    }
  }

  onProfileDocFileRemove(): void {
    this.profileDocFile = null;
    this.profileDocFileUrl = '';
    this.customForm.get('profile_doc')?.reset();
    this.customForm.get('profile_doc')?.setValidators([Validators.required]);
    this.customForm.get('profile_doc')?.updateValueAndValidity();
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      let date_of_birth = '';
      if (this.customForm.value.date_of_birth) {
        date_of_birth = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.date_of_birth);
      }
      const data = {
        kyc_step: this.customForm.value.kyc_step,
        first_name: this.customForm.value.first_name,
        middle_name: this.customForm.value.middle_name,
        last_name: this.customForm.value.last_name,
        email: this.customForm.value.email,
        date_of_birth: date_of_birth,
        state_code: this.customForm.value.state_code,
        city: this.customForm.value.city,
        pincode: this.customForm.value.pincode,
        address: this.customForm.value.address,
        agent_type: this.customForm.value.agent_type,
        gender: this.customForm.value.gender
      };
      let formData = this.formUtilitiesService.toFormData(data);
      if (this.profileDocFile) {
        formData.append('profile_doc', this.profileDocFile, this.profileDocFile.name);
      }
      this.apiSubmitFormDataSubscription = this.pospKycService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            this.router.navigate([this.globalConstant.base_url, this.routesPospKyc.POSP_KYC || '', this.routesPospKyc.POSP_KYC_PAN_AADHAR_DETAILS || '']);
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetFormDataSubscription) {
      this.apiGetFormDataSubscription.unsubscribe();
    }
    if (this.apiGetStateSubscription) {
      this.apiGetStateSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }

}
