import { INSURER_ID } from "../constants/insurer/ids.constants";

export const DocumentTypeUtility = {
  [INSURER_ID.GO_DIGIT_MOTOR]: [
    { value: 'ckyc', label: 'CKYC' },
    { value: 'aadhar_card', label: 'Aadhar Card' },
    { value: 'driving_license', label: 'Driving License' },
    { value: 'voter_id', label: 'Voter ID' },
    { value: 'passport', label: 'Passport' },
    { value: 'pan_card', label: 'Pan Card' },
    { value: 'gst', label: 'GST' },
    { value: 'coi', label: 'COI' }
  ]
};

export type DocumentTypeUtilityType = {
  [key: number]: { value: string; label: string }[];
};
