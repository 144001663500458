import { INSURER_ID } from "../constants/insurer/ids.constants";

export const EducationUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'post_graduate_above', label: 'Post Graduate & Above' },
    { value: 'graduate', label: 'Graduate' },
    { value: 'diploma', label: 'Diploma' },
    { value: '12_pass', label: '12th Pass' },
    { value: '10_pass', label: '10th Pass' },
    { value: 'below_10', label: 'Below 10th' }
  ]
};

export type EducationUtilityType = {
  [key: number]: { value: string; label: string }[];
};
