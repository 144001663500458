<div class="container">
    <div class="row">
        <div class="bg-white py-3">
            <div class="d-flex py-1 px-2 bg-vlight justify-content-between align-items-center">
                <h1 class="fs-5 mb-0">{{ globalConstant.offline_policy_insurance_name.motor_insurance }}</h1>
                <div class="order-filters">
                    <div class="search-filter search_bx">
                        <mat-form-field>
                            <input matInput [(ngModel)]="sFilter" (keyup.enter)="applyFilter()" (focusout)="applyFilter()" placeholder="Search" class="form-control" autocomplete="off" />
                        </mat-form-field>
                    </div>
                    <!-- <div class="search-filter date_bx ms-3">
                        <mat-form-field>
                            <input matInput [matDatepicker]="date" [(ngModel)]="dateFilter" (dateChange)="applyFilter()"
                                (click)="openDatepicker()" [min]="minDate" [max]="maxDate" id="date"
                                placeholder="MM/DD/YYYY" class="form-control" autocomplete="off" />
                            <mat-datepicker #date></mat-datepicker>
                        </mat-form-field>
                    </div> -->
                </div>
            </div>
            <div class="box_outer border rounded service-sidebar">
                <div class="row">
                    <div class="col-md-12 innerpages">
                        <div class="table-responsive custom-mat-table">
                            <table class="table table-bordered table-striped" mat-table [dataSource]="dataSource"
                                matSort>
                                <ng-container matColumnDef="policy_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY ID </th>
                                    <td mat-cell *matCellDef="let row">
                                        <!-- <a [routerLink]="[globalConstant.base_url, routesOfflineMotorInsurancePolicy.DETAIL || '', row?.uuid || '']" class="text-primary fw-semi"> -->
                                            {{ row?.policy_id }}
                                        <!-- </a> -->
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="policy_number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY NUMBER </th>
                                    <td mat-cell *matCellDef="let row"> {{ row?.policy_number }} </td>
                                </ng-container>

                                <ng-container matColumnDef="full_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CUSTOMER NAME </th>
                                    <td mat-cell *matCellDef="let row"> {{ row?.motor_insurance_policy_data?.full_name }} </td>
                                </ng-container>

                                <ng-container matColumnDef="phone">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> MOBILE NO. </th>
                                    <td mat-cell *matCellDef="let row"> {{ row?.motor_insurance_policy_data?.phone }} </td>
                                </ng-container>

                                <ng-container matColumnDef="vehicle_number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> VEHICLE NUMBER </th>
                                    <td mat-cell *matCellDef="let row"> {{ row?.motor_insurance_policy_data?.vehicle_number }} </td>
                                </ng-container>

                                <ng-container matColumnDef="policy_type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY TYPE </th>
                                    <td mat-cell *matCellDef="let row"> {{ row?.motor_insurance_policy_data?.policy_type_text }} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="policy_start_at">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY START DATE </th>
                                    <td mat-cell *matCellDef="let row">
                                        @if(row?.policy_start_at && row?.policy_start_at != '') {
                                            {{ getDBDateShow(row?.policy_start_at) }}
                                        }
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="policy_end_at">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> POLICY END DATE </th>
                                    <td mat-cell *matCellDef="let row">
                                        @if(row?.policy_end_at && row?.policy_end_at != '') {
                                            {{ getDBDateShow(row?.policy_end_at) }}
                                        }
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="package_policy_doc">
                                    <th mat-header-cell *matHeaderCellDef> POLICY </th>
                                    <td mat-cell *matCellDef="let row">
                                        <div class="policy_doc_list d-flex justify-content-between align-items-center">
                                            @if(row?.package_policy_media_data?.policy_id && row?.package_policy_media_data?.file_path != '' && row?.package_policy_media_data?.file_name != "") {
                                                <i class="bx bxs-download fs-5 text-success" (click)="downloadDocument(globalConstant.storage_base_url + (row?.package_policy_media_data?.file_path || '') + '/' + (row?.package_policy_media_data?.file_name || ''))"></i>
                                            }
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="insurer_name">
                                    <th mat-header-cell *matHeaderCellDef> INSURANCE COMPANY </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ row?.insurer_data?.company_name }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="amount_received">
                                    <th mat-header-cell *matHeaderCellDef> TOTAL PPEMIUM </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ showCurrency(row?.payment_data?.amount_received) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="gst_amount">
                                    <th mat-header-cell *matHeaderCellDef> GST AMOUNT </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ showCurrency(row?.payment_data?.gst_amount) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="net_premium">
                                    <th mat-header-cell *matHeaderCellDef> NET PREMIUM </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ showCurrency(row?.payment_data?.net_premium) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="own_damage_amount">
                                    <th mat-header-cell *matHeaderCellDef> OWN DAMAGE AMOUNT </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ showCurrency(row?.payment_data?.own_damage_amount) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="third_party_amount">
                                    <th mat-header-cell *matHeaderCellDef> THIRD PARTY AMOUNT </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ showCurrency(row?.payment_data?.third_party_amount) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="own_damage_commission_amount">
                                    <th mat-header-cell *matHeaderCellDef> OWN DAMAGE COMMISSION AMOUNT </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ showCurrency(row?.user_commission_data?.own_damage_commission_amount) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="third_party_commission_amount">
                                    <th mat-header-cell *matHeaderCellDef> THIRD PARTY COMMISSION AMOUNT </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ showCurrency(row?.user_commission_data?.third_party_commission_amount) }}
                                    </td>
                                </ng-container>
                                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length"> {{
                                        globalConstant.noOrderFoundMessage }} </td>
                                </tr> 
                            </table>
                            <div class="custom-mat-paginator">
                                <mat-paginator [length]="totalItems" [hidePageSize]="true"
                                    [pageSize]=globalConstant.paginate.perPage>
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>