import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { OFFLINE_SERVICES_FULL_NAME, OFFLINE_SERVICES_SHORT_NAME, OFFLINE_SERVICES_URL, OFFLINE_SERVICES_ORDERS_URL, OFFLINE_SERVICES_ORDER_DETAIL_URL, OFFLINE_SERVICES_ORDER_EDIT_URL } from '../constants/services/offline-services-url.constants';
import { ONLINE_SERVICES_URL } from '../constants/services/online-services-url.constants';

@Injectable({
  providedIn: 'root'
})

export class UtilitiesService {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  checkInsurerID(insurerID: any): boolean {
    if(Number(insurerID)) {
      return true;
    } 
    return false;
  }

  insurerID(insurerID: any): number {
    return Number(insurerID);
  }

  checkArray(arr: any): boolean {
    return Array.isArray(arr);
  }

  arrayLength(arr: any[]): number {
    return this.checkArray(arr) ? arr.length : 0;
  }

  checkObject(obj: any): boolean {
    return typeof obj === 'object' && obj !== null;
  }

  objectLength(obj: any): number {
    return this.checkObject(obj) ? Object.keys(obj).length : 0;
  }

  checkNumber(value: any): boolean {
    return Number.isFinite(value);
  }

  checkUUID(value: any): boolean {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return typeof value === 'string' && uuidRegex.test(value);
  }

  getOffLineServiceShortName(id: number): string {
    return OFFLINE_SERVICES_SHORT_NAME[id] || '';
  }

  getOffLineServiceFullName(id: number): string {
    return OFFLINE_SERVICES_FULL_NAME[id] || '';
  }

  getOnLineServiceURL(id: number, step: string): string[] {
    const stepUrls = ONLINE_SERVICES_URL[step] || {};
    const url = stepUrls[id] || [GLOBAL_CONSTANT?.base_url || '/'];
    return url;
  }

  getOffLineServiceURL(id: number, step: string): string[] {
    const stepUrls = OFFLINE_SERVICES_URL[step] || {};
    const url = stepUrls[id] || [GLOBAL_CONSTANT?.base_url || '/'];
    return url;
  }

  getOffLineServiceOrdersURL(id: number, orderShowType: string = GLOBAL_CONSTANT?.offline_order_show_type?.orders, dateKey: string = ''): string[] {
    const stepUrls = OFFLINE_SERVICES_ORDERS_URL || {};
    if (stepUrls[id]) {
      let url = stepUrls[id];
      if (orderShowType != '' && GLOBAL_CONSTANT?.offline_order_show_type_array.includes(orderShowType)) {
        url = [...url, orderShowType.toString()];
      } else {
        orderShowType = GLOBAL_CONSTANT?.offline_order_show_type_match?.orders;
        url = [...url, orderShowType.toString()];
      }
      if (dateKey != '' && GLOBAL_CONSTANT?.date_key_array.includes(dateKey)) {
        url = [...url, dateKey.toString()];
      }
      return url;
    }
    let url = [GLOBAL_CONSTANT?.base_url || '/'];
    return url;
  }

  getOffLineServiceOrderDetailURL(id: number, uuid: string): string[] {
    const stepUrls = OFFLINE_SERVICES_ORDER_DETAIL_URL || {};
    if (stepUrls[id]) {
      let url = stepUrls[id];
      if (uuid != '') {
        return [...url, uuid.toString()];
      }
      return url;
    }
    let url = [GLOBAL_CONSTANT?.base_url || '/'];
    return url;
  }

  getOffLineServiceOrderEditURL(id: number, uuid: string): string[] {
    const stepUrls = OFFLINE_SERVICES_ORDER_EDIT_URL || {};
    if (stepUrls[id]) {
      let url = stepUrls[id];
      if (uuid != '') {
        return [...url, uuid.toString()];
      }
      return url;
    }
    let url = [GLOBAL_CONSTANT?.base_url || '/'];
    return url;
  }

  isOrderEditable(status: string): boolean {
    const orderEditableStatus = GLOBAL_CONSTANT?.orderEditableStatus || [];
    return orderEditableStatus.includes(status);
  }

  getFileExtension(url: string): string | null {
    if (typeof url !== 'string' || url.trim() === '') {
      return null;
    }
    const urlParts = url.split('.');
    if (urlParts.length > 1) {
      return urlParts.pop()?.toLowerCase() || null;
    }
    return null;
  }

  isPdfOrImage(url: string): 'pdf' | 'image' | 'unknown' {
    const extension = this.getFileExtension(url);
    if (!extension) {
      return 'unknown';
    }
    const imageExtensions = GLOBAL_CONSTANT?.file_setting?.image_extensions;
    if (extension === 'pdf') {
      return 'pdf';
    } else if (imageExtensions.includes(extension)) {
      return 'image';
    } else {
      return 'unknown';
    }
  }

  getFilenameFromUrl(url: string): string {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  handleSubscription(nextCallback: (response: any) => void, errorCallback: (error: any) => void) {
    return {
      next: (response: any) => {
        nextCallback(response);
      },
      error: (error: any) => {
        errorCallback(error);
      }
    };
  }

  handleHttpError(error: any): Observable<never> {
    let errorMessage = 'An error occurred';
    if (error instanceof Error) {
      errorMessage = `Error: ${error.message}`;
    } else if (error.status) {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    } else {
      errorMessage = `Unexpected error: ${error}`;
    }
    return throwError(errorMessage);
  }

  trimValidator(control: FormControl) {
    if (control.value && typeof control.value === 'string') {
      const trimmedValue = control.value.trim();
      control.patchValue(trimmedValue, { emitEvent: false });
    }
    return null;
  }

  showCurrency(currencySymbol: string, currency: number): string {
    if (currency != null) {
      return currencySymbol + currency;
    } else {
      return '';
    }
  }

  sanitizeHtml(html: string): SafeHtml {
    if (html && html.trim()) {
      const formattedHtml = html.trim();
      return this.sanitizer.bypassSecurityTrustHtml(formattedHtml);
    }
    return this.sanitizer.bypassSecurityTrustHtml('');
  }

  datePickerInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', '-', '/'];
    if (!allowedKeys.includes(event.key) && (event.key < '0' || event.key > '9')) {
      event.preventDefault();
    }
  }
}
