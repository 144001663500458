import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { filter, Observable, Subscription, take, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { GLOBAL_CONSTANT } from '../../../constants/global-constant.constants';
import { ROUTES_POSP_BLOG } from '../../../constants/routes/posp-blog/posp-blog.constants';

import { UtilitiesService } from '../../../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../../utilities/moment-utilities.service';

import { StoreLatestPospBlog, StoreStateLatestPospBlog } from '../../../../store/reducers/blog/posp-blog/latest-posp-blog.reducers';
import { StoreCategoryPospBlog, StoreStateCategoryPospBlog } from '../../../../store/reducers/blog/posp-blog/category-posp-blog.reducers';
import { selectStoreLatestPospBlog } from '../../../../store/selectors/blog/posp-blog/latest-posp-blog.selectors';
import { selectStoreCategoryPospBlog } from '../../../../store/selectors/blog/posp-blog/category-posp-blog.selectors';
import { storeSetLatestPospBlog, storeResetLatestPospBlog } from '../../../../store/actions/blog/posp-blog/latest-posp-blog.actions';
import { storeSetCategoryPospBlog, storeResetCategoryPospBlog } from '../../../../store/actions/blog/posp-blog/category-posp-blog.actions';

import { PospBlogWidgetService } from './posp-blog-widget.service';

@Component({
  selector: 'app-posp-blog-widget',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './posp-blog-widget.component.html'
})
export class PospBlogWidgetComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospBlog = ROUTES_POSP_BLOG;

  private apiLatestPospBlogDataSubscription: Subscription | undefined;
  private apiCategoryPospBlogDataSubscription: Subscription | undefined;

  latestPospBlogData: any = {};
  categoryPospBlogData: any = {};

  latestPospBlog$: Observable<StoreLatestPospBlog | null>;
  categoryPospBlog$: Observable<StoreCategoryPospBlog | null>;

  constructor(
    private store: Store<{
      selectStoreLatestPospBlog: StoreStateLatestPospBlog,
      selectStoreCategoryPospBlog: StoreStateCategoryPospBlog
    }>,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private pospBlogWidgetService: PospBlogWidgetService
  ) {
    this.latestPospBlog$ = this.store.pipe(select(selectStoreLatestPospBlog));
    this.categoryPospBlog$ = this.store.pipe(select(selectStoreCategoryPospBlog));
  }

  ngOnInit(): void {
    this.getLatestPospBlog();
    this.getCategoryPospBlog();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.DD_MMM_YYYY);
  }

  getLatestPospBlog(): void {
    this.latestPospBlog$.pipe(
      tap(latestPospBlog => {
        if (!latestPospBlog) {
          this.apiGetLatestPospBlog();
        } else {
          this.latestPospBlogData = latestPospBlog;
        }
      }),
      filter(latestPospBlog => !!latestPospBlog),
      take(1)
    ).subscribe();
  }

  getCategoryPospBlog(): void {
    this.categoryPospBlog$.pipe(
      tap(categoryPospBlog => {
        if (!categoryPospBlog) {
          this.apiGetCategoryPospBlog();
        } else {
          this.categoryPospBlogData = categoryPospBlog;
        }
      }),
      filter(categoryPospBlog => !!categoryPospBlog),
      take(1)
    ).subscribe();
  }

  apiGetLatestPospBlog() {
    this.apiLatestPospBlogDataSubscription = this.pospBlogWidgetService.getLatestPospBlog().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.arrayLength(response?.data) > 0) {
            this.store.dispatch(storeSetLatestPospBlog({ storeData: response?.data }));
            this.latestPospBlogData = response?.data;
          } else {
            this.store.dispatch(storeResetLatestPospBlog());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  apiGetCategoryPospBlog() {
    this.apiCategoryPospBlogDataSubscription = this.pospBlogWidgetService.getCategoryPospBlog().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.arrayLength(response?.data) > 0) {
            this.store.dispatch(storeSetCategoryPospBlog({ storeData: response?.data }));
            this.categoryPospBlogData = response?.data;
          } else {
            this.store.dispatch(storeResetCategoryPospBlog());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiLatestPospBlogDataSubscription) {
      this.apiLatestPospBlogDataSubscription.unsubscribe();
    }
    if (this.apiCategoryPospBlogDataSubscription) {
      this.apiCategoryPospBlogDataSubscription.unsubscribe();
    }
  }

}
