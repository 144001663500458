import { INSURER_ID } from "../constants/insurer/ids.constants";

export const MedicalHistoryUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]
};

export type MedicalHistoryUtilityType = {
  [key: number]: { value: string; label: string }[];
};
