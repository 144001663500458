import { Routes } from '@angular/router';
import { checkUsersGuard } from './common/guards/check-users.guard';
import { ROUTES_PAGES } from './common/constants/routes/pages/pages.constants';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagesRoutes } from './common/routes/pages/pages.routes';
import { UsersRoutes } from './common/routes/users/users.routes';
import { PospRoutes } from './common/routes/posp/posp.routes';
import { PospKycRoutes } from './common/routes/posp/posp-kyc.routes';
import { PospTrainingRoutes } from './common/routes/posp/posp-training.routes';
import { PospExamRoutes } from './common/routes/posp/posp-exam.routes';
import { OfflineBikeInsuranceRoutes } from './common/routes/offline-services/offline-bike-insurance.routes';
import { OfflineBusInsuranceRoutes } from './common/routes/offline-services/offline-bus-insurance.routes';
import { OfflineBusinessInsuranceRoutes } from './common/routes/offline-services/offline-business-insurance.routes';
import { OfflineCarInsuranceRoutes } from './common/routes/offline-services/offline-car-insurance.routes';
import { OfflineChildSavingPlanRoutes } from './common/routes/offline-services/offline-child-saving-plan.routes';
import { OfflineHealthInsuranceRoutes } from './common/routes/offline-services/offline-health-insurance.routes';
import { OfflineHomeInsuranceRoutes } from './common/routes/offline-services/offline-home-insurance.routes';
import { OfflineInvestmentPlanRoutes } from './common/routes/offline-services/offline-investment-plan.routes';
import { OfflineLicPlanRoutes } from './common/routes/offline-services/offline-lic-plan.routes';
import { OfflinePickupInsuranceRoutes } from './common/routes/offline-services/offline-pickup-insurance.routes';
import { OfflineRetirementPlanRoutes } from './common/routes/offline-services/offline-retirement-plan.routes';
import { OfflineRickshawInsuranceRoutes } from './common/routes/offline-services/offline-rickshaw-insurance.routes';
import { OfflineSmartSavingPlanRoutes } from './common/routes/offline-services/offline-smart-saving-plan.routes';
import { OfflineTaxiInsuranceRoutes } from './common/routes/offline-services/offline-taxi-insurance.routes';
import { OfflineTermLifeInsuranceRoutes } from './common/routes/offline-services/offline-term-life-insurance.routes';
import { OfflineTractorInsuranceRoutes } from './common/routes/offline-services/offline-tractor-insurance.routes';
import { OfflineTravelInsuranceRoutes } from './common/routes/offline-services/offline-travel-insurance.routes';

import { OfflineMotorInsurancePolicyRoutes } from './common/routes/offline-policy/offline-motor-insurance-policy.routes';

import { OnlineTwoWheelerInsuranceRoutes } from './common/routes/online-services/online-two-wheeler-insurance.routes';
import { OnlineCarInsuranceRoutes } from './common/routes/online-services/online-car-insurance.routes';
import { OfflineGeneralLifeInsurancePolicyRoutes } from './common/routes/offline-policy/offline-general-life-insurance-policy.routes';

import { BlogRoutes } from './common/routes/blog/blog.routes';
import { PospBlogRoutes } from './common/routes/posp-blog/posp-blog.routes';

export const routes: Routes = [
    {
        path: ROUTES_PAGES.HOME,
        component: HomeComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'public-layout' },
        canActivate: [checkUsersGuard]
    },
    ...PagesRoutes,
    ...UsersRoutes,
    ...PospRoutes,
    ...PospKycRoutes,
    ...PospTrainingRoutes,
    ...PospExamRoutes,
    ...OfflineBikeInsuranceRoutes,
    ...OfflineBusInsuranceRoutes,
    ...OfflineBusinessInsuranceRoutes,
    ...OfflineCarInsuranceRoutes,
    ...OfflineChildSavingPlanRoutes,
    ...OfflineHealthInsuranceRoutes,
    ...OfflineHomeInsuranceRoutes,
    ...OfflineInvestmentPlanRoutes,
    ...OfflineLicPlanRoutes,
    ...OfflinePickupInsuranceRoutes,
    ...OfflineRetirementPlanRoutes,
    ...OfflineRickshawInsuranceRoutes,
    ...OfflineSmartSavingPlanRoutes,
    ...OfflineTaxiInsuranceRoutes,
    ...OfflineTermLifeInsuranceRoutes,
    ...OfflineTractorInsuranceRoutes,
    ...OfflineTravelInsuranceRoutes,
    ...OfflineMotorInsurancePolicyRoutes,
    ...OfflineGeneralLifeInsurancePolicyRoutes,
    ...OnlineTwoWheelerInsuranceRoutes,
    ...OnlineCarInsuranceRoutes,
    ...BlogRoutes,
    ...PospBlogRoutes,
    {
        path: '**',
        component: NotFoundComponent,
        canActivate: [checkUsersGuard]
    },
];
