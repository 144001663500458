<div class="main_container">
    <div class="container">
        <div class="row">
            <div class="bg-white order-detail p-3">
                <div class="row">
                    <div class="col-8">
                        <h1 class="fs-5 mb-3">{{ getOffLineServiceFullName(offlineServicesID.INVESTMENT_PLAN) }}</h1>
                    </div>
                    @if(isOrderEditable) {
                    <div class="col-4 text-end">
                        <a [routerLink]="getOffLineServiceOrderEditURL(offlineServicesID.INVESTMENT_PLAN, orderDetail.uuid)"
                            class="text-primary fw-semi" class="btn_edit">
                            <i class="bx bx-edit "></i> Edit
                        </a>
                    </div>
                    }
                </div>
                <div class="box_outer border rounded p-3">
                    <div class="row">
                        @if(objectLength(orderDetail) > 0) {
                        <div class="col-md-12 col-lg-12 col-xl-12">
                            <div class="bg-white shadow-sm border">
                                <div class="p-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="fw-bolder mb-2 text-success text-uppercase">{{
                                                getOffLineServiceFullName(offlineServicesID.INVESTMENT_PLAN) }}</p>
                                            <ul class="order-info mb-3">
                                                <li>
                                                    <div class="head">ORDER ID</div>
                                                    <div class="value value text-primary fw-semi">#{{
                                                        orderDetail?.order_id || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">ORDER STATUS</div>
                                                    <div class="value value text-primary fw-semi">{{
                                                        getStatusDisplay(orderDetail.status) }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">CREATED DATE</div>
                                                    <div class="value text-primary fw-semi">
                                                        @if(orderDetail?.created_at && orderDetail?.created_at != '') {
                                                        {{ getDBDateTimeShow(orderDetail?.created_at) }}
                                                        }
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="head">COMPLETED DATE</div>
                                                    <div class="value value text-primary fw-semi">
                                                        @if(orderDetail?.status && orderDetail?.status ==
                                                        globalConstant.status_match.completed) {
                                                        @if(orderDetail?.completed_at && orderDetail?.completed_at !=
                                                        '') {
                                                        {{ getDBDateTimeShow(orderDetail?.completed_at) }}
                                                        }
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="d-flex justify-content-between">
                                                <p class="fw-bolder mb-2 text-success">BASIC DETAILS</p>
                                            </div>
                                            <ul class="order-info">
                                                <li>
                                                    <div class="head">NAME</div>
                                                    <div class="value">{{ orderDetail?.full_name || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">MOBILE NO.</div>
                                                    <div class="value">{{ orderDetail?.phone || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">EMAIL ID.</div>
                                                    <div class="value">{{ orderDetail?.email || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">DATE OF BIRTH</div>
                                                    <div class="value">
                                                        @if(orderDetail?.date_of_birth && orderDetail?.date_of_birth !=
                                                        '') {
                                                        {{ getDBDateShow(orderDetail?.date_of_birth) }}
                                                        }
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="head">PAN NUMBER</div>
                                                    <div class="value">{{ orderDetail?.pan_card || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">NOMINEE NAME</div>
                                                    <div class="value">{{ orderDetail?.nominee_name || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">NOMINEE DATE OF BIRTH</div>
                                                    <div class="value">
                                                        @if(orderDetail?.nominee_dob && orderDetail?.nominee_dob != '')
                                                        {
                                                        {{ getDBDateShow(orderDetail?.nominee_dob) }}
                                                        }
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="head">NOMINEE RELATION</div>
                                                    <div class="value">{{
                                                        getNomineeDisplay(orderDetail?.nominee_relation) }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">Education</div>
                                                    <div class="value">{{
                                                        getEducationDetailDisplay(orderDetail?.education) }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">STATE</div>
                                                    <div class="value">{{ orderDetail?.state?.name || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">CITY</div>
                                                    <div class="value">{{ orderDetail?.city || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">PINCODE</div>
                                                    <div class="value">{{ orderDetail?.pincode || '' }}</div>
                                                </li>
                                                <li>
                                                    <div class="head">ADDRESS</div>
                                                    <div class="value">{{ orderDetail?.address || '' }}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <span class="cbd_info">Documents</span>
                            <hr class="customer_details">
                        </div>
                        <ul class="documents-privew-img">
                            @if(orderDetail?.pan_doc) {
                            <li>
                                <div class="fs-14 text-primary">PAN Card</div>
                                <div class="doc-img-privews">
                                    @if(isPdfOrImage(orderDetail?.pan_doc) == globalConstant.file_setting.type_pdf) {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.pan_doc || ''))"
                                        src="images/icons/pdf.png" [alt]="projectName"
                                        class="img-fluid custom-download-document" />
                                    } @else {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.pan_doc || ''))"
                                        src="{{ globalConstant.storage_base_url }}{{ orderDetail?.pan_doc || '' }}"
                                        [alt]="projectName" class="img-fluid custom-download-document" />
                                    }
                                </div>
                            </li>
                            }
                            @if(orderDetail?.aadhar_doc) {
                            <li>
                                <div class="fs-14 text-primary">Aadhar Card</div>
                                <div class="doc-img-privews">
                                    @if(isPdfOrImage(orderDetail?.aadhar_doc) == globalConstant.file_setting.type_pdf) {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.aadhar_doc || ''))"
                                        src="images/icons/pdf.png" [alt]="projectName"
                                        class="img-fluid custom-download-document" />
                                    } @else {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.aadhar_doc || ''))"
                                        src="{{ globalConstant.storage_base_url }}{{ orderDetail?.aadhar_doc || '' }}"
                                        [alt]="projectName" class="img-fluid custom-download-document" />
                                    }
                                </div>
                            </li>
                            }
                            @if(orderDetail?.other_doc1) {
                            <li>
                                <div class="fs-14 text-primary">Other Document 1</div>
                                <div class="doc-img-privews">
                                    @if(isPdfOrImage(orderDetail?.other_doc1) == globalConstant.file_setting.type_pdf) {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.other_doc1 || ''))"
                                        src="images/icons/pdf.png" [alt]="projectName"
                                        class="img-fluid custom-download-document" />
                                    } @else {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.other_doc1 || ''))"
                                        src="{{ globalConstant.storage_base_url }}{{ orderDetail?.other_doc1 || '' }}"
                                        [alt]="projectName" class="img-fluid custom-download-document" />
                                    }
                                </div>
                            </li>
                            }
                            @if(orderDetail?.other_doc2) {
                            <li>
                                <div class="fs-14 text-primary">Other Document 2</div>
                                <div class="doc-img-privews">
                                    @if(isPdfOrImage(orderDetail?.other_doc2) == globalConstant.file_setting.type_pdf) {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.other_doc2 || ''))"
                                        src="images/icons/pdf.png" [alt]="projectName"
                                        class="img-fluid custom-download-document" />
                                    } @else {
                                    <img (click)="downloadDocument(globalConstant.storage_base_url + (orderDetail?.other_doc2 || ''))"
                                        src="{{ globalConstant.storage_base_url }}{{ orderDetail?.other_doc2 || '' }}"
                                        [alt]="projectName" class="img-fluid custom-download-document" />
                                    }
                                </div>
                            </li>
                            }
                        </ul>
                        } @else {
                        <p class="order-detail-not-found">Order Details Not Found.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>