import { INSURER_ID } from "../constants/insurer/ids.constants";

export const PolicyTypeUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'own_damage', label: 'Only OD (Own Damage)' },
    { value: 'third_party', label: 'Only TP (Third Party)' },
    { value: 'package_policy', label: 'Package Policy (OD + TP)' }
  ],
  [INSURER_ID.GO_DIGIT_MOTOR]: [
    { value: 'own_damage', label: 'Only OD (Own Damage)' },
    { value: 'third_party', label: 'Only TP (Third Party)' },
    { value: 'package_policy', label: 'Package Policy (OD + TP)' }
  ]
};

export type PolicyTypeUtilityType = {
  [key: number]: { value: string; label: string }[];
};
