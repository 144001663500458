import { INSURER_ID } from "../constants/insurer/ids.constants";

export const NomineeRelationUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'mother', label: 'Mother' },
    { value: 'son', label: 'Son' },
    { value: 'wife', label: 'Wife' },
    { value: 'husband', label: 'Husband' },
    { value: 'sister', label: 'Sister' },
    { value: 'brother', label: 'Brother' },
    { value: 'father', label: 'Father' }
    /* daughter: 'Daughter' */
  ],
  [INSURER_ID.GO_DIGIT_MOTOR]: [
    { value: 'daughter', label: 'Daughter' },
    { value: 'daughter_in_law', label: 'Daughter In Law' },
    { value: 'grand_child', label: 'Grand Child' },
    { value: 'head', label: 'Head' },
    { value: 'mother', label: 'Mother' },
    { value: 'son', label: 'Son' },
    { value: 'wife', label: 'Spouse' },
    { value: 'husband', label: 'Husband' },
    { value: 'longtime_partner', label: 'Longtime Partner' },
    { value: 'child', label: 'Child' },
    { value: 'sister', label: 'Sister' },
    { value: 'brother', label: 'Brother' },
    { value: 'father', label: 'Father' },
    { value: 'other', label: 'Other' }
  ]
};

export type NomineeRelationUtilityType = {
  [key: number]: { value: string; label: string }[];
};
