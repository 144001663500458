import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../common/utilities/moment-utilities.service';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_BLOG } from '../../common/constants/routes/blog/blog.constants';
import { BlogWidgetComponent } from '../../common/layouts/blog/blog-widget/blog-widget.component';
import { BlogDetailService } from './blog-detail.service';

@Component({
  selector: 'app-blog-detail',
  standalone: true,
  imports: [
    RouterModule,
    BlogWidgetComponent
  ],
  templateUrl: './blog-detail.component.html'
})
export class BlogDetailComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesBlog = ROUTES_BLOG;

  projectName = this.globalConstant?.projectName;
  serverErrors: { [key: string]: string[] } = {};

  blogDetailData: any = {};
  slug: string = '';

  isLoading = false;

  private apiBlogDetailDataSubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private blogDetailService: BlogDetailService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.slug = params['slug'] || null;
      if (this.slug != null && this.slug != '') {
        this.apiGetBlogDetailData({ 'slug': this.slug });
      }
    });
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.DD_MMM_YYYY);
  }

  sanitizeHtml(html: string) {
    return this.utilitiesService.sanitizeHtml(html);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  apiGetBlogDetailData(params: object) {
    this.isLoading = true;
    this.apiBlogDetailDataSubscription = this.blogDetailService.getBlogDetailData(params).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.blogDetailData = response?.data && this.objectLength(response?.data) > 0 ? response?.data : {};
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiBlogDetailDataSubscription) {
      this.apiBlogDetailDataSubscription.unsubscribe();
    }
  }
}
