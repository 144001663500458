import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GLOBAL_CONSTANT } from '../../../constants/global-constant.constants';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MakeService } from './make.service';
import { UtilitiesService } from '../../../utilities/utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { LoaderService } from '../../../loader/loader.service';

@Component({
  selector: 'app-make',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
  templateUrl: './make.component.html'
})
export class MakeComponent implements OnInit, OnDestroy{
  globalConstant = GLOBAL_CONSTANT;
  projectName = this.globalConstant?.projectName;

  @Input() formGroup!: FormGroup;
  @Input() serverErrors: any = {};
  @Output() continue = new EventEmitter<void>();
  @Output() makeSelected = new EventEmitter<string>();
  @Output() previous = new EventEmitter<void>();

  selectedMakeName: string | null = null;
  getMakeList: any[] = [];
  isLoading = false;

  private apiGetMakeListSubscription: Subscription | undefined;

  constructor(
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private loaderService: LoaderService,
    private makeService: MakeService
  ) {
    this.formGroup = new FormGroup({
      make_name: new FormControl(''),
    });
   }

  ngOnInit(): void {
    this.apiGetMakeList();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  apiGetMakeList() {
    this.apiGetMakeListSubscription = this.makeService.getMakeList({
    }).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getMakeList = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.formGroup.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetMakeListSubscription) {
      this.apiGetMakeListSubscription.unsubscribe();
    }
  }

  onContinue(): void {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const selectedMakeName = this.formGroup.get('make_name')?.value;
      if (selectedMakeName) {
        const selectedMake = this.getMakeList.find(make => make.name === selectedMakeName);
        if (selectedMake) {
          this.makeSelected.emit(selectedMake.id);
        }
        this.continue.emit();
      }
      this.isLoading = false;
      this.loaderService.hide();
    }
  }

  onPrevious(): void {
    this.previous.emit();
  }

  onRadioSelect(makeName: string): void {
    this.formGroup.get('make_name')?.setValue(makeName);
    this.selectedMakeName = makeName;
    const selectedMake = this.getMakeList.find(make => make.name === makeName);
    if (selectedMake) {
      this.formGroup.addControl('make_id', new FormControl(selectedMake.id));
    }
  }

  isRadioChecked(makeName: string): boolean {
    return this.formGroup.get('make_name')?.value === makeName;
  }
}
