import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        service_step: new FormControl(GLOBAL_CONSTANT?.offline_personal_accident_cover_step_match?.step_1 || 'basic_details'),
        full_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        phone: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('^[0-9]*$')
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(100),
            Validators.email
        ])
    });
}
