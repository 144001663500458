import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GLOBAL_CONSTANT } from '../../../constants/global-constant.constants';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ModelService } from './model.service';
import { UtilitiesService } from '../../../utilities/utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { LoaderService } from '../../../loader/loader.service';

@Component({
  selector: 'app-model',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
  templateUrl: './model.component.html'
})
export class ModelComponent implements OnInit, OnDestroy{

  globalConstant = GLOBAL_CONSTANT;
  projectName = this.globalConstant?.projectName;

  @Input() formGroup!: FormGroup;
  @Input() serverErrors: any = {};
  @Input() makeId: string | null = null;
  @Output() continue = new EventEmitter<void>();
  @Output() modelSelected = new EventEmitter<string>();
  @Output() previous = new EventEmitter<void>();

  selectedModelName: string | null = null;
  getModelList: any[] = [];
  isLoading = false;

  private apiGetModelListSubscription: Subscription | undefined;

  constructor(
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private loaderService: LoaderService,
    private modelService: ModelService
  ) { }

  ngOnInit(): void {
    if (this.makeId != null) {
      this.apiGetModelList();
    }
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  apiGetModelList() {
    if (this.makeId != null && this.makeId != '') {
      let params = {
        'make_id': this.makeId
      };
      this.apiGetModelListSubscription = this.modelService.postData(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
              this.getModelList = response?.data;
            }
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.formGroup.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetModelListSubscription) {
      this.apiGetModelListSubscription.unsubscribe();
    }
  }

  onContinue(): void {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const selectedModelName = this.formGroup.get('model_name')?.value;
      if (selectedModelName) {
        const selectedModel = this.getModelList.find(model => model.name === selectedModelName);
        if (selectedModel) {
          this.modelSelected.emit(selectedModel.id);
        }
        this.continue.emit();
      }
      this.isLoading = false;
      this.loaderService.hide();
    }
  }

  onPrevious(): void {
    this.previous.emit();
  }

  onRadioSelect(modelName: string): void {
    this.formGroup.get('model_name')?.setValue(modelName);
    this.selectedModelName = modelName;
    const selectedModel = this.getModelList.find(model => model.name === modelName);
    if (selectedModel) {
      this.formGroup.addControl('model_id', new FormControl(selectedModel.id));
    }
  }

  isRadioChecked(modelName: string): boolean {
    return this.formGroup.get('model_name')?.value === modelName;
  }

}
