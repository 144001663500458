import { INSURER_ID } from "../constants/insurer/ids.constants";

export const PersonalHabitUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'smoking', label: 'Smoking' },
    { value: 'drinking', label: 'Drinking' },
    { value: 'pan_masala', label: 'Pan Masala' },
    { value: 'no_habbit', label: 'No any habbit' },
    { value: 'other', label: 'Other' },
  ]
};

export type PersonalHabitUtilityType = {
  [key: number]: { value: string; label: string }[];
};
