import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { filter, Observable, Subscription, take, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { GLOBAL_CONSTANT } from '../../constants/global-constant.constants';
import { ROUTES_PAGES } from '../../constants/routes/pages/pages.constants';
import { ROUTES_POSP } from '../../constants/routes/posp/posp.constants';
import { environment } from '../../../../environments/environment';
import { UtilitiesService } from '../../utilities/utilities.service';
import { MomentUtilitiesService } from '../../utilities/moment-utilities.service';
import { AuthUsersService } from '../../utilities/auth-users.service';
import { DownloadService } from '../../utilities/download.service';
import { LoaderService } from '../../loader/loader.service';

import { StoreUserProfileGuard, StoreStateUserProfileGuard } from '../../../store/reducers/user-profile-guard.reducers';
import { StoreUserProfile, StoreStateUserProfile } from '../../../store/reducers/user-profile.reducers';
import { selectStoreUserProfileGuard } from '../../../store/selectors/user-profile-guard.selectors';
import { selectStoreUserProfile } from '../../../store/selectors/user-profile.selectors';
import { storeSetUserProfile, storeResetUserProfile } from '../../../store/actions/user-profile.actions';
import { ROUTES_USERS } from '../../constants/routes/users/users.constants';
import { SidebarNavigationComponent } from '../sidebar-navigation/sidebar-navigation.component';

@Component({
  selector: 'app-sidebar-header',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    SidebarNavigationComponent
  ],
  templateUrl: './sidebar-header.component.html'
})
export class SidebarHeaderComponent implements OnInit {

  isProduction = environment.production;
  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;
  routesPosp = ROUTES_POSP;
  routesUsers = ROUTES_USERS;
  projectName = this.globalConstant?.projectName;

  profileData: any = {};
  getPospData: any = {};

  userProfileGuard$: Observable<StoreUserProfileGuard | null>;
  userProfile$: Observable<StoreUserProfile | null>;

  constructor(
    private store: Store<{ selectStoreUserProfileGuard: StoreStateUserProfileGuard, selectStoreUserProfile: StoreStateUserProfile }>,
    private router: Router,
    private authUsersService: AuthUsersService,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private downloadService: DownloadService,
    private loaderService: LoaderService
  ) {
    this.userProfileGuard$ = this.store.pipe(select(selectStoreUserProfileGuard));
    this.userProfile$ = this.store.pipe(select(selectStoreUserProfile));
  }

  private apiProfileDataSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.getProfile();
  }

  isProfileOpen = false;

  onProfileOpened() {
    this.isProfileOpen = true;
  }

  onProfileClosed() {
    this.isProfileOpen = false;
  }

  async logout(): Promise<void> {
    try {
      await this.authUsersService.logoutUsers();
      this.router.navigate([this.globalConstant.base_url]);
    } catch (error) {
      this.router.navigate([this.globalConstant.base_url]);
    }
  }

  getDBDateTimeShow(date: string): string {
    return this.momentUtilitiesService.getDBDateTimeShow(date);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  getProfile(): void {
    this.userProfile$.pipe(
      tap(userProfile => {
        this.loaderService.show();
        if (!userProfile) {
          this.userProfileGuard$.pipe(
            tap(userProfileGuard => {
              this.loaderService.show();
              if (userProfileGuard) {
                let user_type = userProfileGuard?.['user_type'] ?? '';
                if (user_type == this.globalConstant.user_type.posp) {
                  this.apiGetPospProfile();
                } else if (user_type == this.globalConstant.user_type.user) {
                  this.apiGetUserProfile();
                }
              }
              this.loaderService.hide();
            }),
            filter(userProfileGuard => !!userProfileGuard),
            take(1)
          ).subscribe();
        } else {
          this.profileData = userProfile;
        }
        this.loaderService.hide();
      }),
      filter(userProfile => !!userProfile),
      take(1)
    ).subscribe();
  }

  apiGetUserProfile() {
    this.apiProfileDataSubscription = this.authUsersService.getUserProfile().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            this.store.dispatch(storeSetUserProfile({ storeData: response?.data }));
          } else {
            this.store.dispatch(storeResetUserProfile());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  apiGetPospProfile() {
    this.apiProfileDataSubscription = this.authUsersService.getPospProfile().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            this.store.dispatch(storeSetUserProfile({ storeData: response?.data }));
          } else {
            this.store.dispatch(storeResetUserProfile());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(this.globalConstant?.storage_base_url + url);
  }

  ngOnDestroy(): void {
    if (this.apiProfileDataSubscription) {
      this.apiProfileDataSubscription.unsubscribe();
    }
  }

}
