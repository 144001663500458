<div class="vehicle-customer vehicleInfo" id="customerDiv">
    <p class="fw-semi text-primary mb-4">Fill your Personal details</p>
    <div [formGroup]="formGroup" class="form-group">
    <div class="row">
        <div class="col-md-12">
            <mat-label class="fs-14 mb-1 custom-label">Vehicle owner/ (वाहन का स्वामित्व) <span class="text-danger"> * </span></mat-label>
            <div class="form-group">
                <div class="form-check form-check-inline typeradio customradio w-50">
                    <input type="radio" id="individual" name="customerType" formControlName="customerType" value="INDIVIDUAL" class="form-check-input" checked>
                    <label for="individual" class="form-check-label">Individual</label>
                </div>
                <div class="form-check form-check-inline typeradio customradio w-50 pe-0">
                    <input type="radio" id="organization" name="customerType" formControlName="customerType" value="ORGANISATION" class="form-check-input">
                    <label for="organization" class="form-check-label">Organization</label>
                </div>
                <mat-error *ngIf="getErrorMessage('customerType')" class="custom-error">
                    {{ getErrorMessage('customerType') }}
                </mat-error>
            </div>
        </div>
        <div class="col-md-12 d-none">
            <mat-label class="fs-14 mb-1 custom-label">Organization / (संस्था नाम) <span class="text-danger"> * </span></mat-label>
            <div class="form-group mb-3">
                <input matInput name="organization_name" formControlName="organization_name" id="organization_name" placeholder="Organization Name" maxlength="200" type="text" class="form-control custom-input" />
                <mat-error *ngIf="getErrorMessage('organization_name')" class="custom-error">
                    {{ getErrorMessage('organization_name') }}
                </mat-error>
            </div>
        </div>
        <div class="col-md-12">
            <mat-label class="fs-14 mb-1 custom-label">Full Name / (पूरा नाम) <span class="text-danger"> * </span></mat-label>
            <div class="form-group mb-3">
                <input matInput name="full_name" formControlName="full_name" id="full_name" placeholder="First Name" maxlength="60" type="text" class="form-control custom-input" />
                <mat-error *ngIf="getErrorMessage('full_name')" class="custom-error">
                    {{ getErrorMessage('full_name') }}
                </mat-error>
            </div>
        </div>
    </div>
</div>
</div>