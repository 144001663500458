import { Injectable } from '@angular/core';
import { ApiService } from '../../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospBlogWidgetService {

  constructor(
    private apiService: ApiService
  ) { }

  getLatestPospBlog() {
    return this.apiService.request<any>('GET', 'posp-blog/latest');
  }

  getCategoryPospBlog() {
    return this.apiService.request<any>('GET', 'posp-blog/category');
  }
}
