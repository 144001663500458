import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT, STATUS_MAP, POLICY_TYPE_MAP, NOMINEE_MAP } from '../../../common/constants/global-constant.constants';
import { OFFLINE_SERVICES_ID } from '../../../common/constants/services/offline-services-url.constants';
import { ROUTES_PAGES } from '../../../common/constants/routes/pages/pages.constants';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { DownloadService } from '../../../common/utilities/download.service';
import { OfflineBikeInsuranceOrderDetailService } from './offline-bike-insurance-order-detail.service';

@Component({
  selector: 'app-offline-bike-insurance-order-detail',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './offline-bike-insurance-order-detail.component.html'
})
export class OfflineBikeInsuranceOrderDetailComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  offlineServicesID = OFFLINE_SERVICES_ID;
  routesPages = ROUTES_PAGES;
  statusMapConstant = STATUS_MAP;
  nomineeMapConstant = NOMINEE_MAP;
  policyTypeMapConstant = POLICY_TYPE_MAP;

  projectName = this.globalConstant?.projectName;

  uuId: string | null = null;
  isOrderEditable: boolean | null = false;
  orderDetail: any = {};

  private apiGetOrderDetailSubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private downloadService: DownloadService,
    private offlineBikeInsuranceOrderDetailService: OfflineBikeInsuranceOrderDetailService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    if (this.uuId != null && this.uuId != '') {
      this.apiOrderDetail({ 'uuid': this.uuId });
    }
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  getOffLineServiceShortName(id: number): string {
    return this.utilitiesService.getOffLineServiceShortName(id);
  }

  getOffLineServiceFullName(id: number): string {
    return this.utilitiesService.getOffLineServiceFullName(id);
  }

  getDBDateTimeShow(date: string): string {
    return this.momentUtilitiesService.getDBDateTimeShow(date);
  }

  getDBDateShow(date: string): string {
    return this.momentUtilitiesService.getDBDateShow(date);
  }

  getNomineeDisplay(nomineeRelation: string): string {
    return this.nomineeMapConstant[nomineeRelation] || nomineeRelation;
  }

  getStatusDisplay(status: string): string {
    return this.statusMapConstant[status] || status;
  }

  getPolicyTypeDisplay(policyType: string): string {
    return this.policyTypeMapConstant[policyType] || policyType;
  }

  isPdfOrImage(url: string): string {
    return this.utilitiesService.isPdfOrImage(url);
  }

  getOffLineServiceOrderEditURL(id: number, uuid: string = ''): string[] {
    return this.utilitiesService.getOffLineServiceOrderEditURL(id, uuid);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  apiOrderDetail(params: object) {
    this.apiGetOrderDetailSubscription = this.offlineBikeInsuranceOrderDetailService.getOrderDetail(params).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            this.orderDetail = response?.data;
            if (response?.data?.status) {
              this.isOrderEditable = this.utilitiesService.isOrderEditable(response?.data?.status);
            }
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiGetOrderDetailSubscription) {
      this.apiGetOrderDetailSubscription.unsubscribe();
    }
  }

}
