import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appNumericDecimalInput]',
  standalone: true,
})
export class NumericDecimalInputDirective {
  @Input() allowDecimal: boolean = false;
  @Input() maxIntegerDigits: number = 2;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;
    let sanitizedValue = this.allowDecimal
      ? inputValue.replace(/[^0-9.]/g, '')
      : inputValue.replace(/\D/g, '');
    if (this.allowDecimal) {
      const decimalCount = (sanitizedValue.match(/\./g) || []).length;
      if (decimalCount > 1) {
        const firstDecimalIndex = sanitizedValue.indexOf('.');
        sanitizedValue = sanitizedValue.slice(0, firstDecimalIndex + 1) + sanitizedValue.slice(firstDecimalIndex + 1).replace(/\./g, '');
      }
      const decimalIndex = sanitizedValue.indexOf('.');
      if (decimalIndex !== -1) {
        let integerPart = sanitizedValue.slice(0, decimalIndex);
        let decimalPart = sanitizedValue.slice(decimalIndex + 1);
        integerPart = integerPart.slice(0, this.maxIntegerDigits);
        decimalPart = decimalPart.slice(0, 2);
        sanitizedValue = `${integerPart}.${decimalPart}`;
      } else {
        sanitizedValue = sanitizedValue.slice(0, this.maxIntegerDigits);
      }
    } else {
      sanitizedValue = sanitizedValue.slice(0, this.maxIntegerDigits);
    }
    inputElement.value = sanitizedValue;
  }
}