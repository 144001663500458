import { Action, createReducer, on } from '@ngrx/store';
import { storeSetLatestBlog, storeResetLatestBlog } from '../../../actions/blog/blog/latest-blog.actions';

export interface StoreLatestBlog { [key: string]: any; }

export interface StoreStateLatestBlog {
  storeData: StoreLatestBlog | null;
}

export const initialState: StoreStateLatestBlog = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetLatestBlog, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetLatestBlog, state => ({ ...state, storeData: null }))
);

export function reducerLatestBlog(state: StoreStateLatestBlog | undefined, action: Action) { return _storeReducer(state, action); }