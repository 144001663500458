import { INSURER_ID } from "../constants/insurer/ids.constants";

export const GenderUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ],
  [INSURER_ID.GO_DIGIT_MOTOR]: [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
  ]
};

export type GenderUtilityType = {
  [key: number]: { value: string; label: string }[];
};
