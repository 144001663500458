<section class="sec insplan py-5 categroy">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="vehicleDetail">
                    <div class="cmnform onlinesvcform">
                          <form [formGroup]="customForm" autocomplete="off" enctype="multipart/form-data">
                            <app-rto *ngIf="currentStep === 'rto'" [formGroup]="customForm" [serverErrors]="serverErrors" (continue)="goToNextStep('make')"  (rtoCodeSelected)="updateValue($event)"></app-rto>

                            <app-make *ngIf="currentStep === 'make'" [formGroup]="customForm" [serverErrors]="serverErrors" (continue)="goToNextStep('model')" (makeSelected)="onMakeIdSelected($event)" (previous)="goToPreviousStep('rto')"></app-make>

                            <app-model *ngIf="currentStep === 'model'" [formGroup]="customForm" [serverErrors]="serverErrors" [makeId]="selectedMakeId" (continue)="goToNextStep('variant')" (modelSelected)="onModelIdSelected($event)" (previous)="goToPreviousStep('make')"></app-model>

                            <app-variant *ngIf="currentStep === 'variant'" [formGroup]="customForm" [serverErrors]="serverErrors" [makeId]="selectedMakeId" [modelId]="selectedModelId" (continue)="goToNextStep('year')" (variantSelected)="onVariantIdSelected($event)" (previous)="goToPreviousStep('model')"></app-variant>

                            <app-year *ngIf="currentStep === 'year'" [formGroup]="customForm" [serverErrors]="serverErrors" [makeId]="selectedMakeId" [modelId]="selectedModelId" [variantId]="selectedVariantId" (continue)="goToNextStep('customer')" (yearSelected)="updateValue($event)" (previous)="goToPreviousStep('variant')"></app-year>

                            <app-customer *ngIf="currentStep === 'customer'" [formGroup]="customForm" [serverErrors]="serverErrors" (previous)="goToPreviousStep('year')"></app-customer>
                          </form>
                    </div>
                </div>
            </div>
            <div class="col-md-3 offset-md-1">
                <div class="rounded-2 bg-white border shadow-sm border-success overflow-hidden">
                    <div class="vcard p-3 bg-light">
                        <div class="title">
                            <h6 class="mb-0 fs-5 fw-semi text-primary">
                                <span class="imgspace">
                                    <img src="images/plans/bike.svg" width="25" height="25" [alt]="projectName" />
                                </span>
                               Two Wheeler
                            </h6>
                        </div>
                    </div>
                    <div class="vehicle_detail px-3 pb-2">
                        <ul>
                            <li>
                                <div class="heading">RTO & City</div>
                                <div class="value">
                                    {{ selectedRtoCode ? selectedRtoCode : '' }}
                                </div>
                            </li>
                            <li>
                                <div class="heading">Manufacturer</div>
                                <div class="value">
                                    {{ selectedMakeName ? selectedMakeName : '' }}
                                </div>
                            </li>
                            <li>
                                <div class="heading">Model</div>
                                <div class="value">
                                    {{ selectedModelName ? selectedModelName : '' }}
                                </div>
                            </li>
                            <li>
                                <div class="heading">Variant</div>
                                <div class="value">
                                    {{ selectedVariantName ? selectedVariantName : '' }}
                                </div>
                            </li>
                            <li>
                                <div class="heading">Year</div>
                                <div class="value">
                                    {{ selectedYear ? selectedYear : '' }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>