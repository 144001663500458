import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { INSURER_CONSTANT, INSURER_ID_MAP } from '../../../common/constants/insurer/ids.constants';
import { ONLINE_SERVICES_ID, ONLINE_SERVICES_STEP } from '../../../common/constants/services/online-services-url.constants';
import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { StoreUtilitiesService } from '../../../common/utilities/store-utilities.service';
import { InsuranceTermsConditionsComponent } from '../../../common/layouts/insurance-terms-conditions/insurance-terms-conditions.component';
import { OnlineTwoWheelerInsuranceChoosePlanService } from './online-two-wheeler-insurance-choose-plan.service';

import { StoreTwoWheelerInsuranceBasicDetail, StoreStateTwoWheelerInsuranceBasicDetail } from '../../../store/reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.reducers';
import { selectStoreTwoWheelerInsuranceBasicDetail } from '../../../store/selectors/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.selectors';
import { storeSetTwoWheelerInsuranceBasicDetail, storeResetTwoWheelerInsuranceBasicDetail } from '../../../store/actions/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.actions';

import { StoreTwoWheelerInsuranceQuotesList, StoreStateTwoWheelerInsuranceQuotesList } from '../../../store/reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.reducers';
import { selectStoreTwoWheelerInsuranceQuotesList } from '../../../store/selectors/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.selectors';
import { storeSetTwoWheelerInsuranceQuotesList, storeResetTwoWheelerInsuranceQuotesList } from '../../../store/actions/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.actions';

@Component({
  selector: 'app-online-two-wheeler-insurance-choose-plan',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormFieldModule,
    InsuranceTermsConditionsComponent
  ],
  templateUrl: './online-two-wheeler-insurance-choose-plan.component.html'
})

export class OnlineTwoWheelerInsuranceChoosePlanComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  onLineServicesID = ONLINE_SERVICES_ID;
  insurerConstant = INSURER_CONSTANT;
  insurerIDMap = INSURER_ID_MAP;
  onLineServicesStep = ONLINE_SERVICES_STEP;

  projectName = this.globalConstant?.projectName;

  isLoading = false;
  serverErrors: { [key: string]: string[] } = {};

  uuId: string | null = null;
  vehicleDetail: any = {};
  quotesList: any = {};

  getStoreVehicleDetail$: Observable<StoreTwoWheelerInsuranceBasicDetail | null>;
  getStoreQuotesList$: Observable<StoreTwoWheelerInsuranceQuotesList | null>;

  private apiGetVehicleDetailSubscription: Subscription | undefined;
  private apiGetQuotesListSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{
      selectStoreTwoWheelerInsuranceBasicDetail: StoreStateTwoWheelerInsuranceBasicDetail,
      selectStoreTwoWheelerInsuranceQuotesList: StoreStateTwoWheelerInsuranceQuotesList
    }>,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private storeUtilitiesService: StoreUtilitiesService,
    private onlineTwoWheelerInsuranceChoosePlanService: OnlineTwoWheelerInsuranceChoosePlanService
  ) {
    this.getStoreVehicleDetail$ = this.store.pipe(select(selectStoreTwoWheelerInsuranceBasicDetail));
    this.getStoreQuotesList$ = this.store.pipe(select(selectStoreTwoWheelerInsuranceQuotesList));
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    this.loaderService.show();
    this.storeUtilitiesService.processStore(
      this.getStoreVehicleDetail$,
      () => this.apiGetVehicleDetailData(),
      (data) => this.setStoreVehicleDetailData(data)
    );

    this.storeUtilitiesService.processStore(
      this.getStoreQuotesList$,
      () => this.apiGetQuotesListData(),
      (data) => this.setStoreQuotesListData(data)
    );
    this.loaderService.hide();
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  showCurrency(currencySymbol: string, currency: number): string {
    return this.utilitiesService.showCurrency(currencySymbol, currency);
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.yearShow);
  }

  paymentButton(uuId: string, insurer_id: string) {
    let getRoute = this.utilitiesService.getOnLineServiceURL(this.onLineServicesID.TWO_WHEELER_INSURANCE, this.onLineServicesStep.STEP_3);
    getRoute = [...getRoute, uuId];
    getRoute = [...getRoute, insurer_id];
    this.router.navigate(getRoute);
  }

  async apiGetVehicleDetailData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'uuid': this.uuId
      };
      this.apiGetVehicleDetailSubscription = this.onlineTwoWheelerInsuranceChoosePlanService.getVehicleDetail(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetTwoWheelerInsuranceBasicDetail());
            this.store.dispatch(storeSetTwoWheelerInsuranceBasicDetail({ storeData: getData }));
            this.setStoreVehicleDetailData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  setStoreVehicleDetailData(data: StoreTwoWheelerInsuranceBasicDetail | null) {
    if (!data) return;
    this.vehicleDetail = data;
  }

  async apiGetQuotesListData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      /* let thisKeys = Object.keys(this.insurerIDMap);
      thisKeys.forEach(key => {
        console.log(`${key}: ${this.insurerIDMap[key]}`);
      }); */

      let params = {
        'quote_uuid': this.uuId,
        'insurer_id': this.insurerIDMap['GO_DIGIT_MOTOR'],
        'plan_type': this.insurerConstant?.plan_type?.comprehensive
      };

      this.apiGetQuotesListSubscription = this.onlineTwoWheelerInsuranceChoosePlanService.getQuotesList(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetTwoWheelerInsuranceQuotesList());
            this.store.dispatch(storeSetTwoWheelerInsuranceQuotesList({ storeData: getData }));
            this.setStoreQuotesListData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  setStoreQuotesListData(data: StoreTwoWheelerInsuranceBasicDetail | null) {
    if (!data) return;
    this.quotesList = data;
  }

  ngOnDestroy(): void {
    if (this.apiGetVehicleDetailSubscription) {
      this.apiGetVehicleDetailSubscription.unsubscribe();
    }
    if (this.apiGetQuotesListSubscription) {
      this.apiGetQuotesListSubscription.unsubscribe();
    }
  }
}
