import { INSURER_ID } from "../constants/insurer/ids.constants";

export const InsuranceStatusUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'active', label: 'Active' },
    { value: 'lapse', label: 'Lapse' },
  ]
};

export type InsuranceStatusUtilityType = {
  [key: number]: { value: string; label: string }[];
};
