import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_ONLINE_TWO_WHEELER_INSURANCE } from '../../constants/routes/online-services/online-two-wheeler-insurance.constants';

import { OnlineTwoWheelerInsuranceBasicDetailsComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-basic-details/online-two-wheeler-insurance-basic-details.component';
import { OnlineTwoWheelerInsuranceChoosePlanComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-choose-plan/online-two-wheeler-insurance-choose-plan.component';
import { OnlineTwoWheelerInsurancePersonalDetailsComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-personal-details/online-two-wheeler-insurance-personal-details.component';
import { OnlineTwoWheelerInsurancePaymentComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-payment/online-two-wheeler-insurance-payment.component';
import { OnlineTwoWheelerInsurancePaymentSuccessComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-payment-success/online-two-wheeler-insurance-payment-success.component';
import { OnlineTwoWheelerInsurancePaymentFailedComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-payment-failed/online-two-wheeler-insurance-payment-failed.component';
import { OnlineTwoWheelerInsuranceVehicleDetailsComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-vehicle-details/online-two-wheeler-insurance-vehicle-details.component';

export const OnlineTwoWheelerInsuranceRoutes: Routes = [
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.BASIC_DETAILS,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.CHOOSE_PLAN,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.CHOOSE_PLAN + '/:uuid',
        component: OnlineTwoWheelerInsuranceChoosePlanComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PERSONAL_DETAILS,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PERSONAL_DETAILS + '/:uuid',
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PERSONAL_DETAILS + '/:uuid/:insurer_id',
        component: OnlineTwoWheelerInsurancePersonalDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT + '/:uuid',
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT + '/:uuid/:insurer_id',
        component: OnlineTwoWheelerInsurancePaymentComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT_SUCCESS,
        component: OnlineTwoWheelerInsurancePaymentSuccessComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT_FAILED,
        component: OnlineTwoWheelerInsurancePaymentFailedComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },

    /* buying a new bike start */

    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.VEHICLE_DETAILS,
        component: OnlineTwoWheelerInsuranceVehicleDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },

    /* buying a new bike end */
];