import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlEvent, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ONLINE_SERVICES_ID, ONLINE_SERVICES_STEP } from '../../../common/constants/services/online-services-url.constants';

import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { StoreUtilitiesService } from '../../../common/utilities/store-utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { CommonUtilityService } from '../../../common/utility/common-utility.service';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';

import { getFormValidators } from './form-utils';

import { InsuranceTermsConditionsComponent } from '../../../common/layouts/insurance-terms-conditions/insurance-terms-conditions.component';
import { OnlineTwoWheelerInsurancePersonalDetailsService } from './online-two-wheeler-insurance-personal-details.service';

import { StoreTwoWheelerInsuranceBasicDetail, StoreStateTwoWheelerInsuranceBasicDetail } from '../../../store/reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.reducers';
import { selectStoreTwoWheelerInsuranceBasicDetail } from '../../../store/selectors/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.selectors';
import { storeSetTwoWheelerInsuranceBasicDetail, storeResetTwoWheelerInsuranceBasicDetail } from '../../../store/actions/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.actions';
import { NumericInputDirective } from '../../../common/directives/numeric-input.directive';
import { INSURER_CONSTANT, INSURER_ID_MAP } from '../../../common/constants/insurer/ids.constants';
import { StoreTwoWheelerInsuranceQuotesList, StoreStateTwoWheelerInsuranceQuotesList } from '../../../store/reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.reducers';
import { selectStoreTwoWheelerInsuranceQuotesList } from '../../../store/selectors/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.selectors';
import { storeSetTwoWheelerInsuranceQuotesList, storeResetTwoWheelerInsuranceQuotesList } from '../../../store/actions/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.actions';

@Component({
  selector: 'app-online-two-wheeler-insurance-personal-details',
  standalone: true,
  imports: [
    NgIf,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    InsuranceTermsConditionsComponent,
    NumericInputDirective
  ],
  templateUrl: './online-two-wheeler-insurance-personal-details.component.html'
})
export class OnlineTwoWheelerInsurancePersonalDetailsComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  onLineServicesID = ONLINE_SERVICES_ID;
  onLineServicesStep = ONLINE_SERVICES_STEP;
  insurerConstant = INSURER_CONSTANT;
  insurerIDMap = INSURER_ID_MAP;
  onlineInsurerConstant = null;


  projectName = this.globalConstant?.projectName;

  isLoading = false;
  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  uuId: string | null = null;
  insurerId: string | null = null;
  vehicleDetail: any = {};

  getTitle: any[] = [];
  getGender: any[] = [];
  getNomineeRelation: any[] = [];
  getDocumentType: any[] = [];
  getPolicyHolderType: any[] = [];
  getPolicyStatus: any[] = [];
  getPolicyType: any[] = [];

  getState: any[] = [];
  getPreviousInsurer: any[] = [];

  selectInsurerData: any = {};

  getStoreVehicleDetail$: Observable<StoreTwoWheelerInsuranceBasicDetail | null>;
  getStoreQuotesList$: Observable<StoreTwoWheelerInsuranceQuotesList | null>;

  private apiGetVehicleDetailSubscription: Subscription | undefined;
  private apiGetSelectInsurerSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;
  private apiGetStateSubscription: Subscription | undefined;
  private apiGetPreviousInsurerSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{
      selectStoreTwoWheelerInsuranceBasicDetail: StoreStateTwoWheelerInsuranceBasicDetail,
      selectStoreTwoWheelerInsuranceQuotesList: StoreStateTwoWheelerInsuranceQuotesList
    }>,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private commonUtilityService: CommonUtilityService,
    private momentUtilitiesService: MomentUtilitiesService,
    private storeUtilitiesService: StoreUtilitiesService,
    private onlineTwoWheelerInsurancePersonalDetailsService: OnlineTwoWheelerInsurancePersonalDetailsService
  ) {
    this.customForm = getFormValidators();
    this.getStoreVehicleDetail$ = this.store.pipe(select(selectStoreTwoWheelerInsuranceBasicDetail));
    this.getStoreQuotesList$ = this.store.pipe(select(selectStoreTwoWheelerInsuranceQuotesList));
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
      this.insurerId = params['insurer_id'] || null;
    });

    this.loaderService.show();
    this.storeUtilitiesService.processStore(
      this.getStoreVehicleDetail$,
      () => this.apiGetVehicleDetailData(),
      (data) => this.setStoreVehicleDetailData(data)
    );
    this.storeUtilitiesService.processStore(
      this.getStoreQuotesList$,
      () => this.apiGetSelectInsurerData(),
      (data) => this.setStoreQuotesListData(data)
    );

    if (this.uuId != null && this.utilitiesService.checkUUID(this.uuId) && this.insurerId != null && this.utilitiesService.checkInsurerID(this.insurerId)) {
      this.customForm.patchValue({
        quote_uuid: this.uuId,
        insurer_id: this.insurerId
      });

      this.getTitleData();
      this.getGenderData();
      this.getNomineeRelationData();
      this.getDocumentTypeData();
      this.getPolicyHolderTypeData();
      this.getPolicyStatusData();
      this.getPolicyTypeData();

      this.apiGetState();
      this.apiGetPreviousInsurerData();
    }
    this.loaderService.hide();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  showCurrency(currencySymbol: string, currency: number): string {
    return this.utilitiesService.showCurrency(currencySymbol, currency);
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.yearShow);
  }

  @ViewChild('date_of_birth', { static: false }) date_of_birth_datepicker!: MatDatepicker<Date>;
  @ViewChild('registration_date', { static: false }) registration_date_datepicker!: MatDatepicker<Date>;
  @ViewChild('nominee_dob', { static: false }) nominee_dob_datepicker!: MatDatepicker<Date>;

  maxDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.minDobYear);
  minDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);
  maxRegistrationDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
  minRegistrationDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);
  maxNomineeDobDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
  minNomineeDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);

  openDobDatepicker() {
    this.date_of_birth_datepicker.open();
  }

  openRegistrationDateDatepicker() {
    this.registration_date_datepicker.open();
  }

  openNomineeDobDatepicker() {
    this.nominee_dob_datepicker.open();
  }

  datePickerInput(event: KeyboardEvent): void {
    this.utilitiesService.datePickerInput(event);
  }

  getTitleData() {
    let getTitleUtility = this.commonUtilityService.getUtility('title_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getTitleUtility && this.utilitiesService.arrayLength(getTitleUtility) > 0) {
      this.getTitle = getTitleUtility;
    }
  }

  getGenderData() {
    let getGenderUtility = this.commonUtilityService.getUtility('gender_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getGenderUtility && this.utilitiesService.arrayLength(getGenderUtility) > 0) {
      this.getGender = getGenderUtility;
    }
  }

  getNomineeRelationData() {
    let getNomineeRelationUtility = this.commonUtilityService.getUtility('nominee_relation_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getNomineeRelationUtility && this.utilitiesService.arrayLength(getNomineeRelationUtility) > 0) {
      this.getNomineeRelation = getNomineeRelationUtility;
    }
  }

  getDocumentTypeData() {
    let getDocumentTypeUtility = this.commonUtilityService.getUtility('document_type_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getDocumentTypeUtility && this.utilitiesService.arrayLength(getDocumentTypeUtility) > 0) {
      this.getDocumentType = getDocumentTypeUtility;
    }
  }

  getPolicyHolderTypeData() {
    let getPolicyHolderTypeUtility = this.commonUtilityService.getUtility('policy_holder_type_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getPolicyHolderTypeUtility && this.utilitiesService.arrayLength(getPolicyHolderTypeUtility) > 0) {
      this.getPolicyHolderType = getPolicyHolderTypeUtility;
    }
  }

  getPolicyStatusData() {
    let getPolicyStatusUtility = this.commonUtilityService.getUtility('policy_status_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getPolicyStatusUtility && this.utilitiesService.arrayLength(getPolicyStatusUtility) > 0) {
      this.getPolicyStatus = getPolicyStatusUtility;
    }
  }

  getPolicyTypeData() {
    let getPolicyTypeUtility = this.commonUtilityService.getUtility('policy_type_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getPolicyTypeUtility && this.utilitiesService.arrayLength(getPolicyTypeUtility) > 0) {
      this.getPolicyType = getPolicyTypeUtility;
    }
  }

  async apiGetState(): Promise<void> {
    this.apiGetStateSubscription = this.onlineTwoWheelerInsurancePersonalDetailsService.getState({
      'insurer_id': this.utilitiesService.insurerID(this.insurerId)
    }).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getState = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  async apiGetPreviousInsurerData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'insurer_id': this.utilitiesService.insurerID(this.insurerId),
      };
      this.apiGetPreviousInsurerSubscription = this.onlineTwoWheelerInsurancePersonalDetailsService.getPreviousInsurer(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
              this.getPreviousInsurer = response?.data;
            }
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  async apiGetVehicleDetailData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'uuid': this.uuId
      };
      this.apiGetVehicleDetailSubscription = this.onlineTwoWheelerInsurancePersonalDetailsService.getVehicleDetail(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetTwoWheelerInsuranceBasicDetail());
            this.store.dispatch(storeSetTwoWheelerInsuranceBasicDetail({ storeData: getData }));
            this.setStoreVehicleDetailData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  setStoreVehicleDetailData(data: StoreTwoWheelerInsuranceBasicDetail | null) {
    if (!data) return;
    this.vehicleDetail = data;
    this.customForm.patchValue({
      title: this.vehicleDetail?.title || '',
      full_name: this.vehicleDetail?.full_name || '',
      first_name: this.vehicleDetail?.first_name || '',
      middle_name: this.vehicleDetail?.middle_name || '',
      last_name: this.vehicleDetail?.last_name || '',
      gender: this.vehicleDetail?.gender || '',
      date_of_birth: this.vehicleDetail?.date_of_birth || '',
      phone: this.vehicleDetail?.phone || '',
      email: this.vehicleDetail?.email || '',
      address_one: this.vehicleDetail?.address_one || '',
      address_two: this.vehicleDetail?.address_two || '',
      address_three: this.vehicleDetail?.address_three || '',
      state_code: this.vehicleDetail?.state_code || '',
      city: this.vehicleDetail?.city || '',
      pincode: this.vehicleDetail?.pincode || '',
      gstin_number: this.vehicleDetail?.gstin_number || '',
      kyc_reference_doc_id: this.vehicleDetail?.kyc_reference_doc_id || '',
      kyc_reference_number: this.vehicleDetail?.kyc_reference_number || '',
      vehicle_number: this.vehicleDetail?.vehicle_number || '',
      engine_number: this.vehicleDetail?.engine_number || '',
      chassis_number: this.vehicleDetail?.chassis_number || '',
      registration_date: this.vehicleDetail?.registration_date || '',
      person_type: this.vehicleDetail?.person_type || this.globalConstant.default_key.policy_holder_type,
      is_hypothecation: this.vehicleDetail?.is_previous_insurance_remember || '0',
      nominee_first_name: this.vehicleDetail?.nominee_first_name || '',
      nominee_middle_name: this.vehicleDetail?.nominee_middle_name || '',
      nominee_last_name: this.vehicleDetail?.nominee_last_name || '',
      nominee_relation: this.vehicleDetail?.nominee_relation || '',
      nominee_dob: this.vehicleDetail?.nominee_dob || '',
      is_previous_insurance_remember: this.vehicleDetail?.is_previous_insurance_remember || '0'
    });

    if (this.vehicleDetail.person_type === this.globalConstant.match.policy_holder_type_organization) {
      this.customForm.patchValue({
        organization_name: this.vehicleDetail?.organization_name || ''
      });
    }

    if (this.vehicleDetail.is_hypothecation == '1') {
      this.customForm.patchValue({
        financer_name: this.vehicleDetail?.financer_name || '',
        financer_city: this.vehicleDetail?.financer_city || ''
      });
    }

    if (this.vehicleDetail.is_previous_insurance_remember == '1') {
      this.customForm.patchValue({
        previous_insurance_status: this.vehicleDetail?.previous_insurance_status || '',
        previous_insurance_type: this.vehicleDetail?.previous_insurance_type || '',
        previous_insurance_company: this.vehicleDetail?.previous_insurance_company || ''
      });
    }
  }

  setStoreQuotesListData(data: StoreTwoWheelerInsuranceBasicDetail | null) {
    if (!data) return;
    this.selectInsurerData = data;
  }

  async apiGetSelectInsurerData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'quote_uuid': this.uuId,
        'insurer_id': this.insurerIDMap['GO_DIGIT_MOTOR'],
        'plan_type': this.insurerConstant?.plan_type?.comprehensive
      };

      this.apiGetSelectInsurerSubscription = this.onlineTwoWheelerInsurancePersonalDetailsService.getSelectInsurerDetail(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetTwoWheelerInsuranceQuotesList());
            this.store.dispatch(storeSetTwoWheelerInsuranceQuotesList({ storeData: getData }));
            this.setStoreQuotesListData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();

      let date_of_birth = '';
      let registration_date = '';
      let nominee_dob = '';

      if (this.customForm.value.date_of_birth && this.customForm.value.date_of_birth != '') {
        date_of_birth = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.date_of_birth);
      }
      if (this.customForm.value.registration_date && this.customForm.value.registration_date != '') {
        registration_date = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.registration_date);
      }
      if (this.customForm.value.nominee_dob && this.customForm.value.nominee_dob != '') {
        nominee_dob = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.nominee_dob);
      }

      const formData: any = {
        "quote_uuid": this.customForm?.value?.quote_uuid,
        "insurer_id": this.customForm?.value?.insurer_id,
        "title": this.customForm?.value?.title,
        "first_name": this.customForm?.value?.first_name,
        "middle_name": this.customForm?.value?.middle_name,
        "last_name": this.customForm?.value?.last_name,
        "gender": this.customForm?.value?.gender,
        "date_of_birth": date_of_birth,
        "phone": this.customForm?.value?.phone,
        "email": this.customForm?.value?.email,
        "address_one": this.customForm?.value?.address_one,
        "address_two": this.customForm?.value?.address_two,
        "address_three": this.customForm?.value?.address_three,
        "state_code": this.customForm?.value?.state_code,
        "city": this.customForm?.value?.city,
        "pincode": this.customForm?.value?.pincode,
        "gstin_number": this.customForm?.value?.gstin_number,
        "kyc_reference_doc_id": this.customForm?.value?.kyc_reference_doc_id,
        "kyc_reference_number": this.customForm?.value?.kyc_reference_number,
        "vehicle_number": this.customForm?.value?.vehicle_number,
        "engine_number": this.customForm?.value?.engine_number,
        "chassis_number": this.customForm?.value?.chassis_number,
        "registration_date": registration_date,
        "person_type": this.customForm?.value?.person_type,
        "is_hypothecation": this.customForm?.value?.is_hypothecation,
        "nominee_first_name": this.customForm?.value?.nominee_first_name,
        "nominee_middle_name": this.customForm?.value?.nominee_middle_name,
        "nominee_last_name": this.customForm?.value?.nominee_last_name,
        "nominee_dob": nominee_dob,
        "nominee_relation": this.customForm?.value?.nominee_relation,
        "is_previous_insurance_remember": this.customForm?.value?.is_previous_insurance_remember,
      };

      if (this.customForm?.value?.person_type === this.globalConstant.match.policy_holder_type_organization) {
        formData.organization_name = this.customForm?.value?.organization_name;
      }

      if (this.customForm?.value?.is_hypothecation == '1') {
        formData.financer_name = this.customForm?.value?.financer_name;
        formData.financer_city = this.customForm?.value?.financer_city;
      }

      if (this.customForm?.value?.is_previous_insurance_remember == '1') {
        formData.previous_insurance_status = this.customForm?.value?.previous_insurance_status;
        formData.previous_insurance_type = this.customForm?.value?.previous_insurance_type;
        formData.previous_insurance_company = this.customForm?.value?.previous_insurance_company;
      }

      this.apiSubmitFormDataSubscription = this.onlineTwoWheelerInsurancePersonalDetailsService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            let getResponse = response?.data;
            let getUuid = response?.data?.uuid;
            if (getUuid != '') {
              this.store.dispatch(storeSetTwoWheelerInsuranceBasicDetail({ storeData: getResponse }));
              let getRoute = this.utilitiesService.getOnLineServiceURL(this.onLineServicesID.TWO_WHEELER_INSURANCE, this.onLineServicesStep.STEP_4);
              getRoute = [...getRoute, getUuid, this.utilitiesService.insurerID(this.insurerId)];
              this.router.navigate(getRoute);
            }
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetVehicleDetailSubscription) {
      this.apiGetVehicleDetailSubscription.unsubscribe();
    }
    if (this.apiGetSelectInsurerSubscription) {
      this.apiGetSelectInsurerSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
    if (this.apiGetStateSubscription) {
      this.apiGetStateSubscription.unsubscribe();
    }
    if (this.apiGetPreviousInsurerSubscription) {
      this.apiGetPreviousInsurerSubscription.unsubscribe();
    }
  }
}