import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_GENERAL_LIFE_INSURANCE_POLICY } from '../../constants/routes/offline-policy/offline-general-life-insurance-policy.constants';
import { OfflineGeneralLifeInsurancePolicyComponent } from '../../../offline-policy/offline-general-life-insurance-policy/offline-general-life-insurance-policy.component';

export const OfflineGeneralLifeInsurancePolicyRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_GENERAL_LIFE_INSURANCE_POLICY.INDEX,
        component: OfflineGeneralLifeInsurancePolicyComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_GENERAL_LIFE_INSURANCE_POLICY.INDEX + '/:page_name',
        component: OfflineGeneralLifeInsurancePolicyComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];