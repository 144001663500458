import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GLOBAL_CONSTANT } from '../../../constants/global-constant.constants';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule, FormControl } from '@angular/forms';
import { UtilitiesService } from '../../../utilities/utilities.service';
import { FormUtilitiesService } from '../../../utilities/form-utilities.service';
import { LoaderService } from '../../../loader/loader.service';
import { VariantService } from './variant.service';

@Component({
  selector: 'app-variant',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
  templateUrl: './variant.component.html'
})
export class VariantComponent implements OnInit, OnDestroy {

  globalConstant = GLOBAL_CONSTANT;
  projectName = this.globalConstant?.projectName;

  @Input() formGroup!: FormGroup;
  @Input() serverErrors: any = {};
  @Output() continue = new EventEmitter<void>();
  @Input() makeId: string | null = null;
  @Input() modelId: string | null = null;
  @Output() variantSelected = new EventEmitter<string>();
  @Output() previous = new EventEmitter<void>();

  selectedVariantName: string | null = null;
  getVariantList: any[] = [];
  isLoading = false;

  private apiGetVarientListSubscription: Subscription | undefined;

  constructor(
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private loaderService: LoaderService,
    private variantService: VariantService
  ) { }

  ngOnInit(): void {
    if (this.makeId != null && this.modelId) {
      this.apiGetVarientList();
    }
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  apiGetVarientList() {
    if (this.makeId != null && this.makeId != '' && this.modelId != null && this.modelId != '') {
      let params = {
        'make_id': this.makeId,
        'model_id': this.modelId
      };
      this.apiGetVarientListSubscription = this.variantService.postData(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
              this.getVariantList = response?.data;
            }
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.formGroup.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetVarientListSubscription) {
      this.apiGetVarientListSubscription.unsubscribe();
    }
  }

  onContinue(): void {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const selectedVariantName = this.formGroup.get('variant_name')?.value;
      if (selectedVariantName) {
        const selectedModel = this.getVariantList.find(variant => variant.name === selectedVariantName);
        if (selectedModel) {
          this.variantSelected.emit(selectedModel.id);
        }
        this.continue.emit();
      }
      this.isLoading = false;
      this.loaderService.hide();
    }
  }

  onPrevious(): void {
    this.previous.emit();
  }

  onRadioSelect(variantName: string): void {
    this.formGroup.get('variant_name')?.setValue(variantName);
    this.selectedVariantName = variantName;
    const selectedVariant = this.getVariantList.find(variant => variant.name === variantName);
    if (selectedVariant) {
      this.formGroup.addControl('vairant_id', new FormControl(selectedVariant.id));
    }
  }

  isRadioChecked(variantName: string): boolean {
    return this.formGroup.get('variant_name')?.value === variantName;
  }

}
