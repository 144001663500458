import { Routes } from '@angular/router';
import { checkUsersGuard } from './common/guards/check-users.guard';
import { ROUTES_PAGES } from './common/constants/routes/pages/pages.constants';
import { ROUTES_OFFLINE_BIKE_INSURANCE } from './common/constants/routes/offline-services/offline-bike-insurance.constants';
import { ROUTES_OFFLINE_CAR_INSURANCE } from './common/constants/routes/offline-services/offline-car-insurance.constants';
import { ROUTES_OFFLINE_RICKSHAW_INSURANCE } from './common/constants/routes/offline-services/offline-rickshaw-insurance.constants';
import { ROUTES_OFFLINE_TAXI_INSURANCE } from './common/constants/routes/offline-services/offline-taxi-insurance.constants';
import { ROUTES_OFFLINE_TRACTOR_INSURANCE } from './common/constants/routes/offline-services/offline-tractor-insurance.constants';
import { ROUTES_OFFLINE_BUS_INSURANCE } from './common/constants/routes/offline-services/offline-bus-insurance.constants';
import { ROUTES_OFFLINE_PICKUP_INSURANCE } from './common/constants/routes/offline-services/offline-pickup-insurance.constants';
import { ROUTES_OFFLINE_HEALTH_INSURANCE } from './common/constants/routes/offline-services/offline-health-insurance.constants';
import { ROUTES_OFFLINE_TERM_LIFE_INSURANCE } from './common/constants/routes/offline-services/offline-term-life-insurance.constants';
import { ROUTES_OFFLINE_CHILD_SAVING_PLAN } from './common/constants/routes/offline-services/offline-child-saving-plan.constants';
import { ROUTES_OFFLINE_INVESTMENT_PLAN } from './common/constants/routes/offline-services/offline-investment-plan.constants';
import { ROUTES_OFFLINE_RETIREMENT_PLAN } from './common/constants/routes/offline-services/offline-retirement-plan.constants';
import { ROUTES_OFFLINE_SMART_SAVING_PLAN } from './common/constants/routes/offline-services/offline-smart-saving-plan.constants';
import { ROUTES_OFFLINE_BUSINESS_INSURANCE } from './common/constants/routes/offline-services/offline-business-insurance.constants';
import { ROUTES_OFFLINE_TRAVEL_INSURANCE } from './common/constants/routes/offline-services/offline-travel-insurance.constants';
import { ROUTES_OFFLINE_HOME_INSURANCE } from './common/constants/routes/offline-services/offline-home-insurance.constants';
import { ROUTES_OFFLINE_LIC_PLAN } from './common/constants/routes/offline-services/offline-lic-plan.constants';
import { ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER } from './common/constants/routes/offline-services/offline-persoanl-accident-cover.constants';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagesRoutes } from './common/routes/pages/pages.routes';
import { UsersRoutes } from './common/routes/users/users.routes';
import { PospRoutes } from './common/routes/posp/posp.routes';
import { PospKycRoutes } from './common/routes/posp/posp-kyc.routes';
import { PospTrainingRoutes } from './common/routes/posp/posp-training.routes';
import { PospExamRoutes } from './common/routes/posp/posp-exam.routes';
import { OfflineBikeInsuranceRoutes } from './common/routes/offline-services/offline-bike-insurance.routes';
import { OfflineBusInsuranceRoutes } from './common/routes/offline-services/offline-bus-insurance.routes';
import { OfflineBusinessInsuranceRoutes } from './common/routes/offline-services/offline-business-insurance.routes';
import { OfflineCarInsuranceRoutes } from './common/routes/offline-services/offline-car-insurance.routes';
import { OfflineChildSavingPlanRoutes } from './common/routes/offline-services/offline-child-saving-plan.routes';
import { OfflineHealthInsuranceRoutes } from './common/routes/offline-services/offline-health-insurance.routes';
import { OfflineHomeInsuranceRoutes } from './common/routes/offline-services/offline-home-insurance.routes';
import { OfflineInvestmentPlanRoutes } from './common/routes/offline-services/offline-investment-plan.routes';
import { OfflineLicPlanRoutes } from './common/routes/offline-services/offline-lic-plan.routes';
import { OfflinePickupInsuranceRoutes } from './common/routes/offline-services/offline-pickup-insurance.routes';
import { OfflineRetirementPlanRoutes } from './common/routes/offline-services/offline-retirement-plan.routes';
import { OfflineRickshawInsuranceRoutes } from './common/routes/offline-services/offline-rickshaw-insurance.routes';
import { OfflineSmartSavingPlanRoutes } from './common/routes/offline-services/offline-smart-saving-plan.routes';
import { OfflineTaxiInsuranceRoutes } from './common/routes/offline-services/offline-taxi-insurance.routes';
import { OfflineTermLifeInsuranceRoutes } from './common/routes/offline-services/offline-term-life-insurance.routes';
import { OfflineTractorInsuranceRoutes } from './common/routes/offline-services/offline-tractor-insurance.routes';
import { OfflineTravelInsuranceRoutes } from './common/routes/offline-services/offline-travel-insurance.routes';

import { OfflineMotorInsurancePolicyRoutes } from './common/routes/offline-policy/offline-motor-insurance-policy.routes';

import { OnlineTwoWheelerInsuranceRoutes } from './common/routes/online-services/online-two-wheeler-insurance.routes';
import { OnlineCarInsuranceRoutes } from './common/routes/online-services/online-car-insurance.routes';
import { OfflineGeneralLifeInsurancePolicyRoutes } from './common/routes/offline-policy/offline-general-life-insurance-policy.routes';

import { BlogRoutes } from './common/routes/blog/blog.routes';
import { PospBlogRoutes } from './common/routes/posp-blog/posp-blog.routes';

import { OfflinePersonalAccidentCoverRoutes } from './common/routes/offline-services/offline-personal-accident-cover.routes';

export const routes: Routes = [
    {
        path: ROUTES_PAGES.HOME,
        component: HomeComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'public-layout' },
        canActivate: [checkUsersGuard]
    },
    {
        path: 'bike',
        redirectTo: ROUTES_OFFLINE_BIKE_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'car',
        redirectTo: ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'rickshaw',
        redirectTo: ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'taxi',
        redirectTo: ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'tractor',
        redirectTo: ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'bus',
        redirectTo: ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'pickup',
        redirectTo: ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'health',
        redirectTo: ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'term',
        redirectTo: ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'child',
        redirectTo: ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_STEP_ONE
    },
    {
        path: 'investment',
        redirectTo: ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_STEP_ONE
    },
    {
        path: 'retirement',
        redirectTo: ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_STEP_ONE
    },
    {
        path: 'smart',
        redirectTo: ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_STEP_ONE
    },
    {
        path: 'business',
        redirectTo: ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'travel',
        redirectTo: ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'home',
        redirectTo: ROUTES_OFFLINE_HOME_INSURANCE.OFFLINE_STEP_ONE
    },
    {
        path: 'lic',
        redirectTo: ROUTES_OFFLINE_LIC_PLAN.OFFLINE_STEP_ONE
    },
    {
        path: 'pa',
        redirectTo: ROUTES_OFFLINE_PERSONAL_ACCIDENT_COVER.OFFLINE_STEP_ONE
    },
    ...PagesRoutes,
    ...UsersRoutes,
    ...PospRoutes,
    ...PospKycRoutes,
    ...PospTrainingRoutes,
    ...PospExamRoutes,
    ...OfflineBikeInsuranceRoutes,
    ...OfflineBusInsuranceRoutes,
    ...OfflineBusinessInsuranceRoutes,
    ...OfflineCarInsuranceRoutes,
    ...OfflineChildSavingPlanRoutes,
    ...OfflinePersonalAccidentCoverRoutes,
    ...OfflineHealthInsuranceRoutes,
    ...OfflineHomeInsuranceRoutes,
    ...OfflineInvestmentPlanRoutes,
    ...OfflineLicPlanRoutes,
    ...OfflinePickupInsuranceRoutes,
    ...OfflineRetirementPlanRoutes,
    ...OfflineRickshawInsuranceRoutes,
    ...OfflineSmartSavingPlanRoutes,
    ...OfflineTaxiInsuranceRoutes,
    ...OfflineTermLifeInsuranceRoutes,
    ...OfflineTractorInsuranceRoutes,
    ...OfflineTravelInsuranceRoutes,
    ...OfflineMotorInsurancePolicyRoutes,
    ...OfflineGeneralLifeInsurancePolicyRoutes,
    ...OnlineTwoWheelerInsuranceRoutes,
    ...OnlineCarInsuranceRoutes,
    ...BlogRoutes,
    ...PospBlogRoutes,
    {
        path: '**',
        component: NotFoundComponent,
        canActivate: [checkUsersGuard]
    },
];
