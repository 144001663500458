<div class="main_container">
    <div class="sec pos_ds mb-2">
        <div class="bg-white p-3 rounded-2 border shadow-sm pf-tabs">
            <div class="row">
                <div class="col-md-12">
                    <div class="profile_section h-100">
                        <mat-tab-group animationDuration="0ms">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span class="icon">
                                        <img src="images/icons/user.svg" width="14">
                                    </span>
                                    <span class="text-label">Personal Information</span>
                                </ng-template>
                                <div class="card-body p-0">
                                    <h2 class="fs-6 fw-semi mb-4">Personal Information</h2>
                                    @if(getPospData?.posp_detail?.profile_doc != ''){
                                    <div class="profile_pic mb-4">
                                        @if(isPdfOrImage(getPospData?.posp_detail?.profile_doc) ==
                                        globalConstant.file_setting.type_pdf) {
                                        <img (click)="downloadDocument(profileDocUrl)" src="images/icons/pdf.png"
                                            [alt]="projectName" class="custom-download-document pdfimg" />
                                        } @else {
                                        <img (click)="downloadDocument(profileDocUrl)"
                                            [src]="profileDocUrl ?? 'images/icons/user-icon.png'" [alt]="projectName"
                                            class="custom-download-document" />
                                        }
                                    </div>
                                    }
                                    <div class="row">
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Full name</label>
                                                <input value="{{ getPospData?.full_name || '' }}" type="text"
                                                    class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/user.svg" [alt]="projectName" width="16"
                                                        height="21" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Email address</label>
                                                <input value="{{ getPospData?.email || '' }}" type="text"
                                                    class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/email.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Mobile number</label>
                                                <input value="{{ getPospData?.phone || '' }}" type="text"
                                                    class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/mobile.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Date of Birth</label>
                                                @if(getPospData?.posp_detail?.date_of_birth &&
                                                getPospData?.posp_detail?.date_of_birth != '') {
                                                <input
                                                    value="{{ getDBDateShow(getPospData?.posp_detail?.date_of_birth) }}"
                                                    type="text" class="form-control" disabled>
                                                } @else {
                                                <input value="" type="text" class="form-control" disabled>
                                                }
                                                <span class="icon">
                                                    <img src="images/icons/date.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">State</label>
                                                <input value="{{ getPospData?.posp_detail?.state_data?.name || '' }}"
                                                    type="text" class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/state.png" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">City</label>
                                                <input value="{{ getPospData?.posp_detail?.city || '' }}" type="text"
                                                    class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/city.png" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Pincode</label>
                                                <input value="{{ getPospData?.posp_detail?.pincode || '' }}" type="text"
                                                    class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/pin-code.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Address</label>
                                                <input value="{{ getPospData?.posp_detail?.address || '' }}" type="text"
                                                    class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/address.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span class="icon">
                                        <img src="images/icons/pan-card.svg" width="18">
                                    </span>
                                    <span class="text-label">Pan Card Details</span>
                                </ng-template>
                                <div class="card-body p-0">
                                    <h2 class="fs-6 fw-semi mb-4">Pan Card Details</h2>
                                    <div class="row">
                                        <div class="col-md-12 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">PAN Number</label>
                                                <input value="{{ getPospData?.posp_detail?.pan_card || '' }}"
                                                    type="text" class="form-control text-uppercase" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/pan-card.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        @if(getPospData?.posp_detail?.pan_card_doc != ''){
                                        <div class="col-md-12 signform step_form">
                                            <div class="pancard_img">
                                                @if(isPdfOrImage(getPospData?.posp_detail?.pan_card_doc) ==
                                                globalConstant.file_setting.type_pdf) {
                                                <img (click)="downloadDocument(pancardDocUrl)"
                                                    src="images/icons/pdf.png" [alt]="projectName"
                                                    class="custom-download-document pdfimg" />
                                                } @else {
                                                <img (click)="downloadDocument(pancardDocUrl)"
                                                    [src]="pancardDocUrl ?? 'images/icons/pan-card.svg'"
                                                    [alt]="projectName" class="custom-download-document" />
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span class="icon">
                                        <img src="images/icons/aadhar-card.svg" width="14">
                                    </span>
                                    <span class="text-label">Aadhaar Information</span>
                                </ng-template>
                                <div class="card-body p-0">
                                    <h2 class="fs-6 fw-semi mb-4">Aadhaar Card Details</h2>
                                    <div class="row">
                                        <div class="col-md-12 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Aadhaar Number</label>
                                                <input value="{{ getPospData?.posp_detail?.aadhar_number || '' }}"
                                                    type="text" class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/pan-card.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        @if(getPospData?.posp_detail?.aadhar_front_doc != ''){
                                        <div class="col-6 signform step_form">
                                            <div class="pancard_img">
                                                @if(isPdfOrImage(getPospData?.posp_detail?.aadhar_front_doc) ==
                                                globalConstant.file_setting.type_pdf) {
                                                <img (click)="downloadDocument(aadhaarFrontDocUrl)"
                                                    src="images/icons/pdf.png" [alt]="projectName"
                                                    class="custom-download-document pdfimg" />
                                                } @else {
                                                <img (click)="downloadDocument(aadhaarFrontDocUrl)"
                                                    [src]="aadhaarFrontDocUrl ?? 'images/icons/pan-card.svg'"
                                                    [alt]="projectName" class="custom-download-document" />
                                                }
                                            </div>
                                        </div>
                                        }
                                        @if(getPospData?.posp_detail?.aadhar_back_doc != ''){
                                        <div class="col-6 signform step_form">
                                            <div class="pancard_img">
                                                @if(isPdfOrImage(getPospData?.posp_detail?.aadhar_back_doc) ==
                                                globalConstant.file_setting.type_pdf) {
                                                <img (click)="downloadDocument(aadhaarBackDocUrl)"
                                                    src="images/icons/pdf.png" [alt]="projectName"
                                                    class="custom-download-document pdfimg" />
                                                } @else {
                                                <img (click)="downloadDocument(aadhaarBackDocUrl)"
                                                    [src]="aadhaarBackDocUrl ?? 'images/icons/pan-card.svg'"
                                                    [alt]="projectName" class="custom-download-document" />
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span class="icon">
                                        <img src="images/icons/bank.svg" width="18">
                                    </span>
                                    <span class="text-label">Bank Details</span>
                                </ng-template>
                                <div class="card-body p-0">
                                    <h2 class="fs-6 fw-semi mb-4">Bank Details </h2>
                                    <div class="row">
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Account Number</label>
                                                <input value="{{ getPospData?.posp_detail?.bank_account_number || '' }}"
                                                    type="text" class="form-control" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/account.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">IFSC Code</label>
                                                <input value="{{ getPospData?.posp_detail?.ifsc_code || '' }}"
                                                    type="text" class="form-control text-uppercase" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/pin-code.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Bank Name</label>
                                                <input value="{{ getPospData?.posp_detail?.bank_name || '' }}"
                                                    type="text" class="form-control text-uppercase" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/bank.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Branch Name</label>
                                                <input value="{{ getPospData?.posp_detail?.bank_branch_name || '' }}"
                                                    type="text" class="form-control text-uppercase" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/building.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        @if(getPospData?.posp_detail?.cheque_doc != ''){
                                        <div class="col-6 signform step_form">
                                            <label class="d-block fs-14 fw-semi mb-2">Passbook / Cheque</label>
                                            <div class="check img_doc">
                                                @if(isPdfOrImage(getPospData?.posp_detail?.cheque_doc) ==
                                                globalConstant.file_setting.type_pdf) {
                                                <img (click)="downloadDocument(bankDocUrl)" src="images/icons/pdf.png"
                                                    [alt]="projectName" class="custom-download-document pdfimg" />
                                                } @else {
                                                <img (click)="downloadDocument(bankDocUrl)"
                                                    [src]="bankDocUrl ?? 'images/icons/pan-card.svg'"
                                                    [alt]="projectName" class="custom-download-document" />
                                                }
                                            </div>
                                        </div>
                                        }
                                        @if(getPospData?.posp_detail?.signature_doc != ''){
                                        <div class="col-6 signform step_form">
                                            <label class="d-block fs-14 fw-semi mb-2">Signature</label>
                                            <div class="check img_doc">
                                                @if(isPdfOrImage(getPospData?.posp_detail?.signature_doc) ==
                                                globalConstant.file_setting.type_pdf) {
                                                <img (click)="downloadDocument(pospSignDocUrl)"
                                                    src="images/icons/pdf.png" [alt]="projectName"
                                                    class="custom-download-document pdfimg" />
                                                } @else {
                                                <img (click)="downloadDocument(pospSignDocUrl)"
                                                    [src]="pospSignDocUrl ?? 'images/icons/pan-card.svg'"
                                                    [alt]="projectName" class="custom-download-document" />
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span class="icon">
                                        <img src="images/icons/education.svg" width="20">
                                    </span>
                                    <span class="text-label">Education Information</span>
                                </ng-template>
                                <div class="card-body p-0">
                                    <h2 class="fs-6 fw-semi mb-4">Educational Information</h2>
                                    <div class="row">
                                        <div class="col-md-12 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label class="control-label input-label">Education</label>
                                                <input
                                                    value="{{ getPospData?.posp_detail?.educational_qualification || '' }}"
                                                    type="text" class="form-control text-uppercase" disabled>
                                                <span class="icon">
                                                    <img src="images/icons/education.svg" [alt]="projectName" width="16"
                                                        height="15" />
                                                </span>
                                            </div>
                                        </div>
                                        @if(getPospData?.posp_detail?.educational_certificate_doc != ''){
                                        <div class="col-md-6 signform step_form">
                                            <div class="check img_doc">
                                                @if(isPdfOrImage(getPospData?.posp_detail?.educational_certificate_doc)
                                                == globalConstant.file_setting.type_pdf) {
                                                <img (click)="downloadDocument(pospeduDocUrl)"
                                                    src="images/icons/pdf.png" [alt]="projectName"
                                                    class="custom-download-document pdfimg" />
                                                } @else {
                                                <img (click)="downloadDocument(pospeduDocUrl)"
                                                    [src]="pospeduDocUrl ?? 'images/icons/pan-card.svg'"
                                                    [alt]="projectName" class="custom-download-document" />
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>