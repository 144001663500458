import { environment } from '../../../environments/environment';

export const GLOBAL_CONSTANT = {

  base_url: '/',
  projectName: 'Telsysweb Insurance',
  storage_base_url: environment?.storage_base_url,

  session: {
    auth_token: 'auth_token'
  },

  googleAnalyticID: 'G-9D5G5BR6DX',

  offlineServiceOrderBy: 'order_placed_at',

  cookie: {
    otp_verification_data: 'otp_verification_data',
    otp_verification_data_expire: 10,
    service_login_data: 'service_login_data',
    service_login_data_expire: 20
  },

  currencySymbol: "₹",

  dateTime: {
    minDobYear: 18,
    maxDobYear: 100,
    minRegYear: 1,
    maxRegYear: 20,
    formField: 'DD-MM-YYYY',
    dbDateTimeShow: 'DD-MM-YYYY HH:mm:ss',
    dbDateShow: 'DD-MM-YYYY',
    yearShow: 'YYYY',
    DD_MMM_YYYY: 'DD-MMM-YYYY',
    orderFillterMin: '2023-01-01',
  },

  paginate: {
    perPage: 10
  },

  noOrderFoundMessage: 'No data matching the filter',

  file: {
    /* max_file_size: (3 * 1024 * 1024),
    max_file_label: "(Max file size 3MB)",
    max_file_label2: "Upload Documents Maximum file size 3MB Allowed", */
    max_file_size: (20 * 1024 * 1024),
    max_file_label: "(Max file size 20MB)",
    max_file_label2: "Upload Documents Maximum file size 20MB Allowed",
    file_image_type: ['jpeg', 'jpg', 'png'],
    file_image_accept: "image/jpeg,image/jpg,image/png",
    file_image_accept_label: "(only - .jpeg, .jpg, .png format)",
    file_image_pdf_type: ['jpeg', 'jpg', 'png', 'pdf'],
    file_image_pdf_accept: "image/jpeg,image/jpg,image/png,application/pdf",
    file_image_pdf_accept_label: "(only - .jpeg, .jpg, .png, .pdf format)"
  },

  user_type: {
    posp: 'posp',
    user: 'user'
  },

  offline_order_show_type_array: ['orders', 'commission', 'payment'],

  offline_order_show_type: {
    orders: 'orders',
    commission: 'commission',
    payment: 'payment'
  },

  offline_order_show_type_match: {
    orders: 'orders'
  },

  offline_policy_page_name: {
    policy: 'policy',
    renewal: 'renewal'
  },

  offline_policy_insurance_type_match: {
    motor_insurance: 'motor_insurance',
    general_life_insurance: 'general_life_insurance'
  },

  offline_policy_insurance_name: {
    motor_insurance: "Motor Insurance Policy",
    general_life_insurance: "General Life Insurance Policy"
  },

  date_key_array: ['today', 'this-week', 'this-month'],

  date_key_match: {
    today: 'today',
    this_week: 'this-week',
    this_month: 'this-month'
  },

  file_setting: {
    upload_max_size: 3,
    image_extensions: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'],
    type_pdf: 'pdf',
    type_image: 'image',
    type_unknown: 'unknown'
  },

  status_match: {
    no_activity: 'no_activity',
    active: 'active',
    inactive: 'inactive',
    processing: 'processing',
    incomplete: 'incomplete',
    pending: 'pending',
    approved: 'approved',
    rejected: 'rejected',
    cancelled: 'cancelled',
    completed: 'completed',
    time_pending: 'time_pending',
    passed: 'passed',
    failed: 'failed',
    yes: 'yes',
    no: 'no'
  },

  defaultTrainingCourse: 'general_life_ins',

  orderEditableStatus: ['incomplete', 'pending'],

  policyFilledInStatus: ["completed"],

  posp_kyc_step_match: {
    basic_details: 'basic_details',
    pan_aadhar_details: 'pan_aadhar_details',
    education_details: 'education_details',
    bank_details: 'bank_details'
  },

  service_type_match: {
    offline: 'offline',
    online: 'online'
  },

  offline_bike_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_car_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_rickshaw_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_taxi_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_tractor_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_truck_bus_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_truck_pickup_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_health_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_term_life_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_child_saving_plans_step_match: {
    step_1: 'basic_details',
    step_2: 'plan_details'
  },

  offline_investment_plans_step_match: {
    step_1: 'basic_details',
    step_2: 'plan_details'
  },

  offline_retirement_plans_step_match: {
    step_1: 'basic_details',
    step_2: 'plan_details'
  },

  offline_smart_saving_plans_step_match: {
    step_1: 'basic_details',
    step_2: 'plan_details'
  },

  offline_business_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_travel_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_home_insurance_step_match: {
    step_1: 'basic_details',
    step_2: 'insurance_details'
  },

  offline_lic_plans_step_match: {
    step_1: 'basic_details',
    step_2: 'plan_details'
  },

  posp_verified_status_match: {
    posp_nvoy_irdai_checked_done: 'posp_nvoy_irdai_checked_done',
    registered_with_other_company: 'registered_with_other_company',
    migration_request_in_processing: 'migration_request_in_processing',
    agent_deactivation_request_permanent: 'agent_deactivation_request_permanent'
  },

  online_bike_insurance_step_match: {
    step_1: 'vehicle_details',
    step_2: 'quotes',
    step_3: 'update_quote'
  },

};

export const STATUS_MAP: Record<string, string> = {
  no_activity: 'No Activity',
  active: 'Active',
  inactive: 'Inactive',
  processing: 'Processing',
  incomplete: 'Incomplete',
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  completed: 'Completed',
  time_pending: 'Time Pending',
  passed: 'Passed',
  failed: 'Failed',
  yes: 'Yes',
  no: 'No',
  on_hold: 'On Hold',
};


export const NOMINEE_MAP: Record<string, string> = {
  father: 'Father',
  mother: 'Mother',
  brother: 'Brother',
  sister: 'Sister',
  wife: 'Wife',
  husband: 'Husband',
  son: 'Son',
  daughter: 'Daughter'
};

export const POLICY_TYPE_MAP: Record<string, string> = {
  own_damage: 'Only OD (Own Damage)',
  third_party: 'Only TP (Third Party)',
  package_policy: 'Package Policy (OD + TP)'
};

export const HEALTH_PLAN_TYPE_MAP: Record<string, string> = {
  individual: 'Individual',
  family: 'Family'
};

export const EDUCATION_DETAIL_MAP: Record<string, string> = {
  post_graduate_above: 'Post Graduate & Above',
  graduate: 'Graduate',
  diploma: 'Diploma',
  '12_pass': '12th Pass',
  '10_pass': '10th Pass',
  below_10: 'Below 10th'
};

export const GENDER_MAP: Record<string, string> = {
  Male: 'Male',
  Female: 'Female'
};