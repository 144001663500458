import { Injectable } from '@angular/core';
import { ApiService } from '../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospBlogService {

  constructor(
    private apiService: ApiService
  ) { }

  getPospBlog(formData: any) {
    return this.apiService.request<any>('POST', 'posp-blog', formData);
  }
}
