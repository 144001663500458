<div class="vehicle-make vehicleInfo" id="makeDiv">
    <p class="fw-semi text-primary mb-2">Select two wheeler make</p>
    <div class="row">
        <div class="col-md-12">
            <div [formGroup]="formGroup" class="form-group mb-4">
              <select id="make_name" formControlName="make_name" class="form-control form-select">
                <option value="">-- Select two wheeler make --</option>
                @if(arrayLength(getMakeList) > 0) {
                  @for(data of getMakeList; track i; let i = $index) {
                    <option value="{{ data?.name || '' }}">{{ data?.name || '' }}</option>
                  }
                }
              </select>
              <mat-error *ngIf="getErrorMessage('make_name')" class="custom-error">
                {{ getErrorMessage('make_name') }}
              </mat-error>
            </div>
        </div>
    </div>
    <p class="fw-semi fs-5 text-center mb-3">Popular makes</p>
    <div class="row">
        @if(arrayLength(getMakeList) > 0) {
            <div class="col-md-12">
              <ul class="grouplist mb-4">
                @for(data of getMakeList; track i; let i = $index) {
                  <li>
                    <div class="form-check form-check-inline typeradio">
                      <input class="form-check-input" type="radio" name="make_name" id="{{ data?.id }}" value="{{ data?.name || '' }}" (change)="onRadioSelect(data?.name)" [checked] ="isRadioChecked(data?.name)"/>
                      <label class="form-check-label justify-content-center" for="{{ data?.id }}">
                        <span class="imgspace">
                            <img src="https://test.webinsurance.in/storage/images/makelogos/honda.svg" width="50" height="50" [alt]="projectName" />
                        </span>
                        <span class="makename">{{ data?.name }}</span>
                      </label>
                    </div>
                  </li>
                }
              </ul>
            </div>
          }
        <div class="col-sm-12">
            <div class="form-group text-center">

              <button type="button" class="btn btn-secondary rounded-2 btn-lg px-5 me-3" (click)="onPrevious()">Previous</button>
              <button (click)="onContinue()" [disabled]="isLoading" class="btn btn-success rounded-2 btn-lg px-5">Continue</button>
            </div>
        </div>
    </div>
</div>