<div class="blog pt-3 mt-md-0 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 col-xl-8">
                <div *ngIf="dataSource.data && objectLength(dataSource.data) > 0; else noBlogs">
                    <div class="row">
                        <div *ngFor="let data of dataSource.data; let i = index" class="col-md-6 col-lg-6 col-xl-6 py-3">
                            <div class="border rounded h-100 pb-2">
                                <div class="blog_image">
                                    <a [routerLink]="[globalConstant.base_url, routesBlog.DETAIL || '', data?.slug]" class="d-flex align-items-center justify-content-center">
                                        <ng-container *ngIf="data?.thumbnail && data?.thumbnail !== '' && data?.thumbnail !== null">
                                            <span>
                                                <img [src]="globalConstant.storage_base_url + data.thumbnail" [alt]="data?.alt" class="img-fluid rounded-top">
                                            </span>
                                        </ng-container>
                                    </a>
                                </div>
                                <div class="blog_content">
                                    <ul>
                                        <li class="mt-lg-3 mt-xl-0">
                                            <span class="font_14 text-uppercase">
                                                <i class="bx bx-calendar pe-1"></i>
                                                <ng-container *ngIf="data?.posted_at && data?.posted_at !== ''">
                                                    {{ formatDate(data?.posted_at) }}
                                                </ng-container>
                                            </span>
                                        </li>
                                    </ul>
                                    <h3 class="fw-semibold fs-5">
                                        <a [routerLink]="[globalConstant.base_url, routesBlog.DETAIL || '', data?.slug]" class="text-dark title">
                                            {{ data?.title }}
                                        </a>
                                    </h3>
                                    <div [innerHTML]="sanitizeHtml(data.short_description)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custom-mat-paginator">
                    <mat-paginator 
                        [length]="totalItems" 
                        [hidePageSize]="true" 
                        [pageSize]="globalConstant.paginate.perPage">
                    </mat-paginator>
                </div>
                <ng-template #noBlogs>
                    <div class="no-blogs-message text-center py-3">
                        <p class="text-muted">No blogs found.</p>
                    </div>
                </ng-template>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4 py-3 order-md-1">
                <div class="second_blog">
                    <!-- <div class="mb-4 rounded">
                        <form>
                            <div class="input-group">
                                <input type="text" class="form-control py-0" style="height:38px;" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1">
                                <button class="input-group-text border-0 text-white" id="basic-addon1">Search</button>
                            </div>
                        </form>
                    </div> -->
                    <app-blog-widget></app-blog-widget>
                </div>
            </div>
        </div>
    </div>
</div>
