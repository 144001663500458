import { Component } from '@angular/core';

@Component({
  selector: 'app-online-two-wheeler-insurance-payment-success',
  standalone: true,
  imports: [],
  templateUrl: './online-two-wheeler-insurance-payment-success.component.html',
})
export class OnlineTwoWheelerInsurancePaymentSuccessComponent {

}
