<section class="sec sec-checkout pt-3 pb-5">
    <div class="container">
        <div class="row mt-3">
            @if(objectLength(vehicleDetail) > 0) {
                <div class="col-md-8">
                    <div class="border bg-white p-3 rounded-2">
                        <div class="cmnform onlinesvcform">
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <p class="fw-bold fs-18">Proposal Form (Owner details)</p>
                                <div class="row gx-3">
                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Salutation / (अभिवादन) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <select id="title" formControlName="title" class="form-control form-select">
                                                <option value="">-- Select Salutation --</option>
                                                @if(getTitle && arrayLength(getTitle) > 0) {
                                                    @for(data of getTitle; track i; let i=$index) {
                                                        <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                    }
                                                }
                                            </select>
                                            <mat-error *ngIf="getErrorMessage('title')" class="custom-error">
                                                {{ getErrorMessage('title') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">First Name / (प्रथम नाम) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="first_name" formControlName="first_name" id="first_name" placeholder="First Name" maxlength="60" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('first_name')" class="custom-error">
                                                {{ getErrorMessage('first_name') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Middle Name / (मध्य नाम)</mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="middle_name" formControlName="middle_name" id="middle_name" placeholder="Middle Name" maxlength="40" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('middle_name')" class="custom-error">
                                                {{ getErrorMessage('middle_name') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Last Name / (अंतिम नाम)</mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="last_name" formControlName="last_name" id="last_name" placeholder="Last Name" maxlength="40" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('last_name')" class="custom-error">
                                                {{ getErrorMessage('last_name') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Gender / (लिंग) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <select id="gender" formControlName="gender" class="form-control form-select">
                                                <option value="">-- Select Gender --</option>
                                                @if(getGender && arrayLength(getGender) > 0) {
                                                    @for(data of getGender; track i; let i=$index) {
                                                        <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                    }
                                                }
                                            </select>
                                            <mat-error *ngIf="getErrorMessage('gender')" class="custom-error">
                                                {{ getErrorMessage('gender') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Date of Birth (जन्म की तारीख) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group icon-group mb-4">
                                            <input matInput [matDatepicker]="date_of_birth" [min]="minDobDate" [max]="maxDobDate" id="date_of_birth" formControlName="date_of_birth" placeholder="MM/DD/YYYY" type="text" class="form-control custom-input" autocomplete="off" (click)="openDobDatepicker()" (keydown)="datePickerInput($event)" />
                                            <mat-icon matSuffix class="custom-icon icon">
                                                <mat-datepicker #date_of_birth></mat-datepicker>
                                            </mat-icon>
                                            <mat-error *ngIf="getErrorMessage('date_of_birth')" class="custom-error">{{ getErrorMessage('date_of_birth') }}</mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Mobile Number / (मोबाइल नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput appNumericInput name="phone" formControlName="phone" id="phone" placeholder="Mobile Number" minlength="10" maxlength="10" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('phone')" class="custom-error">
                                                {{ getErrorMessage('phone') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Email ID / (ईमेल) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="email" formControlName="email" id="email" placeholder="Email ID" maxlength="100" type="email" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('email')" class="custom-error">
                                                {{ getErrorMessage('email') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Address 1 (घर/फ्लैट नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="address_one" formControlName="address_one" id="address_one" placeholder="House/Flat/Street" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('address_one')" class="custom-error">
                                                {{ getErrorMessage('address_one') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Address 2 (पता 2) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="address_two" formControlName="address_two" id="address_two" placeholder="Address 2" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('address_two')" class="custom-error">
                                                {{ getErrorMessage('address_two') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Address 3 (पता 3)</mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="address_three" formControlName="address_three" id="address_three" placeholder="Address 3" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('address_three')" class="custom-error">
                                                {{ getErrorMessage('address_three') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Select State / (राज्य) <span class="text-danger"> *</span></mat-label>
                                        <div class="form-group icon-group mb-3">
                                            <select id="state_code" formControlName="state_code" class="form-control form-control-custom form-select-custom form-select">
                                                <option value="">-- Select state --</option>
                                                @if(arrayLength(getState) > 0) {
                                                    @for(data of getState; track i; let i=$index) {
                                                        <option value="{{ data?.state_code || '' }}">{{ data?.name || '' }}</option>
                                                    }
                                                }
                                            </select>
                                            <mat-error *ngIf="getErrorMessage('state_code')" class="custom-error">
                                                {{ getErrorMessage('state_code') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">City / (शहर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group icon-group mb-3">
                                            <input matInput name="city" formControlName="city" id="city" placeholder="City" maxlength="100" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('city')" class="custom-error">
                                                {{ getErrorMessage('city') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Pincode / (पिन कोड) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group icon-group mb-3">
                                            <input matInput appNumericInput name="pincode" formControlName="pincode" id="pincode" placeholder="Pincode" minlength="6" maxlength="6" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('pincode')" class="custom-error">
                                                {{ getErrorMessage('pincode') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">GST Number / (जीएसटी नंबर)</mat-label>
                                        <div class="form-group icon-group mb-3">
                                            <input matInput name="gstin_number" formControlName="gstin_number" id="gstin_number" placeholder="GST Number" maxlength="15" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('gstin_number')" class="custom-error">
                                                {{ getErrorMessage('gstin_number') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row gx-3">
                                    <div class="col-md-6 col-lg-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Document For Verification / (सत्यापन के लिए दस्तावेज़) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <select id="kyc_reference_doc_id" formControlName="kyc_reference_doc_id" class="form-control form-select">
                                                <option value="">-- Document Type --</option>
                                                @if(getDocumentType && arrayLength(getDocumentType) > 0) {
                                                    @for(data of getDocumentType; track i; let i=$index) {
                                                        <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                    }
                                                }
                                            </select>
                                            <mat-error *ngIf="getErrorMessage('kyc_reference_doc_id')" class="custom-error">
                                                {{ getErrorMessage('kyc_reference_doc_id') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Document Number / (दस्तावेज़ नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group icon-group mb-4">
                                            <input matInput name="kyc_reference_number" formControlName="kyc_reference_number" id="kyc_reference_number" placeholder="Document Number" maxlength="40" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('kyc_reference_number')" class="custom-error">
                                                {{ getErrorMessage('kyc_reference_number') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                                <p class="fw-bold fs-18 mt-2">Vehicle details</p>
                                <div class="row gx-3">
                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Vehicle Number / (वाहन नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="vehicle_number" formControlName="vehicle_number" id="vehicle_number" placeholder="Vehicle Number:  (eg. RJ14AB1234)" maxlength="10" type="text" class="form-control" appUppercase />
                                            <mat-error *ngIf="getErrorMessage('vehicle_number')" class="custom-error">
                                                {{ getErrorMessage('vehicle_number') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Engine Number / (इंजन नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="engine_number" formControlName="engine_number" id="engine_number" placeholder="Engine Number" type="text" class="form-control" appUppercase />
                                            <mat-error *ngIf="getErrorMessage('engine_number')" class="custom-error">
                                                {{ getErrorMessage('engine_number') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Chassis Number / (चेसिस नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="chassis_number" formControlName="chassis_number" id="chassis_number" placeholder="Chassis Number" type="text" class="form-control" appUppercase />
                                            <mat-error *ngIf="getErrorMessage('chassis_number')" class="custom-error">
                                                {{ getErrorMessage('chassis_number') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Registration Date / (पंजीकरण तिथि) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group icon-group mb-3">
                                            <input matInput [matDatepicker]="registration_date" [min]="minRegistrationDate" [max]="maxRegistrationDate" id="registration_date" formControlName="registration_date" placeholder="MM/DD/YYYY" type="text" class="form-control custom-input" autocomplete="off" (click)="openRegistrationDateDatepicker()" (keydown)="datePickerInput($event)" />
                                            <mat-icon matSuffix class="custom-icon icon">
                                                <mat-datepicker #registration_date></mat-datepicker>
                                            </mat-icon>
                                            <mat-error *ngIf="getErrorMessage('registration_date')" class="custom-error">
                                                {{ getErrorMessage('registration_date') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-8">
                                        <div class="vhRow">
                                            <div class="vhSelect">
                                                <mat-label class="fs-14 mb-1 custom-label">Vehicle owner/ (वाहन का स्वामित्व) <span class="text-danger"> * </span></mat-label>
                                                <div class="form-group">
                                                    @if(getPolicyHolderType && arrayLength(getPolicyHolderType) > 0) {
                                                        @for(data of getPolicyHolderType; track i; let i=$index) {
                                                            <div class="form-check form-check-inline typeradio customradio">
                                                                <input type="radio" id="person_type_{{ data?.value || '' }}" name="person_type" formControlName="person_type" value="{{ data?.value || '' }}" class="form-check-input">
                                                                <label for="person_type_{{ data?.value || '' }}" class="form-check-label">{{ data?.label || '' }}</label>
                                                            </div>
                                                        }
                                                    }
                                                    <mat-error *ngIf="getErrorMessage('person_type')" class="custom-error">
                                                        {{ getErrorMessage('person_type') }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                            @if(customForm.get('person_type')?.value == globalConstant.match.policy_holder_type_organization) {
                                                <div class="vehicle_organization_row">
                                                    <mat-label class="fs-14 mb-1 custom-label">Organization / (संस्था नाम) <span class="text-danger"> * </span></mat-label>
                                                    <div class="form-group mb-3">
                                                        <input matInput name="organization_name" formControlName="organization_name" id="organization_name" placeholder="Organization Name" maxlength="200" type="text" class="form-control custom-input" />
                                                        <mat-error *ngIf="getErrorMessage('organization_name')" class="custom-error">
                                                            {{ getErrorMessage('organization_name') }}
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Hypothecation? / (वाहन ऋण) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group">
                                            <div class="form-check form-check-inline typeradio customradio">
                                                <input type="radio" id="is_hypothecation_1" name="is_hypothecation" formControlName="is_hypothecation" value="1" class="form-check-input">
                                                <label for="is_hypothecation_1" class="form-check-label">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline typeradio customradio pe-0">
                                                <input type="radio" id="is_hypothecation_0" name="is_hypothecation" formControlName="is_hypothecation" value="0" class="form-check-input">
                                                <label for="is_hypothecation_0" class="form-check-label">No</label>
                                            </div>
                                            <mat-error *ngIf="getErrorMessage('is_hypothecation')" class="custom-error">
                                                {{ getErrorMessage('is_hypothecation') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    @if(customForm.get('is_hypothecation')?.value == '1') {
                                        <div class="col-md-6 col-lg-4">
                                            <mat-label class="fs-14 mb-1 custom-label">Financer Name / (फाइनेंसर का नाम) <span class="text-danger"> * </span></mat-label>
                                            <div class="form-group mb-3">
                                                <input matInput name="financer_name" formControlName="financer_name" id="financer_name" placeholder="Financer name" maxlength="200" type="text" class="form-control custom-input" />
                                                <mat-error *ngIf="getErrorMessage('financer_name')" class="custom-error">
                                                    {{ getErrorMessage('financer_name') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-4">
                                            <mat-label class="fs-14 mb-1 custom-label">Financer City / (फाइनेंसर शहर) <span class="text-danger"> * </span></mat-label>
                                            <div class="form-group mb-3">
                                                <input matInput name="financer_city" formControlName="financer_city" id="financer_city" placeholder="Financer city" maxlength="100" type="text" class="form-control custom-input" />
                                                <mat-error *ngIf="getErrorMessage('financer_city')" class="custom-error">
                                                    {{ getErrorMessage('financer_city') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <p class="fw-bold fs-18 mt-2">Nominee Details</p>
                                <div class="row gx-3">
                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee First Name / (नॉमिनी प्रथम नाम) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="nominee_first_name" formControlName="nominee_first_name" id="nominee_first_name" placeholder="Nominee First Name" maxlength="70" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('nominee_first_name')" class="custom-error">
                                                {{ getErrorMessage('nominee_first_name') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee Middle Name / (नॉमिनी मध्य नाम) </mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="nominee_middle_name" formControlName="nominee_middle_name" id="nominee_middle_name" placeholder="Nominee Middle Name" maxlength="50" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('nominee_middle_name')" class="custom-error">
                                                {{ getErrorMessage('nominee_middle_name') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee Last Name / (नॉमिनी अंतिम नाम) </mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="nominee_last_name" formControlName="nominee_last_name" id="nominee_last_name" placeholder="Nominee Last Name" maxlength="50" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('nominee_last_name')" class="custom-error">
                                                {{ getErrorMessage('nominee_last_name') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee Relation / (नॉमिनी सबंध) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <select id="nominee_relation" formControlName="nominee_relation" class="form-control form-control-custom form-select-custom form-select">
                                                <option value="">-- Nominee Relation --</option>
                                                @if(getNomineeRelation && arrayLength(getNomineeRelation) > 0) {
                                                    @for(data of getNomineeRelation; track i; let i=$index) {
                                                        <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                    }
                                                }
                                            </select>
                                            <mat-error *ngIf="getErrorMessage('nominee_relation')" class="custom-error">
                                                {{ getErrorMessage('nominee_relation') }}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee D.O.B / (नॉमिनी जन्म तिथि) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput [matDatepicker]="nominee_dob" [min]="minNomineeDobDate" [max]="maxNomineeDobDate" id="nominee_dob" formControlName="nominee_dob" placeholder="MM/DD/YYYY" type="text" class="form-control custom-input" autocomplete="off" (click)="openNomineeDobDatepicker()" (keydown)="datePickerInput($event)" />
                                            <mat-icon matSuffix class="custom-icon icon">
                                                <mat-datepicker #nominee_dob></mat-datepicker>
                                            </mat-icon>
                                            <mat-error *ngIf="getErrorMessage('nominee_dob')" class="custom-error">
                                                {{ getErrorMessage('nominee_dob') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                                <p class="fw-bold fs-18 mt-2">Have you Previous Insurer and Policy details</p>
                                <div class="row gx-3">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="form-group">
                                            <div class="form-check form-check-inline typeradio customradio">
                                                <input type="radio" id="is_previous_insurance_remember_1" name="is_previous_insurance_remember" formControlName="is_previous_insurance_remember" value="1" class="form-check-input">
                                                <label for="is_previous_insurance_remember_1" class="form-check-label">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline typeradio customradio pe-0">
                                                <input type="radio" id="is_previous_insurance_remember_0" name="is_previous_insurance_remember" formControlName="is_previous_insurance_remember" value="0" class="form-check-input">
                                                <label for="is_previous_insurance_remember_0" class="form-check-label">No</label>
                                            </div>
                                            <mat-error *ngIf="getErrorMessage('is_previous_insurance_remember')" class="custom-error">
                                                {{ getErrorMessage('is_previous_insurance_remember') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                                @if(customForm.get('is_previous_insurance_remember')?.value == '1') {
                                    <div class="previous_insurer_row">
                                        <div class="row gx-3">
                                            <div class="col-md-6">
                                                <mat-label class="fs-14 mb-1 custom-label">Previous Policy Status <span class="text-danger"> * </span></mat-label>
                                                <div class="form-group mb-3">
                                                    <select id="previous_insurance_status"
                                                        formControlName="previous_insurance_status"
                                                        class="form-control form-control-custom form-select-custom form-select">
                                                        <option value="">-- Previous Insurance Status --</option>
                                                        @if(getPolicyStatus && arrayLength(getPolicyStatus) > 0) {
                                                            @for(data of getPolicyStatus; track i; let i=$index) {
                                                                <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                            }
                                                        }
                                                    </select>
                                                    <mat-error *ngIf="getErrorMessage('previous_insurance_status')" class="custom-error">
                                                        {{ getErrorMessage('previous_insurance_status') }}
                                                    </mat-error>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <mat-label class="fs-14 mb-1 custom-label">Previous Insurance Type <span class="text-danger"> * </span></mat-label>
                                                <div class="form-group mb-3">
                                                    <select id="previous_insurance_type" formControlName="previous_insurance_type" class="form-control form-control-custom form-select-custom form-select">
                                                        <option value="">-- Previous Insurance Type --</option>
                                                        @if(getPolicyType && arrayLength(getPolicyType) > 0) {
                                                            @for(data of getPolicyType; track i; let i=$index) {
                                                                <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                            }
                                                        }
                                                    </select>
                                                    <mat-error *ngIf="getErrorMessage('previous_insurance_type')" class="custom-error">
                                                        {{ getErrorMessage('previous_insurance_type') }}
                                                    </mat-error>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6">
                                                <mat-label class="fs-14 mb-1 custom-label">Previous Insurance Company <span class="text-danger"> * </span></mat-label>
                                                <div class="form-group icon-group mb-3">
                                                    <select id="previous_insurance_company" formControlName="previous_insurance_company" class="form-control form-control-custom form-select-custom form-select">
                                                        <option value="">-- Select previous Insurer --</option>
                                                        @if(arrayLength(getPreviousInsurer) > 0) {
                                                            @for(data of getPreviousInsurer; track i; let i=$index) {
                                                                <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                            }
                                                        }
                                                    </select>
                                                    <mat-error *ngIf="getErrorMessage('previous_insurance_company')" class="custom-error">
                                                        {{ getErrorMessage('previous_insurance_company') }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div class="row">
                                    <div class="col-md-12 mt-2">
                                        <div class="form-group text-center">
                                            <button type="submit" class="btn btn-success rounded-5 btn-lg px-5" [disabled]="isLoading">Save and Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mt-3 mt-md-0">
                    <div class="border bg-white p-3 rounded-2 possticky minhight230">
                        <div class="insure_meber">
                            <div class="inCard bg-white border-0 border-bottom p-0 pb-2 rounded-0">
                                <div class="userview">
                                    <div class="fw-semi">{{ vehicleDetail?.vehicle_model }}</div>
                                    <div class="fs-13 fw-normal">
                                        <span> {{ vehicleDetail?.vehicle_number }}</span>
                                        <span class="ms-1 ps-2 border-start border-green border-1">Year:
                                            <span class="fw-semi">
                                                @if(vehicleDetail?.registration_date && vehicleDetail?.registration_date != '') {
                                                    {{ formatDate(vehicleDetail?.registration_date) }}
                                                }
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        @if(objectLength(selectInsurerData) > 0) {
                            <div class="card-top my-3">
                                <div class="fnc-img rounded-2 border p-2">
                                     <img [src]="selectInsurerData?.['insurer_logo'] ?? 'images/default/default-insurance.png'" alt="{{ selectInsurerData?.['insurer_logo'] }}" width="80" height="53" />
                                </div>
                                <div class="fncName">
                                    <p class="plantype mb-0">Comprehensive</p>
                                    <p class="planvalue text-muted fs-14 mb-0">Vehicle value (IDV):
                                        <span class="dv-value fw-semi">{{ showCurrency(selectInsurerData?.currency_symbol, selectInsurerData?.idv?.default_idv || '') }}</span>
                                    </p>

                                </div>
                            </div>
                            <div class="cartamoutinfo">
                                <ul>
                                    <!-- <li>
                                        <div class="head fs-14">Basic own damage premium</div>
                                        <div class="value fw-normal fs-6"><i class="bx bx-rupee align-top"></i>153</div>
                                    </li>
                                    <li>
                                        <div class="head fs-14">Third party property damage</div>
                                        <div class="value fw-normal fs-6"><i class="bx bx-rupee align-top"></i>573</div>
                                    </li> -->
                                    <li class="last">
                                        <div class="head fw-semi">Net Premium</div>
                                        <div class="value">
                                            {{ showCurrency(selectInsurerData?.currency_symbol, selectInsurerData?.net_premium || '') }}
                                        </div>
                                    </li>
                                    <li>
                                        <div class="head fs-14">GST</div>
                                        <div class="value fw-normal fs-6">
                                            <!-- <i class="bx bx-plus align-top"></i>
                                            <i class="bx bx-rupee align-top"></i>130 -->
                                            ---
                                        </div>
                                    </li>
                                    <li class="last">
                                        <div class="head fw-semi">Total Premium</div>
                                        <div class="value">
                                            {{ showCurrency(selectInsurerData?.currency_symbol, selectInsurerData?.net_premium || '') }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        } @else {
                            <div class="col-md-12 col-lg-12 text-center mt-3">
                                Data not found.
                            </div>
                        }
                    </div>
                </div>
            } @else {
                <div class="col-md-12 col-lg-12 text-center">
                    Vehicle details not found.
                </div>
            }
        </div>
    </div>
</section>
<app-insurance-terms-conditions></app-insurance-terms-conditions>