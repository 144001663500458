import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineBikeInsuranceStepTwoService {

  constructor(
    private apiService: ApiService
  ) { }

  getState(formData: any) {
    return this.apiService.request<any>('POST', 'states', formData);
  }

  getFormData(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'offline/bike-insurance/order-edit', formData);
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'offline/bike-insurance/order-store', formData);
  }

}
