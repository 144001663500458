import { Injectable } from '@angular/core';
import { ApiService } from '../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class BlogDetailService {

  constructor(
    private apiService: ApiService
  ) { }

  getBlogDetailData(formData: any) {
    return this.apiService.request<any>('POST', 'blog/detail', formData);
  }
}
