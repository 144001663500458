export const INSURER_ID = {
  WEB_INSURANCE: 1,
  GO_DIGIT_MOTOR: 2
}

export const INSURER_CONSTANT = {
  plan_type: {
    comprehensive: 'comprehensive',
    third_party: 'third_party',
    standalone_own_damage: 'standalone_own_damage'
  }
};

export const INSURER_NAME_MAP: Record<string, string> = {
  GO_DIGIT_MOTOR: 'GO_DIGIT_MOTOR'
};

export const INSURER_ID_MAP: Record<string, number> = {
  /* WEB_INSURANCE: 1, */
  GO_DIGIT_MOTOR: 2
};