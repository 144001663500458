<section class="sec serviceForm basicInfo bg-lgwhite py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="border shadow-sm p-3 rounded-1 bg-white">
            <div class="text-center mb-3">
              <h1 class="fs-5 mb-1 fw-bold">Term Life Insurance Policy</h1>
              <div class="fs-18 fw-semi text-center">Let's Start Term Life Insurance Fill the Basic Details</div>
            </div>
            <div class="cmnform services-form">
              <div class="indicators">
                <div class="step-tabs step-current"><span>Basic Details</span></div>
                <div class="step-tabs step-current"><span>Insurance Details</span></div>
              </div>
              <div class="stpesForm mt-4">
                <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                  <div class="row">
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">PAN Number / (पैन कार्ड नंबर) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                       <input matInput name="pan_card" formControlName="pan_card" id="pan_card" placeholder="PAN Card Number" minlength="10" maxlength="10" type="text" class="form-control custom-input text-uppercase" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/pan-card.svg" [alt]="projectName" width="18" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('pan_card')" class="custom-error">
                          {{ getErrorMessage('pan_card') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Date of Birth  (जन्म की तारीख) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                        <input matInput [matDatepicker]="date_of_birth" [min]="minDobDate" [max]="maxDobDate" id="date_of_birth" formControlName="date_of_birth" placeholder="MM/DD/YYYY" type="text" class="form-control custom-input" autocomplete="off" (click)="openDobDatepicker()" (keydown)="datePickerInput($event)" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/date.svg" [alt]="projectName" width="20" height="20" />
                          <mat-datepicker #date_of_birth></mat-datepicker>
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('date_of_birth')" class="custom-error">{{ getErrorMessage('date_of_birth') }}</mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Education / (शिक्षा) <span class="text-danger"> * </span></mat-label>
                        <div class="form-group icon-group mb-3">
                          <select id="education" formControlName="education" class="form-control form-control-custom form-select-custom form-select">
                            <option value="">-- Select Education --</option>
                            @if(arrayLength(getEducationType) > 0) {
                              @for(data of getEducationType; track i; let i=$index) {
                                <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                              }
                            }
                          </select>
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('education')" class="custom-error">
                            {{ getErrorMessage('education') }}
                          </mat-error>
                        </div>
                      </div>
                      <div class="col- col-sm-12 col-md-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Height in Feet / (ऊंचाई फीट में)<span class="text-danger"> * </span></mat-label>
                        <div class="form-group icon-group mb-4">
                          <input matInput appNumericDecimalInput name="height" formControlName="height" id="height" placeholder="Height in feet" type="text" class="form-control custom-input" [allowDecimal]="true" [maxIntegerDigits]="3" />
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/height.svg" [alt]="projectName" width="30" height="30" class="opacity-50">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('height')" class="custom-error">
                            {{ getErrorMessage('height') }}
                          </mat-error>
                        </div>
                      </div>
                      <div class="col- col-sm-12 col-md-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Weight in
                          (KG) / (वज़न)<span class="text-danger"> * </span></mat-label>
                        <div class="form-group icon-group mb-4">
                          <input matInput appNumericDecimalInput name="weight" formControlName="weight" id="weight" placeholder="Weight in (KG)" type="text" class="form-control custom-input" [allowDecimal]="true" [maxIntegerDigits]="3" />
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/weight-icon.svg" [alt]="projectName" width="30" height="30">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('weight')" class="custom-error">
                            {{ getErrorMessage('weight') }}
                          </mat-error>
                        </div>
                      </div>
                      <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Occupation / (पेशा) <span class="text-danger"> * </span></mat-label>
                        <div class="form-group icon-group mb-3">
                          <select id="occupation" formControlName="occupation" class="form-control form-control-custom form-select-custom form-select">
                            <option value="">-- Select Occupation --</option>
                            @if(arrayLength(getOccupationType) > 0) {
                              @for(data of getOccupationType; track i; let i=$index) {
                                <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                              }
                            }
                          </select>
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('occupation')" class="custom-error">
                            {{ getErrorMessage('occupation') }}
                          </mat-error>
                        </div>
                      </div>

                      <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Nature of Business / (व्यवसाय की प्रकृति)</mat-label>
                        <div class="form-group icon-group mb-3">
                          <select id="nature_of_business" formControlName="nature_of_business" class="form-control form-control-custom form-select-custom form-select">
                            <option value="">-- Select Nature of Business --</option>
                            @if(arrayLength(getBusinessNatureType) > 0) {
                              @for(data of getBusinessNatureType; track i; let i=$index) {
                                <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                              }
                            }
                          </select>
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('nature_of_business')" class="custom-error">
                            {{ getErrorMessage('nature_of_business') }}
                          </mat-error>
                        </div>
                      </div>
                      <div class="col- col-sm-12 col-md-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Employer/Business Firm Name / (फर्म का नाम)</mat-label>
                        <div class="form-group icon-group mb-4">
                          <input matInput name="firm_name" formControlName="firm_name" id="firm_name" placeholder="Firm Name" type="text" class="form-control custom-input" />
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/building.svg" [alt]="projectName" width="20" height="20">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('firm_name')" class="custom-error">
                            {{ getErrorMessage('firm_name') }}
                          </mat-error>
                        </div>
                      </div>
                      <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Mother Name / (माता का नाम) <span class="text-danger"> * </span></mat-label>
                        <div class="form-group icon-group mb-3">
                          <input matInput name="mother_name" formControlName="mother_name" id="mother_name" placeholder="Mother Name" maxlength="100" type="text" class="form-control custom-input" />
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/woman.svg" [alt]="projectName" width="22" height="22">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('mother_name')" class="custom-error">
                            {{ getErrorMessage('mother_name') }}
                          </mat-error>
                        </div>
                      </div>
                    <div class="col- col-sm-12 col-md-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Nominee Name / (नॉमिनी नाम) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                        <input matInput name="nominee_name" formControlName="nominee_name" id="nominee_name" placeholder="Nominee Name" maxlength="100" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/user.svg" [alt]="projectName" width="18" height="16">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('nominee_name')" class="custom-error">
                          {{ getErrorMessage('nominee_name') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Nominee D.O.B / (नॉमिनी जन्म तिथि) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                        <input matInput [matDatepicker]="nominee_dob" [min]="minNomineeDobDate" [max]="maxNomineeDobDate" id="nominee_dob" formControlName="nominee_dob" placeholder="MM/DD/YYYY" type="text" class="form-control custom-input" autocomplete="off" (click)="openNomineeDatepicker()" (keydown)="datePickerInput($event)" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/date.svg" [alt]="projectName" width="20" height="20" />
                          <mat-datepicker #nominee_dob></mat-datepicker>
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('nominee_dob')" class="custom-error">{{ getErrorMessage('nominee_dob') }}</mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Nominee Relation / (नॉमिनी सबंध) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <select id="nominee_relation" formControlName="nominee_relation" class="form-control form-control-custom form-select-custom form-select">
                          <option value="">-- Select Nominee Relation --</option>
                          @if(arrayLength(getNomineeRelation) > 0) {
                            @for(data of getNomineeRelation; track i; let i=$index) {
                              <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                            }
                          }
                        </select>
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/relation.svg" [alt]="projectName" width="20" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('nominee_relation')" class="custom-error">
                          {{ getErrorMessage('nominee_relation') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Nominee Mobile Number / (नॉमिनी मोबाइल नंबर)</mat-label>
                      <div class="form-group icon-group mb-3">
                          <input matInput appNumericInput name="nominee_phone" formControlName="nominee_phone" id="nominee_phone" placeholder="Nominee Mobile Number" minlength="10" maxlength="10" type="text" class="form-control custom-input" />
                          <mat-icon matSuffix class="custom-icon icon">
                              <img src="images/icons/mobile.svg" [alt]="projectName" width="16" height="20">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('nominee_phone')" class="custom-error">
                              {{ getErrorMessage('nominee_phone') }}
                          </mat-error>
                      </div>
                  </div>
                  <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <mat-label class="fs-14 mb-1 custom-label">Nominee Email ID / (नॉमिनी ईमेल)</mat-label>
                    <div class="form-group icon-group mb-3">
                        <input matInput name="nominee_email" formControlName="nominee_email" id="nominee_email" placeholder="Nominee Email ID" maxlength="100" type="email" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/email.svg" [alt]="projectName" width="18" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('nominee_email')" class="custom-error">
                            {{ getErrorMessage('nominee_email') }}
                        </mat-error>
                    </div>
                </div>
                <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <mat-label class="fs-14 mb-1 custom-label">Own Vehicle Number / (वाहन नंबर)</mat-label>
                  <div class="form-group icon-group mb-4">
                    <input matInput appUpperCase name="vehicle_number" formControlName="vehicle_number" id="vehicle_number" placeholder="Vehicle Number" maxlength="12" type="text" class="form-control custom-input" />
                    <mat-icon matSuffix class="custom-icon icon">
                      <img src="images/icons/motorcycle.svg" [alt]="projectName" width="20" height="20">
                    </mat-icon>
                    <mat-error *ngIf="getErrorMessage('vehicle_number')" class="custom-error">
                      {{ getErrorMessage('vehicle_number') }}
                    </mat-error>
                  </div>
                </div>

                <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <mat-label class="fs-14 mb-1 custom-label">Any Medical History / (कोई भी चिकित्सा इतिहास) <span class="text-danger"> * </span> </mat-label>
                  <div class="form-group icon-group mb-3">
                    <select id="medical_history" formControlName="medical_history" class="form-control form-control-custom form-select-custom form-select">
                      <option value="">-- Select Medical History --</option>
                      @if(arrayLength(getMedicalHistoryType) > 0) {
                        @for(data of getMedicalHistoryType; track i; let i=$index) {
                          <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                        }
                      }
                    </select>
                    <mat-icon matSuffix class="custom-icon icon">
                      <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                    </mat-icon>
                    <mat-error *ngIf="getErrorMessage('medical_history')" class="custom-error">
                      {{ getErrorMessage('medical_history') }}
                    </mat-error>
                  </div>
                </div>
                <div class="col- col-sm-12 col-md-6 col-xl-4">
                  <mat-label class="fs-14 mb-1 custom-label">Previous Insurance Company / (पिछली बीमा कंपनी)</mat-label>
                  <div class="form-group icon-group mb-4">
                    <input matInput name="last_insurer_name" formControlName="last_insurer_name" id="last_insurer_name" placeholder="Previous Insurance Company" type="text" class="form-control custom-input" />
                    <mat-icon matSuffix class="custom-icon icon">
                      <img src="images/icons/building.svg" [alt]="projectName" width="20" height="20">
                    </mat-icon>
                    <mat-error *ngIf="getErrorMessage('last_insurer_name')" class="custom-error">
                      {{ getErrorMessage('last_insurer_name') }}
                    </mat-error>
                  </div>
                </div>
                <div class="col- col-sm-12 col-md-6 col-xl-4">
                  <mat-label class="fs-14 mb-1 custom-label">Previous Premium / (अंतिम प्रीमियम)</mat-label>
                  <div class="form-group icon-group mb-4">
                    <input matInput appNumericDecimalInput name="last_premium" formControlName="last_premium" id="last_premium" placeholder="Previous Premium" type="text" class="form-control custom-input" maxlength="10" [allowDecimal]="true" [maxIntegerDigits]="10" />
                    <mat-icon matSuffix class="custom-icon icon">
                      <img src="images/icons/inr.svg" [alt]="projectName" width="18" height="16">
                    </mat-icon>
                    <mat-error *ngIf="getErrorMessage('last_premium')" class="custom-error">
                      {{ getErrorMessage('last_premium') }}
                    </mat-error>
                  </div>
                </div>
                <div class="col- col-sm-12 col-md-6 col-xl-4">
                  <mat-label class="fs-14 mb-1 custom-label">Previous Sum Insured / (पिछली बीमा-राशि)</mat-label>
                  <div class="form-group icon-group mb-4">
                    <input matInput appNumericDecimalInput name="last_sum_insured" formControlName="last_sum_insured" id="last_sum_insured" placeholder="Previous Sum Insured" type="text" class="form-control custom-input" maxlength="10" [allowDecimal]="true" [maxIntegerDigits]="10" />
                    <mat-icon matSuffix class="custom-icon icon">
                      <img src="images/icons/inr.svg" [alt]="projectName" width="18" height="16">
                    </mat-icon>
                    <mat-error *ngIf="getErrorMessage('last_sum_insured')" class="custom-error">
                      {{ getErrorMessage('last_sum_insured') }}
                    </mat-error>
                  </div>
                </div>

                <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <mat-label class="fs-14 mb-1 custom-label">Previous Policy Status / (पिछली बीमा स्थिति)</mat-label>
                  <div class="form-group icon-group mb-3">
                    <select id="last_insurance_status" formControlName="last_insurance_status" class="form-control form-control-custom form-select-custom form-select">
                      <option value="">-- Select Previous Policy Status --</option>
                      @if(arrayLength(getInsuranceStatusType) > 0) {
                        @for(data of getInsuranceStatusType; track i; let i=$index) {
                          <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                        }
                      }
                    </select>
                    <mat-icon matSuffix class="custom-icon icon">
                      <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                    </mat-icon>
                    <mat-error *ngIf="getErrorMessage('last_insurance_status')" class="custom-error">
                      {{ getErrorMessage('last_insurance_status') }}
                    </mat-error>
                  </div>
                </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Select State / (राज्य) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <select id="state_code" formControlName="state_code" class="form-control form-control-custom form-select-custom form-select">
                          <option value="">-- Select state --</option>
                          @if(arrayLength(getState) > 0) {
                            @for(data of getState; track i; let i=$index) {
                              <option value="{{ data?.state_code || '' }}">{{ data?.name || '' }}</option>
                            }
                          }
                        </select>
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('state_code')" class="custom-error">
                          {{ getErrorMessage('state_code') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">City / (शहर) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="city" formControlName="city" id="city" placeholder="City" maxlength="100" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/city.svg" [alt]="projectName" width="22" height="22">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('city')" class="custom-error">
                          {{ getErrorMessage('city') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Pincode / (पिन कोड) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput appNumericInput name="pincode" formControlName="pincode" id="pincode" placeholder="Pincode" minlength="6" maxlength="6" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/file.svg" [alt]="projectName" width="18" height="18">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('pincode')" class="custom-error">
                          {{ getErrorMessage('pincode') }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col- col-sm-12">
                      <mat-label class="fs-14 mb-1 custom-label">Address / (पता) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="address" formControlName="address" id="address" placeholder="Address" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/building.svg" [alt]="projectName" width="20" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('address')" class="custom-error">
                          {{ getErrorMessage('address') }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="text-danger fs-12 fw-semi col-12 mb-2">{{ globalConstant.file.max_file_label2 }}</div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Insured Photo /  (बीमित व्यक्ति का फ़ोटो अपलोड करें)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="photo_doc" formControlName="photo_doc" id="photo_doc" (change)="onPhotoDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('photo_doc')" class="custom-error">
                          {{ getErrorMessage('photo_doc') }}
                        </mat-error>
                      </div>
                      @if(photoDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                        <div class="service-document-file">
                          @if(isPdfOrImage(photoDocFileUrl) == globalConstant.file_setting.type_pdf) {
                            <img (click)="downloadDocument(photoDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                          } @else {
                            <img (click)="downloadDocument(photoDocFileUrl)" [src]="photoDocFileUrl" alt="Preview"  class="custom-download-document" />
                          }
                        </div>
                        <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="rc_doc_remove" (click)="onPhotoDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">PAN Card Photo /  (पैन कार्ड फ़ोटो अपलोड करें)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="pan_doc" formControlName="pan_doc" id="pan_doc" (change)="onPANDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('pan_doc')" class="custom-error">
                          {{ getErrorMessage('pan_doc') }}
                        </mat-error>
                      </div>
                      @if(panDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                        <div class="service-document-file">
                          @if(isPdfOrImage(panDocFileUrl) == globalConstant.file_setting.type_pdf) {
                            <img (click)="downloadDocument(panDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                          } @else {
                            <img (click)="downloadDocument(panDocFileUrl)" [src]="panDocFileUrl" alt="Preview"  class="custom-download-document" />
                          }
                        </div>
                        <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="rc_doc_remove" (click)="onPANDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Aadhar Card Photo / (आधार कार्ड फ़ोटो अपलोड करें)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="aadhar_doc" formControlName="aadhar_doc" id="aadhar_doc" (change)="onAadhaarDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('aadhar_doc')" class="custom-error">
                          {{ getErrorMessage('aadhar_doc') }}
                        </mat-error>
                      </div>
                      @if(aadhaarDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                          <div class="service-document-file">
                            @if(isPdfOrImage(aadhaarDocFileUrl) == globalConstant.file_setting.type_pdf) {
                              <img (click)="downloadDocument(aadhaarDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                            } @else {
                              <img (click)="downloadDocument(aadhaarDocFileUrl)" [src]="aadhaarDocFileUrl" alt="Preview"  class="custom-download-document" />
                            }
                          </div>
                          <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="last_insurance_doc_remove" (click)="onAadhaarDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Name Printed Cheque /  (नाम मुद्रित चेक अपलोड करें)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="cheque_doc" formControlName="cheque_doc" id="cheque_doc" (change)="onChequeDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('cheque_doc')" class="custom-error">
                          {{ getErrorMessage('cheque_doc') }}
                        </mat-error>
                      </div>
                      @if(chequeDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                        <div class="service-document-file">
                          @if(isPdfOrImage(chequeDocFileUrl) == globalConstant.file_setting.type_pdf) {
                            <img (click)="downloadDocument(chequeDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                          } @else {
                            <img (click)="downloadDocument(chequeDocFileUrl)" [src]="chequeDocFileUrl" alt="Preview"  class="custom-download-document" />
                          }
                        </div>
                        <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="rc_doc_remove" (click)="onChequeDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">6 Month Banking with Salary Credits</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="bank_statement_doc" formControlName="bank_statement_doc" id="bank_statement_doc" (change)="onBankStatementDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('bank_statement_doc')" class="custom-error">
                          {{ getErrorMessage('bank_statement_doc') }}
                        </mat-error>
                      </div>
                      @if(bankStatementDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                        <div class="service-document-file">
                          @if(isPdfOrImage(bankStatementDocFileUrl) == globalConstant.file_setting.type_pdf) {
                            <img (click)="downloadDocument(bankStatementDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                          } @else {
                            <img (click)="downloadDocument(bankStatementDocFileUrl)" [src]="bankStatementDocFileUrl" alt="Preview"  class="custom-download-document" />
                          }
                        </div>
                        <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="rc_doc_remove" (click)="onBankStatementDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Appointment letter or latest Salary Slips</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="salary_slip_doc" formControlName="salary_slip_doc" id="salary_slip_doc" (change)="onSalarySlipDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('salary_slip_doc')" class="custom-error">
                          {{ getErrorMessage('salary_slip_doc') }}
                        </mat-error>
                      </div>
                      @if(salarySlipDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                        <div class="service-document-file">
                          @if(isPdfOrImage(salarySlipDocFileUrl) == globalConstant.file_setting.type_pdf) {
                            <img (click)="downloadDocument(salarySlipDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                          } @else {
                            <img (click)="downloadDocument(salarySlipDocFileUrl)" [src]="salarySlipDocFileUrl" alt="Preview"  class="custom-download-document" />
                          }
                        </div>
                        <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="rc_doc_remove" (click)="onSalarySlipDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Nominee ID proof /  (नामांकित का पहचान प्रमाण अपलोड करें)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="nominee_id_proof_doc" formControlName="nominee_id_proof_doc" id="nominee_id_proof_doc" (change)="onNomineeIdDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('nominee_id_proof_doc')" class="custom-error">
                          {{ getErrorMessage('nominee_id_proof_doc') }}
                        </mat-error>
                      </div>
                      @if(nomineeIdDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                        <div class="service-document-file">
                          @if(isPdfOrImage(nomineeIdDocFileUrl) == globalConstant.file_setting.type_pdf) {
                            <img (click)="downloadDocument(nomineeIdDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                          } @else {
                            <img (click)="downloadDocument(nomineeIdDocFileUrl)" [src]="nomineeIdDocFileUrl" alt="Preview"  class="custom-download-document" />
                          }
                        </div>
                        <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="rc_doc_remove" (click)="onNomineeIdDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4 col-md-4">
                      <mat-label class="mb-1 font_doc">Other Documents 1 /  (अन्य दस्तावेज 1)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="other_doc1" formControlName="other_doc1" id="other_doc1" (change)="onOtherOneDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('other_doc1')" class="custom-error">
                          {{ getErrorMessage('other_doc1') }}
                        </mat-error>
                      </div>
                      @if(otherOneDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                          <div class="service-document-file">
                            @if(isPdfOrImage(otherOneDocFileUrl) == globalConstant.file_setting.type_pdf) {
                              <img (click)="downloadDocument(otherOneDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                            } @else {
                              <img (click)="downloadDocument(otherOneDocFileUrl)" [src]="otherOneDocFileUrl" alt="Preview"  class="custom-download-document" />
                            }
                          </div>
                          <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="other_doc1_remove" (click)="onOtherOneDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Other Documents 2 /  (अन्य दस्तावेज 2)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="other_doc2" formControlName="other_doc2" id="other_doc2" (change)="onOtherTwoDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('other_doc2')" class="custom-error">
                          {{ getErrorMessage('other_doc2') }}
                        </mat-error>
                      </div>
                      @if(otherTwoDocFileUrl) {
                              <div class="doc_img-privews preview-bx mb-3">
                                <div class="service-document-file">
                                  @if(isPdfOrImage(otherTwoDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                    <img (click)="downloadDocument(otherTwoDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                                  } @else {
                                    <img (click)="downloadDocument(otherTwoDocFileUrl)" [src]="otherTwoDocFileUrl" alt="Preview"  class="custom-download-document" />
                                  }
                                </div>
                                <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="other_doc2_remove" (click)="onOtherTwoDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                              </div>
                            }
                    </div>
                    <div class="col- col-md-12">
                      <div class="mb-3 form-check">
                        <input id="accept_t_c" formControlName="accept_t_c" value="yes" type="checkbox" class="form-check-input" />
                        <label for="accept_t_c" class="form-check-label">
                          I accept all <a [routerLink]="[globalConstant.base_url, routesPages.TERMS_AND_CONDITIONS || '']" target="_blank" class="text-success">Terms and Conditions</a>
                        </label>
                        <mat-error *ngIf="getErrorMessage('accept_t_c')" class="custom-error">
                            {{ getErrorMessage('accept_t_c') }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group text-center">
                        <a [routerLink]="[globalConstant.base_url, routesTermLifeInsurance.OFFLINE_STEP_ONE || '', uuId || '']" class="btn btn-dark rounded-5 btn-lg px-5 me-2">
                          BACK
                        </a>
                        <button type="submit" class="btn btn-success rounded-5 btn-lg px-5" [disabled]="isLoading">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>