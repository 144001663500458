import { INSURER_ID } from "../constants/insurer/ids.constants";

export const BusinessNatureUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'retail', label: 'Retail' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'service', label: 'Service' },
    { value: 'technology', label: 'Technology' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'construction', label: 'Construction' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'other', label: 'Other' }
  ]
};

export type BusinessNatureUtilityType = {
  [key: number]: { value: string; label: string }[];
};
