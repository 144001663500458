import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineTwoWheelerInsurancePersonalDetailsService {

  constructor(
    private apiService: ApiService
  ) { }

  getTitle(insurerTitleData: any) {
    return this.apiService.request<any>('POST_AUTH', 'title', insurerTitleData, false);
  }

  getGender(insurerGenderData: any) {
    return this.apiService.request<any>('POST_AUTH', 'gender', insurerGenderData, false);
  }

  getState() {
    return this.apiService.request<any>('GET', 'states');
  }

  getDocumentType(insurerDocumentData: any) {
    return this.apiService.request<any>('POST_AUTH', 'document-type', insurerDocumentData, false);
  }

  getNomineeRelation(insurerNomineeRelationData: any) {
    return this.apiService.request<any>('POST_AUTH', 'nominee-relation', insurerNomineeRelationData, false);
  }

  getPreviousInsurerList(previousInsurerList: any) {
    return this.apiService.request<any>('POST_AUTH', 'previous-insurer', previousInsurerList, false);
  }

  getVehicleDetail(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/vehicle-detail-fetch', formData, false);
  }

  getSelectInsurerDetail(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/get-quick-quotation', formData, false);
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/update-proposal-detail', formData);
  }

}