import { Injectable } from '@angular/core';
import { ApiService } from '../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineMotorInsurancePolicyService {

  constructor(
    private apiService: ApiService
  ) { }

  getPolicy(page_name: string, formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'offline-policy/motor-insurance-policy/' + page_name, formData);
  }
}
