import { Component, OnInit } from '@angular/core';
import { filter, Observable, Subscription, take, tap } from 'rxjs';
import { RouterModule } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MatExpansionModule } from '@angular/material/expansion';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_PAGES } from '../../common/constants/routes/pages/pages.constants';
import { HomeService } from './home.service';
import { LoaderService } from '../../common/loader/loader.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { StoreUtilitiesService } from '../../common/utilities/store-utilities.service';
import { StoreHome, StoreStateHome } from '../../store/reducers/home.reducers';
import { selectStoreHome } from '../../store/selectors/home.selectors';
import { storeSetHome, storeResetHome } from '../../store/actions/home.actions';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterModule,
    MatExpansionModule
  ],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;

  title: string = '';
  subTitle: string = '';
  serviceList: any[] = [];
  achievement: any[] = [];
  insuredProcess: any = {};
  security: any[] = [];
  workProcess: any = {};
  consultation: any = {};
  ourPartners: any = {};
  dataSecurity: any = {};
  groupBrands: any = {};
  support: any = {};
  faqs: any = {};

  getStoreHome$: Observable<StoreHome | null>;

  private apiHomeDataSubscription: Subscription | undefined;


  constructor(
    private store: Store<{ selectStoreHome: StoreStateHome }>,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private storeUtilitiesService: StoreUtilitiesService,
    private homeService: HomeService
  ) {
    this.getStoreHome$ = this.store.pipe(select(selectStoreHome));
  }

  ngOnInit(): void {
    this.loaderService.show();
    this.storeUtilitiesService.processStore(
      this.getStoreHome$,
      () => this.apiGetPageData(),
      (data) => this.setStoreData(data)
    );
    this.loaderService.hide();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  getOffLineServiceURL(id: number, step: string): string[] {
    return this.utilitiesService.getOffLineServiceURL(id, step);
  }

  async apiGetPageData(): Promise<void> {
    this.apiHomeDataSubscription = this.homeService.getPageData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            const getData = response?.data;
            this.store.dispatch(storeSetHome({ storeData: getData }));
            this.setStoreData(getData);
          } else {
            this.store.dispatch(storeResetHome());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  setStoreData(data: StoreHome | null) {
    if (!data) return;

    this.title = data['title'] || '';
    this.subTitle = data['sub_title'] || '';
    this.serviceList = data['service_list'] || [];
    this.achievement = data['achievement'] || [];
    this.insuredProcess = data['insured_process'] || {};
    this.security = data['security'] || [];
    this.workProcess = data['work_process'] || {};
    this.consultation = data['consultation'] || {};
    this.ourPartners = data['our_partners'] || {};
    this.dataSecurity = data['data_security'] || {};
    this.groupBrands = data['group_brands'] || {};
    this.support = data['support'] || {};
    this.faqs = data['faqs'] || {};
  }


  ngOnDestroy(): void {
    if (this.apiHomeDataSubscription) {
      this.apiHomeDataSubscription.unsubscribe();
    }
  }

}
