import { INSURER_ID } from "../constants/insurer/ids.constants";

export const AgentTypeUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'dealers_subdealers', label: 'Dealers/Subdealers' },
    { value: 'agents', label: 'Agents' },
    { value: 'insurance_comp_employess', label: 'Insurance Comp Employess' },
    { value: 'retail_shops', label: 'Retail Shops(Telecom,Groceries,Xerox,Petti ETC...,)' },
    { value: 'small_finance_companies', label: 'Small Finance/NBFC/Chit Companies' },
    { value: 'e_commerce', label: 'E COMMERCE' },
    { value: 'auto_shops', label: 'Auto Shops(Workshops,Tyre,Batteries,Accessories ETC..,)' },
    { value: 'franchise_owners', label: 'Franchise Owners' },
    { value: 'chief_advisor', label: 'Chief Advisor' },
    { value: 'e_mitra', label: 'eMitra' },
    { value: 'bank_bc', label: 'Bank BC' },
    { value: 'csc_center', label: 'CSC Center' },
    { value: 'others', label: 'Others' }
  ]
};

export type AgentTypeUtilityType = {
  [key: number]: { value: string; label: string }[];
};
