import { INSURER_ID } from "../constants/insurer/ids.constants";

export const HealthPlanTypeUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'individual', label: 'Individual' },
    { value: 'family', label: 'Family' }
  ]
};

export type HealthPlanTypeUtilityType = {
  [key: number]: { value: string; label: string }[];
};
