export const INSURER_CONSTANT = {
  plan_type: {
    comprehensive: 'comprehensive',
    third_party: 'third_party',
    standalone_own_damage: 'standalone_own_damage'
  }
};

export const INSURER_ID_MAP: Record<string, number> = {
  /* WEB_INSURANCE: 1, */
  GO_DIGIT_MOTOR: 2
};