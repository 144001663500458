import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineTwoWheelerInsurancePersonalDetailsService {

  constructor(
    private apiService: ApiService
  ) { }

  getVehicleDetail(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/vehicle-detail-fetch', formData, false);
  }

  getSelectInsurerDetail(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/get-quick-quotation', formData, false);
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/update-proposal-detail', formData);
  }

  getState(formData: any) {
    return this.apiService.request<any>('POST', 'states', formData, false);
  }

  getPreviousInsurer(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'previous-insurer', formData, false);
  }

}