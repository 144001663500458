<section class="sec sec-checkout pt-3 pb-5">
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-8">
                <div class="p-3 bg-white rounded-2 mb-3 fw-bold border border-success fs-18">Almost there! Please review your information before payment</div>
                <div class="border bg-white p-3 rounded-2 mb-3">
                    <div class="cardHead d-flex justify-content-between mb-4">
                        <div class="card_reviewhead">
                            <p class="text-primary fw-semi fs-18 mb-0">Vehicle Details</p>
                        </div>
                        <div class="edit">
                            <a href="javascript:void(0)" class="text-danger fw-semi"><i
                                    class="bx bx-edit align-top"></i> Edit</a>
                        </div>
                    </div>
                    <div class="insureMemberCard mb-3">
                        <ul class="imc-field">
                            <li>
                                <div class="head">Vehicle Owner</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Customer Type</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Vehicle Model & Varient</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Registration Number</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Registration Date</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Engine Number</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Chassis Number</div>
                                <div class="value"></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="border bg-white p-3 rounded-2 mb-3">
                    <div class="cardHead d-flex justify-content-between mb-4">
                        <div class="card_reviewhead">
                            <p class="text-primary fw-semi fs-18 mb-0">Other Details</p>
                        </div>
                        <div class="edit">
                            <a href="javascript:void(0)" class="text-danger fw-semi"><i
                                    class="bx bx-edit align-top"></i> Edit</a>
                        </div>
                    </div>
                    <div class="insureMemberCard mb-3">
                        <ul class="imc-field">
                            <li>
                                <div class="head">Name</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Contact Number</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Email Address</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Address</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Nominee Name</div>
                                <div class="value"></div>
                            </li>
                            <li>
                                <div class="head">Nominee Relation</div>
                                <div class="value"></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0">
                <div class="border bg-white p-3 rounded-2 possticky minhight230">
                    @if(objectLength(selectInsurerData) > 0) {
                        <div class="card-top my-3">
                            <div class="fnc-img rounded-2 border p-2">
                                 <img [src]="selectInsurerData?.['insurer_logo'] ?? 'images/default/default-insurance.png'"
                                    alt="{{ selectInsurerData?.['insurer_logo'] }}" width="80" height="53" />
                            </div>
                            <div class="fncName">
                                <p class="plantype mb-0">Comprehensive</p>
                                <p class="planvalue text-muted fs-14 mb-0">Vehicle value (IDV):
                                    <span class="dv-value fw-semi">{{ showCurrency(selectInsurerData?.currency_symbol, selectInsurerData?.idv?.default_idv || '') }}</span>
                                </p>

                            </div>
                        </div>
                        <div class="cartamoutinfo">
                            <ul>
                                <li class="last">
                                    <div class="head fw-semi">Total Premium</div>
                                    <div class="value">
                                        {{ showCurrency(selectInsurerData?.currency_symbol, selectInsurerData?.net_premium || '') }}
                                    </div>
                                </li>
                            </ul>
                            <div class="button5 mt-3">
                                <button type="button" (click)="paymentLinkButton()" class="btn btn-success w-100 btn-lg">Proceed to payment</button>
                            </div>
                        </div>
                    } @else {
                        <div class="col-md-12 col-lg-12 text-center">
                            Data not found.
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</section>