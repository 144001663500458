export const ONLINE_INSURER_CONSTANT = {
  previous_policy_status: [
    { key: 'expired', value: 'Expired' },
    { key: 'not_expired', value: 'Not expired' },
    { key: 'expired_within_90_days', value: 'Expired within 90 days' },
    { key: 'expired_more_than_90_days', value: 'Expired more than 90 days' },
  ],
  previous_policy_type: [
    { key: 'comprehensive', value: 'Comprehensive' },
    { key: 'third_party', value: 'Third party' },
    { key: 'standalone_own_damage', value: 'Standalone own damage' },
  ]
};
