import { Injectable } from '@angular/core';
import { ApiService } from '../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {

  constructor(
    private apiService: ApiService
  ) { }

  getServiceData() {
    return this.apiService.request<any>('GET', 'services');
  }

  getOfflineCommission() {
    return this.apiService.request<any>('GET', 'offline-policy/policy-commission-total');
  }
}
