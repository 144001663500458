import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { OFFLINE_SERVICES_ID } from '../../../common/constants/services/offline-services-url.constants';
import { INSURER_ID } from '../../../common/constants/insurer/ids.constants';
import { ROUTES_PAGES } from '../../../common/constants/routes/pages/pages.constants';
import { ROUTES_OFFLINE_TERM_LIFE_INSURANCE } from '../../../common/constants/routes/offline-services/offline-term-life-insurance.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { NumericInputDirective } from '../../../common/directives/numeric-input.directive';
import { handleFileSelection, handleReadFile } from '../../../common/utilities/form-rules';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { CommonUtilityService } from '../../../common/utility/common-utility.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { DownloadService } from '../../../common/utilities/download.service';
import { LoaderService } from '../../../common/loader/loader.service';
import { DialogService } from '../../../common/dialog/dialog.service';
import { OfflineTermLifeInsuranceStepTwoService } from './offline-term-life-insurance-step-two.service';
import { getFormValidators } from './form-utils';
import { NumericDecimalInputDirective } from '../../../common/directives/numeric-decimal-input.directive';

@Component({
  selector: 'app-offline-term-life-insurance-step-two',
  standalone: true,
  imports: [
    NgIf,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NumericInputDirective,
    NumericDecimalInputDirective
  ],
  templateUrl: './offline-term-life-insurance-step-two.component.html'
})
export class OfflineTermLifeInsuranceStepTwoComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  offlineServicesID = OFFLINE_SERVICES_ID;
  routesPages = ROUTES_PAGES;
  routesTermLifeInsurance = ROUTES_OFFLINE_TERM_LIFE_INSURANCE;
  insurerId = INSURER_ID.WEB_INSURANCE;

  projectName = this.globalConstant?.projectName;

  isLoading = false;
  uuId: string | null = null;
  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};
  getNomineeRelation: any[] = [];
  getEducationType: any[] = [];
  getOccupationType: any[] = [];
  getBusinessNatureType: any[] = [];
  getInsuranceStatusType: any[] = [];
  getMedicalHistoryType: any[] = [];
  getState: any[] = [];

  photoDocFile: File | null = null;
  panDocFile: File | null = null;
  aadhaarDocFile: File | null = null;
  chequeDocFile: File | null = null;
  bankStatementDocFile: File | null = null;
  salarySlipDocFile: File | null = null;
  nomineeIdDocFile: File | null = null;
  otherOneDocFile: File | null = null;
  otherTwoDocFile: File | null = null;

  photoDocFileUrl: string = '';
  panDocFileUrl: string = '';
  aadhaarDocFileUrl: string = '';
  chequeDocFileUrl: string = '';
  bankStatementDocFileUrl: string = '';
  salarySlipDocFileUrl: string = '';
  nomineeIdDocFileUrl: string = '';
  otherOneDocFileUrl: string = '';
  otherTwoDocFileUrl: string = '';

  private apiGetFormDataSubscription: Subscription | undefined;
  private apiGetStateSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private momentUtilitiesService: MomentUtilitiesService,
    private utilitiesService: UtilitiesService,
    private commonUtilityService: CommonUtilityService,
    private formUtilitiesService: FormUtilitiesService,
    private downloadService: DownloadService,
    private offlineTermLifeInsuranceStepTwoService: OfflineTermLifeInsuranceStepTwoService
  ) {
    this.customForm = getFormValidators();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    if (this.uuId != null && this.uuId != '') {
      this.apiGetFormData({ 'uuid': this.uuId });
    }
    this.getNomineeRelationData();
    this.apiGetEducationType();
    this.apiGetOccupationType();
    this.apiGetBusinessNatureType();
    this.apiGetInsuranceStatusType();
    this.apiGetMedicalHistoryType();
    this.apiGetState();
  }

  @ViewChild('date_of_birth', { static: false }) date_of_birth_datepicker!: MatDatepicker<Date>;
  @ViewChild('nominee_dob', { static: false }) nominee_dob_datepicker!: MatDatepicker<Date>;
  maxDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.minDobYear);
  minDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);
  maxNomineeDobDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
  minNomineeDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);

  openDobDatepicker() {
    this.date_of_birth_datepicker.open();
  }

  openNomineeDatepicker() {
    this.nominee_dob_datepicker.open();
  }

  isPdfOrImage(url: string): string {
    return this.utilitiesService.isPdfOrImage(url);
  }

  datePickerInput(event: KeyboardEvent): void {
    this.utilitiesService.datePickerInput(event);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  apiGetFormData(params: object) {
    this.apiGetFormDataSubscription = this.offlineTermLifeInsuranceStepTwoService.getFormData(params).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            let orderDetail = response?.data;
            this.customForm.patchValue({
              pan_card: orderDetail?.pan_card || '',
              date_of_birth: orderDetail?.date_of_birth || '',
              nominee_name: orderDetail?.nominee_name || '',
              nominee_dob: orderDetail?.nominee_dob || '',
              nominee_relation: orderDetail?.nominee_relation || '',
              education: orderDetail?.education || '',
              height: orderDetail?.height || '',
              weight: orderDetail?.weight || '',
              occupation: orderDetail?.occupation || '',
              nature_of_business: orderDetail?.nature_of_business || '',
              firm_name: orderDetail?.firm_name || '',
              mother_name: orderDetail?.mother_name || '',
              nominee_phone: orderDetail?.nominee_phone || '',
              nominee_email: orderDetail?.nominee_email || '',
              vehicle_number: orderDetail?.vehicle_number || '',
              medical_history: orderDetail?.medical_history || '',
              last_insurer_name: orderDetail?.last_insurer_name || '',
              last_premium: orderDetail?.last_premium || '',
              last_sum_insured: orderDetail?.last_sum_insured || '',
              last_insurance_status: orderDetail?.last_insurance_status || '',
              state_code: orderDetail?.state_code || '',
              city: orderDetail?.city || '',
              pincode: orderDetail?.pincode || '',
              address: orderDetail?.address || '',
            });

            if (orderDetail?.photo_doc && orderDetail?.photo_doc != '') {
              this.photoDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.photo_doc;
              this.customForm.get('photo_doc')?.clearValidators();
              this.customForm.get('photo_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.pan_doc && orderDetail?.pan_doc != '') {
              this.panDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.pan_doc;
              this.customForm.get('pan_doc')?.clearValidators();
              this.customForm.get('pan_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.aadhar_doc && orderDetail?.aadhar_doc != '') {
              this.aadhaarDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.aadhar_doc;
              this.customForm.get('aadhar_doc')?.clearValidators();
              this.customForm.get('aadhar_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.cheque_doc && orderDetail?.cheque_doc != '') {
              this.chequeDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.cheque_doc;
              this.customForm.get('cheque_doc')?.clearValidators();
              this.customForm.get('cheque_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.bank_statement_doc && orderDetail?.bank_statement_doc != '') {
              this.bankStatementDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.bank_statement_doc;
              this.customForm.get('bank_statement_doc')?.clearValidators();
              this.customForm.get('bank_statement_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.salary_slip_doc && orderDetail?.salary_slip_doc != '') {
              this.salarySlipDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.salary_slip_doc;
              this.customForm.get('salary_slip_doc')?.clearValidators();
              this.customForm.get('salary_slip_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.nominee_id_proof_doc && orderDetail?.nominee_id_proof_doc != '') {
              this.nomineeIdDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.nominee_id_proof_doc;
              this.customForm.get('nominee_id_proof_doc')?.clearValidators();
              this.customForm.get('nominee_id_proof_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.other_doc1 && orderDetail?.other_doc1 != '') {
              this.otherOneDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.other_doc1;
              this.customForm.get('other_doc1')?.clearValidators();
              this.customForm.get('other_doc1')?.updateValueAndValidity();
            }

            if (orderDetail?.other_doc2 && orderDetail?.other_doc2 != '') {
              this.otherTwoDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.other_doc2;
              this.customForm.get('other_doc2')?.clearValidators();
              this.customForm.get('other_doc2')?.updateValueAndValidity();
            }
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
          if (error.status === API_RESPONSE?.status_codes?.bad_request) {
            this.router.navigate(this.utilitiesService.getOffLineServiceURL(this.offlineServicesID.BIKE_INSURANCE, 'step_1'));
          }
        }
      )
    );
  }

  getNomineeRelationData() {
    let getNomineeRelationUtility = this.commonUtilityService.getUtility('nominee_relation_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getNomineeRelationUtility && this.utilitiesService.arrayLength(getNomineeRelationUtility) > 0) {
      this.getNomineeRelation = getNomineeRelationUtility;
    }
  }

  apiGetEducationType() {
    let getEducationUtility = this.commonUtilityService.getUtility('education_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getEducationUtility && this.utilitiesService.arrayLength(getEducationUtility) > 0) {
      this.getEducationType = getEducationUtility;
    }
  }

  apiGetOccupationType() {
    let getOccupationUtility = this.commonUtilityService.getUtility('occupation_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getOccupationUtility && this.utilitiesService.arrayLength(getOccupationUtility) > 0) {
      this.getOccupationType = getOccupationUtility;
    }
  }

  apiGetBusinessNatureType() {
    let getBusinessNatureUtility = this.commonUtilityService.getUtility('business_nature_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getBusinessNatureUtility && this.utilitiesService.arrayLength(getBusinessNatureUtility) > 0) {
      this.getBusinessNatureType = getBusinessNatureUtility;
    }
  }

  apiGetInsuranceStatusType() {
    let getInsuranceStatusUtility = this.commonUtilityService.getUtility('insurance_status_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getInsuranceStatusUtility && this.utilitiesService.arrayLength(getInsuranceStatusUtility) > 0) {
      this.getInsuranceStatusType = getInsuranceStatusUtility;
    }
  }

  apiGetMedicalHistoryType() {
    let getMedicalHistoryUtility = this.commonUtilityService.getUtility('medical_history_utility', this.utilitiesService.insurerID(this.insurerId));
    if (getMedicalHistoryUtility && this.utilitiesService.arrayLength(getMedicalHistoryUtility) > 0) {
      this.getMedicalHistoryType = getMedicalHistoryUtility;
    }
  }

  apiGetState() {
    this.apiGetStateSubscription = this.offlineTermLifeInsuranceStepTwoService.getState({
      'insurer_id': this.utilitiesService.insurerID(this.insurerId)
    }).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getState = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  onPhotoDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('photo_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.photoDocFile = file;
      handleReadFile(this.photoDocFile, (result: string) => {
        this.photoDocFileUrl = result;
      });
    }
  }

  onPANDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('pan_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.panDocFile = file;
      handleReadFile(this.panDocFile, (result: string) => {
        this.panDocFileUrl = result;
      });
    }
  }

  onAadhaarDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('aadhar_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.aadhaarDocFile = file;
      handleReadFile(this.aadhaarDocFile, (result: string) => {
        this.aadhaarDocFileUrl = result;
      });
    }
  }

  onChequeDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('cheque_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.chequeDocFile = file;
      handleReadFile(this.chequeDocFile, (result: string) => {
        this.chequeDocFileUrl = result;
      });
    }
  }

  onBankStatementDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('bank_statement_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.bankStatementDocFile = file;
      handleReadFile(this.bankStatementDocFile, (result: string) => {
        this.bankStatementDocFileUrl = result;
      });
    }
  }

  onSalarySlipDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('salary_slip_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.salarySlipDocFile = file;
      handleReadFile(this.salarySlipDocFile, (result: string) => {
        this.salarySlipDocFileUrl = result;
      });
    }
  }

  onNomineeIdDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('nominee_id_proof_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.nomineeIdDocFile = file;
      handleReadFile(this.nomineeIdDocFile, (result: string) => {
        this.nomineeIdDocFileUrl = result;
      });
    }
  }

  onOtherOneDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('other_doc1')?.setErrors(error);
      return;
    }
    if (file) {
      this.otherOneDocFile = file;
      handleReadFile(this.otherOneDocFile, (result: string) => {
        this.otherOneDocFileUrl = result;
      });
    }
  }

  onOtherTwoDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('other_doc2')?.setErrors(error);
      return;
    }
    if (file) {
      this.otherTwoDocFile = file;
      handleReadFile(this.otherTwoDocFile, (result: string) => {
        this.otherTwoDocFileUrl = result;
      });
    }
  }

  onPhotoDocFileRemove(): void {
    this.photoDocFile = null;
    this.photoDocFileUrl = '';
    this.customForm.get('photo_doc')?.reset();
    this.customForm.get('photo_doc')?.setValidators([Validators.required]);
    this.customForm.get('photo_doc')?.updateValueAndValidity();
  }

  onPANDocFileRemove(): void {
    this.panDocFile = null;
    this.panDocFileUrl = '';
    this.customForm.get('pan_doc')?.reset();
    this.customForm.get('pan_doc')?.setValidators([Validators.required]);
    this.customForm.get('pan_doc')?.updateValueAndValidity();
  }

  onAadhaarDocFileRemove(): void {
    this.aadhaarDocFile = null;
    this.aadhaarDocFileUrl = '';
    this.customForm.get('aadhar_doc')?.reset();
  }

  onChequeDocFileRemove(): void {
    this.chequeDocFile = null;
    this.chequeDocFileUrl = '';
    this.customForm.get('cheque_doc')?.reset();
  }

  onBankStatementDocFileRemove(): void {
    this.bankStatementDocFile = null;
    this.bankStatementDocFileUrl = '';
    this.customForm.get('bank_statement_doc')?.reset();
  }

  onSalarySlipDocFileRemove(): void {
    this.salarySlipDocFile = null;
    this.salarySlipDocFileUrl = '';
    this.customForm.get('salary_slip_doc')?.reset();
  }

  onNomineeIdDocFileRemove(): void {
    this.nomineeIdDocFile = null;
    this.nomineeIdDocFileUrl = '';
    this.customForm.get('nominee_id_proof_doc')?.reset();
  }

  onOtherOneDocFileRemove(): void {
    this.otherOneDocFile = null;
    this.otherOneDocFileUrl = '';
    this.customForm.get('other_doc1')?.reset();
  }

  onOtherTwoDocFileRemove(): void {
    this.otherTwoDocFile = null;
    this.otherTwoDocFileUrl = '';
    this.customForm.get('other_doc2')?.reset();
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();

      let accept_t_c = 'no';
      if (this.customForm.value.accept_t_c) {
        accept_t_c = 'yes';
      }
      let date_of_birth = '';
      let nominee_dob = '';
      if (this.customForm.value.date_of_birth) {
        date_of_birth = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.date_of_birth);
      }
      if (this.customForm.value.nominee_dob) {
        nominee_dob = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.nominee_dob);
      }
      const data = {
        uuid: this.uuId,
        service_step: this.customForm.value.service_step,
        pan_card: this.customForm.value.pan_card,
        date_of_birth: date_of_birth,
        nominee_name: this.customForm.value.nominee_name,
        nominee_dob: nominee_dob,
        nominee_relation: this.customForm.value.nominee_relation,
        education: this.customForm.value.education,
        height: this.customForm.value.height,
        weight: this.customForm.value.weight,
        occupation: this.customForm.value.occupation,
        nature_of_business: this.customForm.value.nature_of_business,
        firm_name: this.customForm.value.firm_name,
        mother_name: this.customForm.value.mother_name,
        nominee_phone: this.customForm.value.nominee_phone,
        nominee_email: this.customForm.value.nominee_email,
        vehicle_number: this.customForm.value.vehicle_number,
        medical_history: this.customForm.value.medical_history,
        last_insurer_name: this.customForm.value.last_insurer_name,
        last_sum_insured: this.customForm.value.last_sum_insured,
        last_premium: this.customForm.value.last_premium,
        last_insurance_status: this.customForm.value.last_insurance_status,
        state_code: this.customForm.value.state_code,
        city: this.customForm.value.city,
        pincode: this.customForm.value.pincode,
        address: this.customForm.value.address,
        accept_t_c: accept_t_c
      };
      let formData = this.formUtilitiesService.toFormData(data);
      if (this.photoDocFile) {
        formData.append('photo_doc', this.photoDocFile, this.photoDocFile.name);
      }
      if (this.panDocFile) {
        formData.append('pan_doc', this.panDocFile, this.panDocFile.name);
      }
      if (this.aadhaarDocFile) {
        formData.append('aadhar_doc', this.aadhaarDocFile, this.aadhaarDocFile.name);
      }
      if (this.chequeDocFile) {
        formData.append('cheque_doc', this.chequeDocFile, this.chequeDocFile.name);
      }
      if (this.bankStatementDocFile) {
        formData.append('bank_statement_doc', this.bankStatementDocFile, this.bankStatementDocFile.name);
      }
      if (this.salarySlipDocFile) {
        formData.append('salary_slip_doc', this.salarySlipDocFile, this.salarySlipDocFile.name);
      }
      if (this.nomineeIdDocFile) {
        formData.append('nominee_id_proof_doc', this.nomineeIdDocFile, this.nomineeIdDocFile.name);
      }
      if (this.otherOneDocFile) {
        formData.append('other_doc1', this.otherOneDocFile, this.otherOneDocFile.name);
      }
      if (this.otherTwoDocFile) {
        formData.append('other_doc2', this.otherTwoDocFile, this.otherTwoDocFile.name);
      }
      this.apiSubmitFormDataSubscription = this.offlineTermLifeInsuranceStepTwoService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            let orderDetail = response?.data;
            const dialogRef = this.dialogService.openDialog({ content: response?.message });
            dialogRef.afterClosed().subscribe(result => {
              if (orderDetail?.uuid) {
                this.router.navigate(this.utilitiesService.getOffLineServiceOrderDetailURL(this.offlineServicesID.TERM_LIFE_INSURANCE, orderDetail?.uuid));
              } else {
                this.router.navigate([this.globalConstant.base_url]);
              }
            });
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetFormDataSubscription) {
      this.apiGetFormDataSubscription.unsubscribe();
    }
    if (this.apiGetStateSubscription) {
      this.apiGetStateSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }

}
