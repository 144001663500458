import { Action, createReducer, on } from '@ngrx/store';
import { storeSetLatestPospBlog, storeResetLatestPospBlog } from '../../../actions/blog/posp-blog/latest-posp-blog.actions';

export interface StoreLatestPospBlog { [key: string]: any; }

export interface StoreStateLatestPospBlog {
  storeData: StoreLatestPospBlog | null;
}

export const initialState: StoreStateLatestPospBlog = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetLatestPospBlog, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetLatestPospBlog, state => ({ ...state, storeData: null }))
);

export function reducerLatestPospBlog(state: StoreStateLatestPospBlog | undefined, action: Action) { return _storeReducer(state, action); }