import { INSURER_ID } from "../constants/insurer/ids.constants";

export const PolicyStatusUtility = {
  [INSURER_ID.GO_DIGIT_MOTOR]: [
    { value: 'expired', label: 'Expired' },
    { value: 'not_expired', label: 'Not Expired' },
    { value: 'expired_within_90_days', label: 'Expired within 90 days' },
    { value: 'expired_more_than_90_days', label: 'Expired more than 90 days' }
  ]
};

export type PolicyStatusUtilityType = {
  [key: number]: { value: string; label: string }[];
};
