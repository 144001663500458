import { Injectable } from '@angular/core';
import { GenderUtility, GenderUtilityType } from './gender-utility';
import { TitleUtility, TitleUtilityType } from './title-utility';
import { DocumentTypeUtility, DocumentTypeUtilityType } from './document-type-utility';
import { PolicyHolderTypeUtility, PolicyHolderTypeUtilityType } from './policy-holder-type-utility';
import { PolicyTypeUtility, PolicyTypeUtilityType } from './policy-type-utility';
import { PolicyStatusUtility, PolicyStatusUtilityType } from './policy-status-utility';
import { NomineeRelationUtility, NomineeRelationUtilityType } from './nominee-relation-utility';
import { HealthPlanTypeUtility, HealthPlanTypeUtilityType } from './health-plan-type-utility';
import { EducationUtility, EducationUtilityType } from './education-utility';
import { AgentTypeUtility, AgentTypeUtilityType } from './agent-type-utility';
import { OccupationUtility, OccupationUtilityType } from './occupation-utility';
import { BusinessNatureUtility, BusinessNatureUtilityType } from './business-nature-utility';
import { InsuranceStatusUtility, InsuranceStatusUtilityType } from './insurance-status-utility';
import { PersonalHabitUtility, PersonalHabitUtilityType } from './personal-habit-utility';
import { MedicalHistoryUtility, MedicalHistoryUtilityType } from './medical-history-utility';
import { SurgeryPastUtility, SurgeryPastUtilityType } from './surgery-past-utility';

export type Utilities = {
  gender_utility: GenderUtilityType;
  title_utility: TitleUtilityType;
  document_type_utility: DocumentTypeUtilityType;
  policy_holder_type_utility: PolicyHolderTypeUtilityType;
  policy_type_utility: PolicyTypeUtilityType;
  policy_status_utility: PolicyStatusUtilityType;
  nominee_relation_utility: NomineeRelationUtilityType;
  health_plan_type_utility: HealthPlanTypeUtilityType;
  education_utility: EducationUtilityType;
  agent_type_utility: AgentTypeUtilityType;
  occupation_utility: OccupationUtilityType;
  business_nature_utility: BusinessNatureUtilityType;
  insurance_status_utility: InsuranceStatusUtilityType;
  personal_habit_utility: PersonalHabitUtilityType;
  medical_history_utility: MedicalHistoryUtilityType;
  surgery_past_utility: SurgeryPastUtilityType;

};

@Injectable({
  providedIn: 'root'
})

export class CommonUtilityService {
  private utilities: Utilities = {
    gender_utility: GenderUtility,
    title_utility: TitleUtility,
    document_type_utility: DocumentTypeUtility,
    policy_holder_type_utility: PolicyHolderTypeUtility,
    policy_type_utility: PolicyTypeUtility,
    policy_status_utility: PolicyStatusUtility,
    nominee_relation_utility: NomineeRelationUtility,
    health_plan_type_utility: HealthPlanTypeUtility,
    education_utility: EducationUtility,
    agent_type_utility: AgentTypeUtility,
    occupation_utility: OccupationUtility,
    business_nature_utility: BusinessNatureUtility,
    insurance_status_utility: InsuranceStatusUtility,
    personal_habit_utility: PersonalHabitUtility,
    medical_history_utility: MedicalHistoryUtility,
    surgery_past_utility: SurgeryPastUtility
  };

  utilityExists(utilityName: keyof Utilities, key: number): boolean {
    const utility = this.utilities[utilityName];
    return utility ? utility.hasOwnProperty(key) : false;
  }

  getUtility(utilityName: keyof Utilities, key: number): { value: string; label: string }[] | null {
    if (this.utilityExists(utilityName, key)) {
      return this.utilities[utilityName][key] || null;
    }
    return null;
  }

  getLabelByValue(utilityName: keyof Utilities, key: number, value: string): string {
    const options = this.getUtility(utilityName, key);
    if (!options) {
      return value;
    }
    const option = options.find(option => option.value === value);
    return option ? option.label : value;
  }

  getValueArray(utilityName: keyof Utilities, key: number): string[] | null {
    const options = this.getUtility(utilityName, key);
    if (!options) {
      return null;
    }
    return options.map(option => option.value);
  }
}
