import { FormGroup, FormControl, Validators } from '@angular/forms';
import { dateFormatValidator, documentTypeValidator, genderValidator, gstNumberValidator, nomineeRelationValidator, policyStatusValidator, policyTypeValidator, titleValidator, vehicleNumberValidator, zeroOneValidator } from '../../../common/utilities/form-rules';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';

export function getFormValidators(): FormGroup {
    const formGroup = new FormGroup({
        quote_uuid: new FormControl('', [
            Validators.maxLength(50)
        ]),
        insurer_id: new FormControl('', [
            Validators.maxLength(2)
        ]),
        title: new FormControl('', [
            Validators.required,
            Validators.maxLength(25),
            titleValidator()
        ]),
        first_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(70)
        ]),
        middle_name: new FormControl('', [
            Validators.maxLength(350)
        ]),
        last_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        gender: new FormControl('', [
            Validators.required,
            Validators.maxLength(50),
            genderValidator()
        ]),
        date_of_birth: new FormControl('', [
            Validators.required,
            dateFormatValidator()
        ]),
        phone: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('^[0-9]*$')
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(100),
            Validators.email
        ]),
        address_one: new FormControl('', [
            Validators.required
        ]),
        address_two: new FormControl('', [
            Validators.required
        ]),
        address_three: new FormControl(),
        state_code: new FormControl('', [
            Validators.required
        ]),
        city: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        pincode: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
        ]),
        gstin_number: new FormControl('', [
            Validators.maxLength(15),
            gstNumberValidator
        ]),
        kyc_reference_doc_id: new FormControl('', [
            Validators.required,
            documentTypeValidator()
        ]),
        kyc_reference_number: new FormControl('', [
            Validators.required,
            Validators.maxLength(50),
        ]),
        vehicle_number: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            vehicleNumberValidator,
        ]),
        engine_number: new FormControl('', [
            Validators.required
        ]),
        chassis_number: new FormControl('', [
            Validators.required
        ]),
        registration_date: new FormControl('', [
            Validators.required,
            dateFormatValidator()
        ]),
        person_type: new FormControl('', [
            Validators.required,
            Validators.maxLength(50)
        ]),
        organization_name: new FormControl('', [
            Validators.maxLength(200)
        ]),
        is_hypothecation: new FormControl('', [
            Validators.required,
            zeroOneValidator()
        ]),
        financer_name: new FormControl('', [
            Validators.maxLength(200)
        ]),
        financer_city: new FormControl('', [
            Validators.maxLength(100)
        ]),
        nominee_first_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(70)
        ]),
        nominee_middle_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        nominee_last_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        nominee_relation: new FormControl('', [
            Validators.required,
            nomineeRelationValidator()
        ]),
        nominee_dob: new FormControl('', [
            Validators.required,
            dateFormatValidator()
        ]),
        is_previous_insurance_remember: new FormControl('', [
            Validators.required,
            zeroOneValidator()
        ]),
        previous_insurance_status: new FormControl('', [
            Validators.maxLength(200),
            policyStatusValidator()
        ]),
        previous_insurance_type: new FormControl('', [
            Validators.maxLength(100),
            policyTypeValidator()
        ]),
        previous_insurance_company: new FormControl('', [
            Validators.maxLength(255)
        ])
    });

    formGroup.get('person_type')?.valueChanges.subscribe(personType => {
        const organizationNameControl = formGroup.get('organization_name');
        if (personType === GLOBAL_CONSTANT.match.policy_holder_type_organization) {
            organizationNameControl?.setValidators([
                Validators.required,
                Validators.maxLength(200)
            ]);
        } else {
            organizationNameControl?.setValidators([
                Validators.maxLength(200)
            ]);
        }
        organizationNameControl?.updateValueAndValidity();
    });

    formGroup.get('is_previous_insurance_remember')?.valueChanges.subscribe(isPreviousInsuranceRemember => {
        const controlsToUpdate = [
            {
                name: 'previous_insurance_status',
                validators: [
                    Validators.maxLength(200),
                    /* policyStatusValidator() */
                ]
            },
            {
                name: 'previous_insurance_type',
                validators: [
                    Validators.maxLength(100),
                    /* policyTypeValidator() */
                ]
            },
            {
                name: 'previous_insurance_company',
                validators:
                [
                    Validators.maxLength(255)
                ]
            }
        ];
        if (isPreviousInsuranceRemember === '1') {
            controlsToUpdate.forEach(control => {
                const controlForm = formGroup.get(control.name);
                controlForm?.setValidators([
                    Validators.required,
                    ...control.validators
                ]);
                controlForm?.updateValueAndValidity();
            });
        } else {
            controlsToUpdate.forEach(control => {
                const controlForm = formGroup.get(control.name);
                controlForm?.setValidators(control.validators);
                controlForm?.updateValueAndValidity();
            });
        }
    });

    formGroup.get('is_hypothecation')?.valueChanges.subscribe(is_hypothecation => {
        const controlsToUpdate = [
            {
                name: 'financer_name',
                validators: [
                    Validators.maxLength(200),
                ]
            },
            {
                name: 'financer_city',
                validators: [
                    Validators.maxLength(100),
                ]
            }
        ];
        if (is_hypothecation === '1') {
            controlsToUpdate.forEach(control => {
                const controlForm = formGroup.get(control.name);
                controlForm?.setValidators([
                    Validators.required,
                    ...control.validators
                ]);
                controlForm?.updateValueAndValidity();
            });
        } else {
            controlsToUpdate.forEach(control => {
                const controlForm = formGroup.get(control.name);
                controlForm?.setValidators(control.validators);
                controlForm?.updateValueAndValidity();
            });
        }
    });

    return formGroup;
}
