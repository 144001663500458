import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';

import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_OFFLINE_MOTOR_INSURANCE_POLICY } from '../../common/constants/routes/offline-policy/offline-motor-insurance-policy.constants';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../common/utilities/moment-utilities.service';
import { DownloadService } from '../../common/utilities/download.service';

import { OfflineMotorInsurancePolicyService } from './offline-motor-insurance-policy.service';

@Component({
  selector: 'app-offline-motor-insurance-policy',
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule
  ],
  templateUrl: './offline-motor-insurance-policy.component.html'
})
export class OfflineMotorInsurancePolicyComponent implements OnInit, AfterViewInit {
  globalConstant = GLOBAL_CONSTANT;
  routesOfflineMotorInsurancePolicy = ROUTES_OFFLINE_MOTOR_INSURANCE_POLICY;

  displayedColumns: string[] = ['policy_id', 'policy_number', 'full_name', 'phone', 'vehicle_number', 'policy_type', 'policy_start_at', 'policy_end_at', 'package_policy_doc', 'insurer_name', 'amount_received', 'gst_amount', 'net_premium', 'own_damage_amount', 'third_party_amount', 'own_damage_commission_amount', 'third_party_commission_amount'];
  dataSource = new MatTableDataSource<any>();
  totalItems = 0;

  sFilter: string = '';
  dateFilter: Date | undefined;
  lastFilters: any = {};
  date_key: string = '';
  getPageName: string = this.globalConstant?.offline_policy_page_name?.policy;
  pageName: string = this.globalConstant?.offline_policy_page_name?.policy;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('date', { static: false }) date!: MatDatepicker<Date>;
  minDate: Date = this.momentUtilitiesService.getCustomDateTimeAsDateTime(this.globalConstant?.dateTime?.orderFillterMin);
  maxDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();

  private apiOfflineMotorInsurancePolicySubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private downloadService: DownloadService,
    private offlineMotorInsurancePolicyService: OfflineMotorInsurancePolicyService
  ) { }

  openDatepicker() {
    this.date.open();
  }

  getDBDateShow(date: string): string {
    return this.momentUtilitiesService.getDBDateShow(date);
  }

  showCurrency(currency: number): string {
    return this.utilitiesService.showCurrency(this.globalConstant.currencySymbol, currency);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.getPageName = params['page_name'] || null;
    });
    if (this.getPageName == this.globalConstant?.offline_policy_page_name?.renewal) {
      this.pageName = this.globalConstant?.offline_policy_page_name?.renewal;
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator && this.sort) {
      this.activatedRoute.params.subscribe(params => {
        this.date_key = params['date_key'] || '';
      });
      this.paginator.page.subscribe(() => this.loadData());
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadData();
      });
      this.loadData();
    }
  }

  loadData(): void {
    console.log(this.paginator, ">>> this.paginator rrr >>>");
    if (this.paginator && this.sort) {
      let defaultSort = "policy_id";
      if (this.pageName == this.globalConstant?.offline_policy_page_name?.renewal) {
        defaultSort = "policy_end_at";
      }
      const page = this.paginator.pageIndex;
      const size = this.paginator.pageSize;
      const sort = this.sort.active || defaultSort;
      const order = this.sort.direction || 'desc';

      const filters: any = {};
      if (this.sFilter) {
        filters.s = this.sFilter;
      }
      if (this.dateFilter) {
        filters.date = this.dateFilter;
      }
      let params = new HttpParams()
        .set('page', (page + 1).toString())
        .set('size', size.toString())
        .set('sort', sort)
        .set('order', order)
        .set('date_key', this.date_key);
      if (filters.s && filters.s != '') {
        params = params.set('s', filters.s);
      }
      if (filters.date && filters.date != '') {
        let date = this.momentUtilitiesService.formInsertDateFormate(filters.date);
        params = params.set('date', date);
      }

      this.apiOfflineMotorInsurancePolicySubscription = this.offlineMotorInsurancePolicyService.getPolicy(this.pageName, params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
              this.dataSource.data = response.data.items;
              this.totalItems = response.data.total;
            }
          },
          (error) => {
            this.dataSource = new MatTableDataSource<any>();
            this.totalItems = 0;
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  applyFilter(): void {
    if (this.filtersChanged()) {
      if (this.paginator) {
        this.paginator.firstPage();
      }
      this.loadData();
      this.lastFilters = {
        s: this.sFilter,
        date: this.dateFilter
      };
    }
  }

  filtersChanged(): boolean {
    return this.sFilter !== this.lastFilters.s || this.dateFilter !== this.lastFilters.date;
  }

  ngOnDestroy(): void {
    if (this.apiOfflineMotorInsurancePolicySubscription) {
      this.apiOfflineMotorInsurancePolicySubscription.unsubscribe();
    }
  }
}