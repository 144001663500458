import { Injectable } from '@angular/core';
import { ApiService } from '../../../utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class VariantService {

  constructor(
    private apiService: ApiService
  ) { }

  postData(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'varient', formData);
  }
}
