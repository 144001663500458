import { Component } from '@angular/core';
import { RtoComponent } from '../../../common/layouts/motor-insurance/rto/rto.component';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { MakeComponent } from '../../../common/layouts/motor-insurance/make/make.component';
import { NgIf } from '@angular/common';
import { FormGroup, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ModelComponent } from '../../../common/layouts/motor-insurance/model/model.component';
import { VariantComponent } from '../../../common/layouts/motor-insurance/variant/variant.component';
import { YearComponent } from '../../../common/layouts/motor-insurance/year/year.component';
import { getFormValidators } from './form-utils';
import { CustomerComponent } from '../../../common/layouts/motor-insurance/customer/customer.component';

@Component({
  selector: 'app-online-two-wheeler-insurance-vehicle-details',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    RtoComponent,
    MakeComponent,
    ModelComponent,
    VariantComponent,
    YearComponent,
    CustomerComponent
  ],
  templateUrl: './online-two-wheeler-insurance-vehicle-details.component.html',
})
export class OnlineTwoWheelerInsuranceVehicleDetailsComponent {
  globalConstant = GLOBAL_CONSTANT;
  projectName = this.globalConstant?.projectName;

  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  currentStep: string = 'rto';

  selectedRtoCode: string | null = null;
  selectedMakeId: string | null = null;
  selectedModelId: string | null = null;
  selectedVariantId: string | null = null;
  selectedMakeName: string | null = null;
  selectedModelName: string | null = null;
  selectedVariantName: string | null = null;
  selectedYear: string | null = null;

  constructor() {
    this.customForm = new FormGroup({
      make_name: new FormControl(''),
    });
    this.customForm = getFormValidators();
  }

  goToNextStep(step: string): void {
    if (step === 'make') {
      this.selectedRtoCode = this.customForm.get('rto_code')?.value;
    } else if (step === 'model') {
      this.selectedMakeName = this.customForm.get('make_name')?.value;
    } else if (step === 'variant') {
      this.selectedModelName = this.customForm.get('model_name')?.value;
    }else if (step === 'year') {
      this.selectedVariantName = this.customForm.get('variant_name')?.value;
    }
    else if (step === 'customer') {
      //this.selectedVariantName = this.customForm.get('variant_name')?.value;
    }
    this.currentStep = step;
  }

  goToPreviousStep(step: string): void {
    this.currentStep = step;
  }

  updateValue(selectedValue: string): void {
    if (this.currentStep === 'rto') {
      this.selectedRtoCode = selectedValue;
    } else if (this.currentStep === 'make') {
      this.selectedMakeName = selectedValue;
    } else if (this.currentStep === 'model') {
      this.selectedModelName = selectedValue;
    } else if (this.currentStep === 'variant') {
      this.selectedVariantName = selectedValue;
    } else if (this.currentStep === 'year') {
      this.selectedYear = selectedValue;
    }
    else if (this.currentStep === 'customer') {
      this.selectedYear = selectedValue;
    }
  }

  onMakeIdSelected(makeId: string): void {
    this.selectedMakeId = makeId;
  }

  onModelIdSelected(modelId: string): void {
    this.selectedModelId = modelId;
  }

  onVariantIdSelected(variantId: string): void {
    this.selectedVariantId = variantId;
  }
}