<div class="blog pt-3 mt-md-0 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 col-xl-8">
                @if(pospBlogDetailData && objectLength(pospBlogDetailData) > 0) {                    
                    <div class="border rounded mt-3">
                        <div class="blog_image mb-2 pics_section_space">
                            <div class="overflow-hidden">
                                @if(pospBlogDetailData?.image && pospBlogDetailData?.image != '' && pospBlogDetailData?.image != null) {
                                    <img src="{{ globalConstant.storage_base_url + pospBlogDetailData.image }}" [alt]="pospBlogDetailData?.alt" class="w-100 h-100">
                                }
                            </div>
                        </div>
                        <div class="blog_content">
                            <ul class="justify-content-start mb-2 mt-2">
                                <li>
                                    <i class="bx bx-calendar pe-1"></i>
                                    <span class="font_14 text-uppercase">
                                        @if(pospBlogDetailData?.posted_at && pospBlogDetailData?.posted_at != '') {
                                            {{ formatDate(pospBlogDetailData?.posted_at) }}
                                        }
                                    </span>
                                </li>
                            </ul>
                            <h1 class="fs-4 fw-semibold">{{ pospBlogDetailData?.title }}</h1>
                            <div [innerHTML]="sanitizeHtml(pospBlogDetailData.description)"></div>                            
                
                        </div>
                    </div>
                }
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4 py-3">
                <div class="second_blog">                   
                    <app-posp-blog-widget></app-posp-blog-widget>
                </div>
            </div>
        </div>
    </div>
</div>