import { INSURER_ID } from "../constants/insurer/ids.constants";

export const TitleUtility = {
  [INSURER_ID.WEB_INSURANCE]: [
    { value: 'mr', label: 'Mr.' },
    { value: 'ms', label: 'Ms.' },
    { value: 'mrs', label: 'Mrs.' },
    { value: 'miss', label: 'Miss' }
  ],
  [INSURER_ID.GO_DIGIT_MOTOR]: [
    { value: 'mr', label: 'Mr.' },
    { value: 'ms', label: 'Ms.' },
    { value: 'mrs', label: 'Mrs.' },
    { value: 'miss', label: 'Miss' }
  ]
};

export type TitleUtilityType = {
  [key: number]: { value: string; label: string }[];
};
