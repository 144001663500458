import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ONLINE_SERVICES_ID, ONLINE_SERVICES_STEP } from '../../../common/constants/services/online-services-url.constants';
import { INSURER_CONSTANT, INSURER_ID_MAP } from '../../../common/constants/insurer-constant.constants';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { LoaderService } from '../../../common/loader/loader.service';
import { StoreTwoWheelerInsuranceBasicDetail } from '../../../store/reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.reducers';
import { OnlineTwoWheelerInsurancePaymentService } from './online-two-wheeler-insurance-payment.service';
import { StoreTwoWheelerInsuranceQuotesList, StoreStateTwoWheelerInsuranceQuotesList } from '../../../store/reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.reducers';
import { selectStoreTwoWheelerInsuranceQuotesList } from '../../../store/selectors/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.selectors';
import { storeSetTwoWheelerInsuranceQuotesList, storeResetTwoWheelerInsuranceQuotesList } from '../../../store/actions/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.actions';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { StoreUtilitiesService } from '../../../common/utilities/store-utilities.service';

@Component({
  selector: 'app-online-two-wheeler-insurance-payment',
  standalone: true,
  imports: [],
  templateUrl: './online-two-wheeler-insurance-payment.component.html'
})
export class OnlineTwoWheelerInsurancePaymentComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  onLineServicesID = ONLINE_SERVICES_ID;
  insurerConstant = INSURER_CONSTANT;
  insurerIDMap = INSURER_ID_MAP;
  onLineServicesStep = ONLINE_SERVICES_STEP;

  projectName = this.globalConstant?.projectName;

  isLoading = false;
  serverErrors: { [key: string]: string[] } = {};

  uuId: string | null = null;
  insurerId: number | null = null;
  selectInsurerData: any = {};

  getStoreQuotesList$: Observable<StoreTwoWheelerInsuranceQuotesList | null>;

  private apiGetProposalDetailSubscription: Subscription | undefined;
  private apiGetSelectInsurerSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{
      selectStoreTwoWheelerInsuranceQuotesList: StoreStateTwoWheelerInsuranceQuotesList
    }>,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private storeUtilitiesService: StoreUtilitiesService,
    private onlineTwoWheelerInsurancePaymentService: OnlineTwoWheelerInsurancePaymentService
  ) {
    this.getStoreQuotesList$ = this.store.pipe(select(selectStoreTwoWheelerInsuranceQuotesList));
   }

   ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    this.storeUtilitiesService.processStore(
      this.getStoreQuotesList$,
      () => this.apiGetSelectInsurerData(),
      (data) => this.setStoreQuotesListData(data)
    );
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  showCurrency(currencySymbol: string, currency: number): string {
    return this.utilitiesService.showCurrency(currencySymbol, currency);
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.yearShow);
  }

  async apiGetSelectInsurerData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'quote_uuid': this.uuId,
        'insurer_id': this.insurerIDMap['GO_DIGIT_MOTOR'],
        'plan_type': this.insurerConstant?.plan_type?.comprehensive
      };

      this.apiGetSelectInsurerSubscription = this.onlineTwoWheelerInsurancePaymentService.getSelectInsurerDetail(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetTwoWheelerInsuranceQuotesList());
            this.store.dispatch(storeSetTwoWheelerInsuranceQuotesList({ storeData: getData }));
            this.setStoreQuotesListData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  setStoreQuotesListData(data: StoreTwoWheelerInsuranceBasicDetail | null) {
    if (!data) return;
    this.selectInsurerData = data;
  }

  paymentLinkButton(): void {
    this.isLoading = true;
    this.loaderService.show();

    const formData = {
      "quote_uuid": this.uuId,
      "insurer_id": this.insurerIDMap['GO_DIGIT_MOTOR']
    };

    this.apiGetProposalDetailSubscription = this.onlineTwoWheelerInsurancePaymentService.postData(formData).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.serverErrors = {};
          const getData = response?.data;
          console.log("Response Data:", getData);

          if (getData?.policy_number && getData?.application_id) {
            const paymentFormData = {
              "policy_number": getData.policy_number,
              "application_id": getData.application_id,
              'quote_uuid': this.uuId,
              'insurer_id': this.insurerIDMap['GO_DIGIT_MOTOR'],
            };

            this.onlineTwoWheelerInsurancePaymentService.paymentLink(paymentFormData).subscribe({
              next: (paymentResponse) => {
                this.isLoading = false;
                this.loaderService.hide();
                const paymentLink = paymentResponse?.data?.link;
                if (paymentLink) {
                  window.location.href = paymentLink;
                } else {
                  this.serverErrors = {};
                }
              },
              error: (error) => {
                this.isLoading = false;
                this.loaderService.hide();
                this.utilitiesService.handleHttpError(error);
              }
            });
          } else {
            this.isLoading = false;
            this.loaderService.hide();
            this.serverErrors = {};
          }
        },
        (error) => {
          this.isLoading = false;
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiGetSelectInsurerSubscription) {
      this.apiGetSelectInsurerSubscription.unsubscribe();
    }
    if (this.apiGetProposalDetailSubscription) {
      this.apiGetProposalDetailSubscription.unsubscribe();
    }
  }

}
