import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_ONLINE_TWO_WHEELER_INSURANCE } from '../../constants/routes/online-services/online-two-wheeler-insurance.constants';

import { OnlineTwoWheelerInsuranceBasicDetailsComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-basic-details/online-two-wheeler-insurance-basic-details.component';
import { OnlineTwoWheelerInsuranceChoosePlanComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-choose-plan/online-two-wheeler-insurance-choose-plan.component';
import { OnlineTwoWheelerInsurancePersonalDetailsComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-personal-details/online-two-wheeler-insurance-personal-details.component';
import { OnlineTwoWheelerInsurancePaymentComponent } from '../../../online-services/two-wheeler/online-two-wheeler-insurance-payment/online-two-wheeler-insurance-payment.component';

export const OnlineTwoWheelerInsuranceRoutes: Routes = [
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.BASIC_DETAILS,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.CHOOSE_PLAN,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.CHOOSE_PLAN + '/:uuid',
        component: OnlineTwoWheelerInsuranceChoosePlanComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PERSONAL_DETAILS,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PERSONAL_DETAILS + '/:uuid',
        component: OnlineTwoWheelerInsurancePersonalDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PERSONAL_DETAILS + '/:uuid/:insurerid',
        component: OnlineTwoWheelerInsurancePersonalDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT,
        component: OnlineTwoWheelerInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT + '/:uuid',
        component: OnlineTwoWheelerInsurancePaymentComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    }
];