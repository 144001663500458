import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { vehicleNumberValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    const formGroup = new FormGroup({
        service_step: new FormControl(GLOBAL_CONSTANT?.online_bike_insurance_step_match?.step_3 || 'update_quote'),
        uuid: new FormControl(''),
        title: new FormControl('', [
           Validators.required
        ]),
        insurer_id: new FormControl('2', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        first_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(30)
        ]),
        middle_name: new FormControl('', [
            Validators.maxLength(30)
        ]),
        last_name: new FormControl('', [
            Validators.maxLength(30)
        ]),
        gender: new FormControl('', [
            Validators.required,
            Validators.maxLength(30)
        ]),
        date_of_birth: new FormControl('', [
            Validators.required
        ]),
        phone: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('^[0-9]*$')
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(100),
            Validators.email
        ]),
        address_one: new FormControl('', [
            Validators.required
        ]),
        address_two: new FormControl('', [
            Validators.required
        ]),
        address_three: new FormControl('', [
        ]),
        state_code: new FormControl('', [
           Validators.required
        ]),
        city: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        pincode: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
        ]),
        gstin_number: new FormControl('', [
            Validators.maxLength(15),
        ]),
        kyc_reference_doc_id: new FormControl('', [
           Validators.required,
        ]),
        kyc_reference_number: new FormControl('', [
            Validators.required,
            Validators.maxLength(30),
        ]),
        vehicle_number: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            vehicleNumberValidator,
        ]),
        engine_number: new FormControl('', [
            Validators.required
        ]),
        chassis_number: new FormControl('', [
            Validators.required
        ]),
        registration_date: new FormControl('', [
            Validators.required
        ]),
        person_type: new FormControl('', [
           Validators.required,
           Validators.maxLength(50)
        ]),
        organization_name: new FormControl('', [
            Validators.maxLength(70)
        ]),
        nominee_first_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(70)
        ]),
        nominee_middle_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        nominee_last_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        nominee_relation: new FormControl('', [
            Validators.required
        ]),
        nominee_dob: new FormControl('', [
            Validators.required
        ]),
        is_previous_insurance_remember: new FormControl('', [
            Validators.required
        ]),
        previous_insurance_status: new FormControl('', []),
        previous_insurance_type: new FormControl('', []),
        previous_insurance_company: new FormControl('', []),
    });

    formGroup.get('is_previous_insurance_remember')?.valueChanges.subscribe((value) => {
        if (value === '1') {
            formGroup.get('previous_insurance_status')?.setValidators([Validators.required]);
            formGroup.get('previous_insurance_type')?.setValidators([Validators.required]);
            formGroup.get('previous_insurance_company')?.setValidators([Validators.required]);
        } else {
            formGroup.get('previous_insurance_status')?.clearValidators();
            formGroup.get('previous_insurance_type')?.clearValidators();
            formGroup.get('previous_insurance_company')?.clearValidators();
        }
        formGroup.get('previous_insurance_status')?.updateValueAndValidity();
        formGroup.get('previous_insurance_type')?.updateValueAndValidity();
        formGroup.get('previous_insurance_company')?.updateValueAndValidity();
    });



    return formGroup;
}





