import { Injectable } from '@angular/core';
import { ApiService } from '../../../utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class MakeService {

  constructor(
    private apiService: ApiService
  ) { }

  getMakeList(formData: any) {
    return this.apiService.request<any>('GET', 'make', formData);
  }
}
