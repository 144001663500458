import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { filter, Observable, Subscription, take, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { GLOBAL_CONSTANT } from '../../../constants/global-constant.constants';
import { ROUTES_BLOG } from '../../../constants/routes/blog/blog.constants';

import { UtilitiesService } from '../../../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../../utilities/moment-utilities.service';

import { StoreLatestBlog, StoreStateLatestBlog } from '../../../../store/reducers/blog/blog/latest-blog.reducers';
import { StoreCategoryBlog, StoreStateCategoryBlog } from '../../../../store/reducers/blog/blog/category-blog.reducers';
import { selectStoreLatestBlog } from '../../../../store/selectors/blog/blog/latest-blog.selectors';
import { selectStoreCategoryBlog } from '../../../../store/selectors/blog/blog/category-blog.selectors';
import { storeSetLatestBlog, storeResetLatestBlog } from '../../../../store/actions/blog/blog/latest-blog.actions';
import { storeSetCategoryBlog, storeResetCategoryBlog } from '../../../../store/actions/blog/blog/category-blog.actions';

import { BlogWidgetService } from './blog-widget.service';

@Component({
  selector: 'app-blog-widget',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './blog-widget.component.html'
})
export class BlogWidgetComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesBlog = ROUTES_BLOG;

  private apiLatestBlogDataSubscription: Subscription | undefined;
  private apiCategoryBlogDataSubscription: Subscription | undefined;

  latestBlogData: any = {};
  categoryBlogData: any = {};

  latestBlog$: Observable<StoreLatestBlog | null>;
  categoryBlog$: Observable<StoreCategoryBlog | null>;

  constructor(
    private store: Store<{
      selectStoreLatestBlog: StoreStateLatestBlog,
      selectStoreCategoryBlog: StoreStateCategoryBlog
    }>,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private blogWidgetService: BlogWidgetService
  ) {
    this.latestBlog$ = this.store.pipe(select(selectStoreLatestBlog));
    this.categoryBlog$ = this.store.pipe(select(selectStoreCategoryBlog));
  }

  ngOnInit(): void {
    this.getLatestBlog();
    this.getCategoryBlog();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.DD_MMM_YYYY);
  }

  getLatestBlog(): void {
    this.latestBlog$.pipe(
      tap(latestBlog => {
        if (!latestBlog) {
          this.apiGetLatestBlog();
        } else {
          this.latestBlogData = latestBlog;
        }
      }),
      filter(latestBlog => !!latestBlog),
      take(1)
    ).subscribe();
  }

  getCategoryBlog(): void {
    this.categoryBlog$.pipe(
      tap(categoryBlog => {
        if (!categoryBlog) {
          this.apiGetCategoryBlog();
        } else {
          this.categoryBlogData = categoryBlog;
        }
      }),
      filter(categoryBlog => !!categoryBlog),
      take(1)
    ).subscribe();
  }

  apiGetLatestBlog() {
    this.apiLatestBlogDataSubscription = this.blogWidgetService.getLatestBlog().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.arrayLength(response?.data) > 0) {
            this.store.dispatch(storeSetLatestBlog({ storeData: response?.data }));
            this.latestBlogData = response?.data;
          } else {
            this.store.dispatch(storeResetLatestBlog());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  apiGetCategoryBlog() {
    this.apiCategoryBlogDataSubscription = this.blogWidgetService.getCategoryBlog().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.arrayLength(response?.data) > 0) {
            this.store.dispatch(storeSetCategoryBlog({ storeData: response?.data }));
            this.categoryBlogData = response?.data;
          } else {
            this.store.dispatch(storeResetCategoryBlog());
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiLatestBlogDataSubscription) {
      this.apiLatestBlogDataSubscription.unsubscribe();
    }
    if (this.apiCategoryBlogDataSubscription) {
      this.apiCategoryBlogDataSubscription.unsubscribe();
    }
  }

}
