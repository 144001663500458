<section class="sec insplan py-4 worldmap">
    <div class="container">
        <div class="cmnform">
            <div class="row">
                <div class="col-md-12 col-lg-8 mx-auto">
                    <div class="indicators">
                        <div class="step-tabs step-current"><span> Basic Info</span></div>
                        <div class="step-tabs"><span>PAN &amp; Aadhar </span></div>
                        <div class="step-tabs"><span>Education </span></div>
                        <div class="step-tabs"><span>Bank Info</span></div>
                    </div>
                    <div class="mt-3">
                        <div class="travel-wizard">
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off"
                                enctype="multipart/form-data">
                                <div class="steps-wizard">
                                    <div class="text-center fw-semi mb-3">
                                        <div class="fs-4 mb-1">
                                            Fill your Basic Details
                                        </div>
                                        <div class="fs-18 fw-normal">Let's Start Earning Now</div>
                                    </div>
                                    <div class="row">
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="first_name" class="control-label input-label">
                                                    First Name <span class="text-danger"> * </span>
                                                </label>
                                                <input id="first_name" formControlName="first_name"
                                                    placeholder="Enter First Name" maxlength="70" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/user.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('first_name')" class="custom-error">
                                                    {{ getErrorMessage('first_name') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="middle_name" class="control-label input-label">
                                                    Middle Name
                                                </label>
                                                <input id="middle_name" formControlName="middle_name"
                                                    placeholder="Enter Middle Name" maxlength="50" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/user.svg" [alt]="projectName" width="20"
                                                        height="20">
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('middle_name')" class="custom-error">
                                                    {{ getErrorMessage('middle_name') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="last_name" class="control-label input-label">
                                                    Last Name
                                                </label>
                                                <input id="last_name" formControlName="last_name"
                                                    placeholder="Enter Last Name" type="text" maxlength="50"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/user.svg" [alt]="projectName" width="20"
                                                        height="20">
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('last_name')" class="custom-error">
                                                    {{ getErrorMessage('last_name') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="email" class="control-label input-label">
                                                    Enter Email ID <span class="text-danger"> * </span>
                                                </label>
                                                <input id="email" formControlName="email" placeholder="Enter Email ID"
                                                    type="email" maxlength="100"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/email.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('email')" class="custom-error">
                                                    {{ getErrorMessage('email') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="date_of_birth" class="control-label input-label datelabel">
                                                    Date of Birth <span class="text-danger"> * </span>
                                                </label>
                                                <input matInput
                                                    [matDatepicker]="date_of_birth"
                                                    [min]="minDobDate"
                                                    [max]="maxDobDate"
                                                    id="date_of_birth"
                                                    formControlName="date_of_birth"
                                                    placeholder="DD/MM/YYYY"
                                                    class="form-control form-control-custom"
                                                    autocomplete="off"
                                                    (click)="openDobDatepicker()"
                                                    (keydown)="datePickerInput($event)"
                                                />
                                                <span class="icon date-picker-icon">
                                                    <img src="images/icons/date.svg" alt="Date Picker" width="20" height="20" />
                                                    <mat-datepicker #date_of_birth></mat-datepicker>
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('date_of_birth')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('date_of_birth') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4 active">
                                                <label for="gender" class="control-label input-label select-label">
                                                    Gender <span class="text-danger"> * </span>
                                                </label>
                                                <select id="gender" formControlName="gender"
                                                    class="form-control form-control-custom form-select-custom">
                                                    <option value="">-- Select gender --</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <span class="icon">
                                                    <img src="images/icons/user.svg" [alt]="projectName" width="20"
                                                        height="20">
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('gender')" class="custom-error">
                                                    {{ getErrorMessage('gender') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label for="agent_type" class="control-label input-label select-label">
                                                    Type of Agent <span class="text-danger"> * </span>
                                                </label>
                                                <select id="agent_type" formControlName="agent_type"
                                                    class="form-control form-control-custom form-select-custom">
                                                    <option value="">-- Select Agent Type --</option>
                                                    @if(arrayLength(getAgentType) > 0) {
                                                        @for(data of getAgentType; track i; let i=$index) {
                                                            <option value="{{ data?.value || '' }}">{{ data?.label || '' }}</option>
                                                        }
                                                    }
                                                </select>
                                                <span class="icon">
                                                    <img src="images/icons/user.svg" [alt]="projectName" width="20"
                                                        height="20">
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('agent_type')" class="custom-error">
                                                    {{ getErrorMessage('agent_type') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label for="state_code" class="control-label input-label select-label">
                                                    Your State <span class="text-danger"> * </span>
                                                </label>
                                                <select id="state_code" formControlName="state_code"
                                                    class="form-control form-control-custom form-select-custom">
                                                    <option value="">-- Select state --</option>
                                                    @if(arrayLength(getState) > 0) {
                                                    @for(data of getState; track i; let i=$index) {
                                                    <option value="{{ data?.state_code || '' }}">{{ data?.name || '' }}</option>
                                                    }
                                                    }
                                                </select>
                                                <span class="icon">
                                                    <img src="images/icons/state.png" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('state_code')" class="custom-error">
                                                    {{ getErrorMessage('state_code') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 signform step_form">
                                            <div class="form-group icon-group mb-4">
                                                <label for="city" class="control-label input-label">
                                                    Enter Your City <span class="text-danger"> * </span>
                                                </label>
                                                <input id="city" formControlName="city" placeholder="Enter City"
                                                    maxlength="100" type="text" class="form-control form-control-custom"
                                                    autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/city.png" [alt]="projectName" width="24"
                                                        height="24" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('city')" class="custom-error">
                                                    {{ getErrorMessage('city') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="pincode" class="control-label input-label">
                                                    Enter PIN Code <span class="text-danger"> * </span>
                                                </label>
                                                <input id="pincode" formControlName="pincode"
                                                    placeholder="Enter Pincode" minlength="6" maxlength="6" type="text"
                                                    class="form-control form-control-custom" autocomplete="off"
                                                    appNumericInput />
                                                <span class="icon">
                                                    <img src="images/icons/pin-code.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('pincode')" class="custom-error">
                                                    {{ getErrorMessage('pincode') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="address" class="control-label input-label">
                                                    Enter Full Address <span class="text-danger"> * </span>
                                                </label>
                                                <input id="address" formControlName="address"
                                                    placeholder="Enter Address" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/address.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('address')" class="custom-error">
                                                    {{ getErrorMessage('address') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="profile_doc" class="control-label fs-15 fw-semi mb-2">
                                                Upload your photo <small class="text-danger">{{ globalConstant.file.max_file_label }}</small>
                                                <span class="text-danger"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="drop-zone">
                                                    <input id="profile_doc" formControlName="profile_doc"
                                                        (change)="onProfileDocFileSelected($event)"
                                                        accept="{{ globalConstant.file.file_image_pdf_accept }}"
                                                        type="file" class="drop-zone__input" autocomplete="off" />
                                                    @if(profileDocFileUrl) {
                                                    <div class="drop-zone__thumb">
                                                        @if(isPdfOrImage(profileDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                                            <img (click)="downloadDocument(profileDocFileUrl)" src="images/icons/pdf.png" [alt]="projectName" class="custom-download-document" />
                                                        } @else {
                                                            <img (click)="downloadDocument(profileDocFileUrl)" [src]="profileDocFileUrl" [alt]="projectName"  class="custom-download-document" />
                                                        }
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-remove text-danger fs-14 fw-bold"
                                                        id="profile_doc_remove" (click)="onProfileDocFileRemove()"><i
                                                            class="bx bx-trash me-1"></i>REMOVE</button>
                                                    }
                                                    @else {
                                                    <p class="text-dark fw-semi">Browse Upload</p>
                                                    }
                                                </div>
                                                <p class="fs-13 text-muted mb-2"> {{ globalConstant.file.file_image_pdf_accept_label }}</p>
                                                <mat-error *ngIf="getErrorMessage('profile_doc')" class="custom-error">
                                                    {{ getErrorMessage('profile_doc') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-3">
                                    <button type="submit" class="btnNext btn btn-success rounded-1 px-3" [disabled]="isLoading">
                                        Next <i class="bx bx-right-arrow-alt"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>