<div class="container">
    <div class="row">
        <div class="bg-white py-3">
            <div class="d-flex py-1 px-2 bg-vlight justify-content-between align-items-center">
                <h1 class="fs-5 mb-0">{{ getOffLineServiceFullName(offlineServicesID.PERSONAL_ACCIDENT_COVER) }}</h1>
                <div class="order-filters">
                    <div class="search-filter search_bx">
                        <mat-form-field>
                            <input matInput [(ngModel)]="sFilter" (keyup.enter)="applyFilter()"
                                (focusout)="applyFilter()" placeholder="Search" class="form-control"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                    <div class="search-filter date_bx ms-3">
                        <mat-form-field>
                            <input matInput [matDatepicker]="date" [(ngModel)]="dateFilter" (dateChange)="applyFilter()"
                                (click)="openDatepicker()" [min]="minDate" [max]="maxDate" id="date"
                                placeholder="MM/DD/YYYY" class="form-control" autocomplete="off" />
                            <mat-datepicker #date></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="box_outer border rounded service-sidebar">
                <div class="row">
                    <div class="col-md-12 innerpages">
                        <div class="table-responsive custom-mat-table">
                            <table class="table table-bordered table-striped" mat-table [dataSource]="dataSource"
                                matSort>
                                <ng-container matColumnDef="order_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> QUOTE ID </th>
                                    <td mat-cell *matCellDef="let row">
                                        <a [routerLink]="getOffLineServiceOrderDetailURL(offlineServicesID.PERSONAL_ACCIDENT_COVER, row.uuid)"
                                            class="text-primary fw-semi">
                                            {{row.order_id}}
                                        </a>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="full_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CUSTOMER NAME </th>
                                    <td mat-cell *matCellDef="let row"> {{row.full_name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="phone">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> MOBILE NO. </th>
                                    <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
                                    <td mat-cell *matCellDef="let row"> {{ getStatusDisplay(row.status) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="created_at">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> DATE </th>
                                    <td mat-cell *matCellDef="let row">
                                        @if(row.created_at && row.created_at != '') {
                                        {{ getDBDateTimeShow(row.created_at) }}
                                        }
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length"> {{
                                        globalConstant.noOrderFoundMessage }} </td>
                                </tr>
                            </table>
                            <div class="custom-mat-paginator">
                                <mat-paginator [length]="totalItems" [hidePageSize]="true"
                                    [pageSize]=globalConstant.paginate.perPage>
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>