<div class="vehicle-varient vehicleInfo" id="varientDiv">
  @if(arrayLength(getVariantList) > 0) {
    <p class="fw-semi text-primary mb-2">Select two wheeler variant</p>
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-12">
                <div [formGroup]="formGroup" class="form-group mb-4">
                  <select id="variant_name" formControlName="variant_name" class="form-control form-select">
                    <option value="">-- Select two wheeler variant --</option>
                    @for(data of getVariantList; track i; let i = $index) {
                      <option value="{{ data?.name || '' }}">{{ data?.name || '' }}</option>
                    }
                  </select>
                  <mat-error *ngIf="getErrorMessage('variant_name')" class="custom-error">
                    {{ getErrorMessage('variant_name') }}
                  </mat-error>
                </div>
            </div>
        </div>
        <div class="col-md-12">
          <ul class="grouplist mb-4">
            @for(data of getVariantList; track i; let i = $index) {
              <li>
                <div class="form-check form-check-inline typeradio">
                  <input class="form-check-input" type="radio" name="variant_name" id="{{ data?.id || ''}}" value="{{ data?.name || '' }}" (change)="onRadioSelect(data?.name)" [checked]="isRadioChecked(data?.name)"/>
                  <label class="form-check-label justify-content-center" for="{{ data?.id || '' }}">{{ data?.name }}</label>
                </div>
              </li>
            }
          </ul>
        </div>
        <div class="col-sm-12">
            <div class="form-group text-center">
              <button type="button" class="btn btn-secondary rounded-2 btn-lg px-5 me-3" (click)="onPrevious()">Previous</button>
                <button type="button" class="btn btn-success rounded-2 btn-lg px-5" (click)="onContinue()" [disabled]="isLoading">Continue</button>
            </div>
        </div>
    </div>
  } @else{
    <div class="row">
      <div class="col-sm-12 text-center">
        <p class="text-danger">Variant Not found</p>
        <div class="form-group">
          <button type="button" class="btn btn-secondary rounded-2 btn-lg px-5 me-3" (click)="onPrevious()">Previous</button>
        </div>
      </div>
    </div>
  }
</div>